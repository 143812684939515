import * as React from 'react';

import { useForm, useLoading, useTranslation } from '@components';
import { IJobSettings, IWork } from '@models';
import { WorkToolbar } from '../WorkToolbar';

export interface IProps {
    work: IWork;
    saveJobSettings: Function;
}

export function ManageJobSettings(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();

    const jobSettings = props.work.jobSettings ?? {
        noInformationLoadedState: false,
        skipInformationLoadedState: false,
        colorizeContractorIndicators: false,
        freeRequirementsAreMandatory: false,
        omitJobHasContractorDefaultNotification: false,
        showResourceIncidenceIndicator: true,
        jobHasContractorNotificationTargetPolicy: undefined,
        informationLoadedNotificationPolicy: undefined,
        workId: props.work.id
    };

    const form = useForm<IJobSettings>({
        initialValues: jobSettings,
    });

    const doSave = loading.wrap(async () => {
        await props.saveJobSettings(props.work.id, form.values);
    });

    return <div className='c e md pd'>
        <WorkToolbar work={props.work} />

        <strong className='page-subtitle'>{t('job.settings')}</strong>

        <div className='c e sm pd g-20'>
            <div className='r g-20 v-center'>
                {form.checkBox('noInformationLoadedState')}
                <label htmlFor='noInformationLoadedState'>
                    {t('job.settings.noInformationLoadedState')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('skipInformationLoadedState')}
                <label htmlFor='skipInformationLoadedState'>
                    {t('job.settings.skipInformationLoadedState')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('colorizeContractorIndicators')}
                <label htmlFor='colorizeContractorIndicators'>
                    {t('job.settings.colorizeContractorIndicators')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('freeRequirementsAreMandatory')}
                <label htmlFor='freeRequirementsAreMandatory'>
                    {t('job.settings.freeRequirementsAreMandatory')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('omitJobHasContractorDefaultNotification')}
                <label htmlFor='omitJobHasContractorDefaultNotification'>
                    {t('job.settings.omitJobHasContractorDefaultNotification')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('showResourceIncidenceIndicator')}
                <label htmlFor='showResourceIncidenceIndicator'>
                    {t('job.settings.showResourceIncidenceIndicator')}
                </label>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '500px auto', gridGap: '10px' }}>
                <label htmlFor='jobHasContractorNotificationTargetPolicy'>
                    {t('job.settings.jobHasContractorNotificationTargetPolicy')}
                </label>
                {form.input('jobHasContractorNotificationTargetPolicy')}

                <label htmlFor='informationLoadedNotificationPolicy'>
                    {t('job.settings.informationLoadedNotificationPolicy')}
                </label>
                {form.input('informationLoadedNotificationPolicy')}
            </div>
            <div className='e'></div>
        </div>
        <div className='r r-end'>
            {loading.render()}
            <button
                className='primary'
                disabled={loading.isLoading()}
                onClick={_ => doSave()}>{t('Save')}</button>
        </div>
    </div>;
}
