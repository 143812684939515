import { get as i18n } from '../i18n';
import { validate, validations as V } from '@components/custom/validations';
import { createFieldValidation, IFieldValidation } from '@models';

type IValidation = {
    name: string,
    message: string,
    validation: Function,
    all?: boolean,
};

type EqualsToTarget = {
    object: any,
    property: string,
};

const isEqualsToTarget = (v: any): v is EqualsToTarget =>
  (v as EqualsToTarget).object !== undefined;

export default class ValidationBuilder<T> {
    private t: Function = i18n;
    private errors: any = {};
    private data: T|undefined;
    private validations: IValidation[] = [];

    static create<T>(data: T|undefined = undefined) {
        return new ValidationBuilder<T>(data);
    }

    static new<T>() {
        return new ValidationBuilder<T>(undefined);
    }

    constructor(data: T|undefined = undefined) {
        this.data = data;
    }

    public lift() {
        return (data: T) => {
            this.data = data;

            return this.build();
        }
    }

    public withFieldValidations(resourceType: number, fields?: IFieldValidation[]) {
        const fieldValidations = fields?.filter(f => f.resourceType === resourceType) ?? [];
        for (const f of fieldValidations) {
            this.custom(f.errorMessage, createFieldValidation(f.value), f.field);
        }
        return this;
    }

    public withI18n(f: Function) {
        this.t = f;
        return this;
    }

    public withData(data: T) {
        this.data = data;
        return this;
    }

    public customValidations(validations: {name: string, msg: string, validation: Function}[]) {
        for (const v of validations) {
            this.custom(v.msg, v.validation, v.name);
        }
        return this;
    }

    public custom(msg: string, validation: Function, name: string | undefined = undefined) {
        this.validations.push({name: name ?? 'error', validation, message: this.t(msg), all: true});
        return this;
    }

    public add(name: string, validation: Function, message: string) {
        this.validations.push({name, validation, message: this.t(message)});
        return this;
    }

    public minLength(name: string, minLength: number, message: string|undefined = undefined) {
        this.add(name, V.minLength(minLength), message ?? `${name} is required`);
        return this;
    }

    public notEmpty(name: string, message: string|undefined = undefined) {
        this.add(name, V.notEmpty, message ?? `${name} is required`);
        return this;
    }

    public notEmptyIf(condition: boolean|Function|undefined, name: string, message: string|undefined = undefined) {
        if (typeof(condition) === 'boolean' && condition === true) {
            this.add(name, V.notEmpty, message ?? `${name} is required`);
        }
        else if (typeof(condition) === 'function' && condition()) {
            this.add(name, V.notEmpty, message ?? `${name} is required`);
        }

        return this;
    }

    public match(name: string, data: any, message: string) {
        this.add(name, V.match(data), message);
        return this;
    }

    public email(name: string, message: string = 'Invalid email') {
        this.add(name, V.email, message);
        return this;
    }

    public equalTo(name: string, prop: string|EqualsToTarget, message: string) {
        this.validations.push({
            name: name,
            validation: (value: any) =>
                value == undefined ||
                    (typeof(prop) === 'string'
                        ? value == (this.data as any)[prop]
                        : isEqualsToTarget(prop)
                        ? value == (prop.object[prop.property])
                        : value == prop),
            message: this.t(message)
        });
        return this;
    }

    public build() {
        let errors: any = {};

        for (const v of this.validations) {
            try {
                validate(this.data, v.name, errors, v.validation, v.message, v.all);
            }
            catch (e) {
                console.log(e);
                errors['*'] = e + '';
            }
        }

        return errors;
    }
}
