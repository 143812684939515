import * as React from 'react';

import {
    useDataTable, useTranslation, useRemoteData,
    useMessage, useLoading, useSearch, Loading, Tag, usePermissions
} from '@components';
import {
    IWorker, IWork, ISecurity, ModuleManager
} from '@models';
import './WorkerJobsDialog.scss'

type GetWorkerF = (workId: number, id: number) => Promise<IWorker>;

export interface IProps {
    activateJobHasWorker: Function;
    requestClose: Function;
    saveJobHasWorker: Function;
    security: ISecurity;
    worker: IWorker;
    workerId: number;
    getWorker: GetWorkerF;
    work: IWork;
    removeJobHasWorker: Function;
    moduleManager: ModuleManager;
}

type SearchR = {
    endDate?: Date;
    jobId: number;
    jobHasWorkerId?: number;
    jobHasContractorId?: number;
    isRelated?: boolean;
    currentStatusType?: number;
    parentContractorName?: string;
    name?: string;
    code?: string;
    description?: string;
}

function WorkerJobsFormImpl(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const messages = useMessage();

    const search = useSearch<SearchR>({
        search: 'contractorJobsHasWorker.list',
        normalizeKeys: true,
        workId: props.work.id,
        filters: {
            workerId: props.workerId,
            contractorId: props.worker.contractorId
        }
    });

    React.useEffect(() => {
        search.doSearch();
    }, []);

    const jobName = (d: SearchR) => {
        if (props.work.id != 23) { //TODO: remove magic number
            return d.name;
        } else {
            return <><Tag value={d.code} /><span className='sm pd-left' title={d.name}>{d.name}</span></>
        }
    }

    const perms = usePermissions(props, {}, {
        add: 'jobHasWorker.add',
        remove: 'jobHasWorker.remove',
    });

    const dataTable = useDataTable<SearchR>({
        columns: [
            { title: 'Job', render: (d: any) => jobName(d) },
            {
                title: 'Description',
                field: 'description',
                render: (d: SearchR) => <span title={d.description}>
                    {d.description}
                </span>
            },
            {
                title: 'Contractor',
                field: 'parentContractorName',
                render: (d: SearchR) => <span title={d.parentContractorName}>
                    {d.parentContractorName}
                </span>
            },
            {
                title: 'Associated',
                delegate: 'boolean',
                field: 'isRelated',
                className: 'td-md'
            },
            {
                title: 'End date',
                delegate: 'date',
                field: 'endDate',
                className: 'td-lg'
            },
        ],
        actions: [
            {
                actions: [
                    perms.get('add')
                        ? {
                            title: t('Associate'),
                            onClick: d => saveWorkerRelation(d.jobId, d.jobHasContractorId),
                            disabled: d => (d.isRelated == true || d.currentStatusType == 3),
                        }
                        : undefined,
                    perms.get('add')
                        ? {
                            title: t('Reactivate'),
                            disabled: d => (d.endDate == undefined),
                            onClick: d => reactiveWorkerRelation(d.jobId, d.jobHasWorkerId, d.jobHasContractorId, d)
                        }
                        : undefined,
                    perms.get('remove')
                        ? {
                            title: t('Finalize'),
                            disabled: d => (!d.isRelated || d.endDate != null),
                            onClick: d => removeWorkerRelation(d.jobId, d.jobHasWorkerId)
                        } : undefined,
                ]
            }
        ],
        data: search.value,
        loading: loading,
    });

    const saveWorkerRelation = loading.wrap(async (jobId: number, jobHasContractorId: number) => {
        const data = {
            workerId: props.workerId,
            jobId,
            jobHasContractorId: jobHasContractorId,
        };
        const resp = await props.saveJobHasWorker(props.work.id, data);
        messages.set(resp);
        await search.doSearch();
    });

    const removeWorkerRelation = loading.wrap(async (jobId: number, jobHasWorkerId: number) => {
        const resp = await props.removeJobHasWorker(props.work.id, jobId, jobHasWorkerId);
        messages.set(resp);
        await search.doSearch();
    });

    const reactiveWorkerRelation = loading.wrap(async (jobId: number, jobHasWorkerId: number, jobHasContractorId: number, d: SearchR) => {
        const resp = await props.activateJobHasWorker(props.work.id, jobId, props.workerId, d.jobHasWorkerId);
        messages.set(resp);
        await search.doSearch();
    });

    return <div className='WorkerJobsDialog'>
        {search.renderLoading()}
        {dataTable()}
        <div className='footer r r-end'>
            <button onClick={() => props.requestClose()}>{t('Close')}</button>
        </div>
    </div>
}

export function WorkerJobsDialog(props: IProps) {
    const worker = useRemoteData<IWorker>(props.getWorker, {
        parameters: [props.work.id, props.workerId]
    });

    return <div>
        {worker.renderLoading()}
        {worker.value && <WorkerJobsFormImpl
            {...props}
            worker={worker.value} />}
    </div>
}


// export function WorkerJobsForm(props: IProps) {
//TODO: llevar al modulo de Azsa
//     const res = props
//         .moduleManager
//         .renderComponent<IProps>('WorkerJobsForm', { ...props }, WorkerJobsFormImpl);

//     return res;
// }