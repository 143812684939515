import * as React from 'react';

import {
    ConfirmDialog, Message, useDataTable, useFormDialog, useLoading, useTranslation
} from '@components';
import { IWorkI18NResource } from '@models/resources';
import * as Actions from '@store/actions/resources';
import { WorkI18NResourceForm } from './WorkI18NResourceForm';

export interface IProps {
    workId: number;
}

export function AdminWorkI18NResources(props: IProps) {
    const { t } = useTranslation();

    const [error, setError] = React.useState<string|undefined>();
    const loading = useLoading(true);
    const [workI18NResources, setWorkI18NResources] = React.useState<IWorkI18NResource[]>([]);
    const formDialog = useFormDialog({
        editTitle: t('Edit internationalization'),
        addTitle: t('Add internationalization'),
    });
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IWorkI18NResource|undefined>();

    const initialize = loading.wrap(async () => {
        const res = await Actions.getWorkI18NResources(props.workId);

        setWorkI18NResources(res.data.work.workI18NResources)
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const saveWorkI18NResource = async (w: IWorkI18NResource) => {

        await Actions.saveWorkI18NResource(w);
        await initialize();

        formDialog.clear();
    }

    const removeWorkI18NResource = async (id: number, workId: number) => {
        setError(undefined);

        workId = props.workId;
        await Actions.removeWorkI18NResource(id, workId);
        await initialize();

        console.log(id, workId);
        formDialog.clear();
    }

    const dataTable = useDataTable({
        loading,
        data: workI18NResources,
        scrollable: true,
        tableStyle: { maxHeight: '300px' },
        scrollHeight: '300px',
        className: 'e',
        columns: [
            {field: 'language', title: 'Language', className: 'td-sm'},
            'name',
            'value'
        ],
        actions: [
            {icon: 'pencil', tooltip: t('Edit'), onClick: formDialog.showEdit, bodyClassName: 'td-sm'},
            {icon: 'trash',  tooltip: t('Delete'), onClick: setShowConfirmRemove, bodyClassName: 'td-sm'},
        ],
    });

    return <div className='he c'>
        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => removeWorkI18NResource(showConfirmRemove.id, showConfirmRemove.workId)} />}

        {formDialog.render((data:IWorkI18NResource) =>
            <WorkI18NResourceForm
                data={data}
                workId={props.workId}
                notifications={[]}
                onSubmit={saveWorkI18NResource}
                footer={formDialog.Footer()} />)}

        {dataTable()}

        <div className='r sm pd footer'>
            {error && <Message severity='error' text={error} />}
            <span className='e' />
            <button className='p-button'
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add')}
            </button>
        </div>
    </div>

}