import * as React from 'react';

import { BreadcrumbItem, R, useForm, useLoading, useMessage, useRemoteData, useTranslation } from '@components';
import { IJobHasContractor } from '@models';
import { goToJobRequirements } from '../routes';

import './JobNotifyInformationLoaded.scss';

export interface IProps {
    getJobContractors: Function;
    jobId: number;
    jobNotifyInformationLoaded: Function;
    workId: number;
}

type FormData = {
    remarks?: string;
}

export function JobNotifyInformationLoaded(props: IProps) {
    const loading = useLoading();
    const msg = useMessage();
    const { t } = useTranslation();

    const contractors = useRemoteData<IJobHasContractor[]>(props.getJobContractors, {
        parameters: [props.workId, props.jobId]
    });

    const form = useForm<FormData>({
        initialValues: {
            remarks: '',
        },
    });

    const notify = loading.wrap(async () => {
        const res = await props.jobNotifyInformationLoaded(
            props.workId,
            props.jobId,
            form.values.remarks,
        );

        msg.set(res);

        if (res.hasValue) {
            goToJobRequirements(props.workId, props.jobId, { reload: true });
        }
    });

    const cancel = () => {
        goToJobRequirements(props.workId, props.jobId);
    }

    return <div className={'c we he JobNotifyInformationLoaded'}>
        <BreadcrumbItem text={t('job.notify-information-loaded')} />

        <div className={'c md pd g-10 e'}>
            <div className={'message'}>
                {t('job.notify-information-loaded.message')}
            </div>
            <div className={'contractors-list'}>
                {(contractors.value ?? []).map(c =>
                    <div key={c.contractorId} className={'contractor'}>
                        <span className={'name'}>{c.contractor?.name}</span>
                        <span className={'mutted sm pd'}>{c.jobsDescription}</span>
                    </div>)}
            </div>

            {(![21, 23].includes(props.workId)) &&
                <div className={'form'}>
                    <div>
                        <label>{t('Remarks')}</label>
                        {form.textarea('remarks', { autoFocus: true, rows: 5 })}
                    </div>
                </div>}
        </div>

        {msg.renderIfError()}

        <div className={'r r-end sm pd'}>
            {contractors.renderLoading()}
            {loading.render()}
            <div className={'p-buttonset'}>
                <button onClick={cancel}>{t('Cancel')}</button>
                <button className={'primary'}
                    disabled={contractors.isLoading() || loading.isLoading()}
                    onClick={notify}>{t('Notify')}</button>
            </div>
        </div>
    </div>
}