import * as React from 'react';

import { classNames } from '@components';
import NavMenu from '../containers/NavMenuContainer';
import Footer from '../containers/FooterContainer';
import { isDebug } from '../Constants';
import Cookies from './Cookies';

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {
        return (
            <div className={classNames('wide-theme', {
                'debug': isDebug,
                'release': !isDebug,
            })}>
                <NavMenu />
                <div id='container'>
                    {this.props.children}
                </div>
                <Cookies />
                <Footer
                    children={this.props.children} />
            </div>
        );
    }
}
