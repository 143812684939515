import * as React from 'react';

import {
    G, useToast, Portal, useForm, useLoading, useTranslation,
    ValidationBuilder, useSearch, SignalManager
} from '@components';
import { DynamicProperties } from '@components/user/DynamicProperties';
import {
    IWorker, IPropertyGroupType, PropertyGroupObjectType, ISecurity,
    IWork, ResourceType, IResourceStatusType, IFieldValidation,
    IWorkerSettings,
} from '@models';

import { delay, flatten, redirectTo } from '@utils';

import './EditWorker.scss';
import useContractorFromAppUser from '@components/custom/useContractorFromAppUser';
import { ModuleManager } from '@models';
import { IWorkerType, IWorkerDocumentType, IContractor } from '@models/resources';
import { useWorkFormSettings } from '@components/custom/useWorkFormSettings';
import { IRequirement, IRequirementGroup, RequirementTargetType } from '@models/requirements';
import ResourcePhotoContainer from '@containers/resources/ResourcePhotoContainer';

type GetWorkerF = (workId: number, id: number) => Promise<IWorker>;

export interface IProps {
    propertyGroupTypes: IPropertyGroupType[];
    getWorker: GetWorkerF;
    hideActions?: boolean;
    moduleManager: ModuleManager;
    work: IWork;
    worker: IWorker;
    workerId: number;
    security: ISecurity;
    saveWorker: Function;
    reactivateWorker: Function;
    workerTypes: IWorkerType[];
    workerDocumentTypes: IWorkerDocumentType[];
    resourceStatusTypes: IResourceStatusType[];
    requirementGroups: IRequirementGroup[];
    getRequirements: Function;
    getRequirement: Function;
}

const validateWorkerF = (fieldValidations: IFieldValidation[] | undefined, workerSettings: IWorkerSettings | undefined = undefined) =>
    ValidationBuilder
        .new()
        .notEmpty('name')
        .notEmpty('surname')
        .notEmptyIf(workerSettings?.workerTypeIsMandatory, 'workerTypeId')
        .withFieldValidations(ResourceType.Worker, fieldValidations)
        .lift();

export function EditWorkerForm(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const toast = useToast();

    const [selectedFile, setSelectedFile] = React.useState<File | undefined>();

    const [pictureRequirement, setPictureRequirement] = React.useState<IRequirement>();

    const settings = useWorkFormSettings({ work: props.work, scope: 'worker' });

    const contractorData = useSearch<IContractor>({
        workId: props.work.id,
        search: 'workers/contractor.get',
        filters: {
            contractorId: props.worker.contractorId,
        }
    });

    const initialize = async () => {
        const requirementTypes = flatten(props.requirementGroups.map(r => r.requirementTypes ?? []));
        const requirements: IRequirement[] = await props.getRequirements(props.work.id, RequirementTargetType.Worker, props.workerId);

        const requirement = requirements.find(r => {
            const rt = requirementTypes.find(rt => rt.id == r.requirementTypeId);
            return rt?.isResourcePicture;
        });
        setPictureRequirement(requirement);
    };

    const reload = async () => {
        if (pictureRequirement !== undefined) {
            const auxReq: IRequirement = await props.getRequirement(props.work.id, RequirementTargetType.Worker, props.work.id, pictureRequirement.id);
            if (auxReq.status.find(r => r.isCurrent) != pictureRequirement.status.find(r => r.isCurrent)) {
                setPictureRequirement(auxReq);
            }
        }
    }

    React.useEffect(() => {
        initialize();
    }, []);

    const selfContractor = useContractorFromAppUser(props.work.id, props.security);

    const contractor = contractorData.value && contractorData.value.length > 0
        ? contractorData.value[0]
        : undefined;

    const saveWorker = loading.wrap(async (req: IWorker) => {
        const resp = await props.saveWorker(props.work.id, req, { imageFile: selectedFile });

        if (resp.hasValue) {
            toast.show(t('Worker saved successfully'));
            if (props.work.id != 21) {
                await delay(2000);
                redirectTo(`/work/${props.work.id}/workers`);
            }
            return { ok: true };
        }
        else {
            toast.show(resp.error, { severity: 'error' });
            return { ok: false, message: resp.error };
        }
    });

    const doSave = (..._: any) => {
        saveWorker(form.values);
    }

    const canSave = () => {
        if (props.security.isContractor() && props.work.id == 23) {
            return false;
        }
        else if (props.security.hasPermission('workers.save')) {
            return true;
        } else {
            if (props.worker.contractorId === selfContractor) {
                return true;
            } else {
                return false;
            }
        }
    }

    const validateWorker = validateWorkerF(props.work.validations, props.work.workerSettings);

    const form = useForm<IWorker>({
        initialValues: {
            id: props.workerId,
            workId: props.work.id,
            name: props.worker.name,
            surname: props.worker.surname,
            code: props.worker.code,
            properties: props.worker.properties,
            isActive: props.worker.isActive == true,
            contractorId: props.worker.contractorId,
            workerTypeId: props.worker.workerTypeId,
            workerDocumentTypeId: props.worker.workerDocumentTypeId,
        },
        readonly: !canSave(),
        validate: validateWorker,
        onSubmit: (data: IWorker) => {
            return saveWorker(data);
        }
    });

    const renderAvatar = () => {
        if (pictureRequirement) {
            return <Portal container='.actions'>
                <ResourcePhotoContainer
                    resourceId={props.workerId}
                    work={props.work}
                    targetTitle={props.worker.name + ' ' + props.worker.surname + ' ' + props.worker.code}
                    targetType={RequirementTargetType.Worker}
                    resourcePictureRequirement={pictureRequirement}
                    requirementType={pictureRequirement.requirementType}
                    onRender={reload} />
            </Portal>
        }
        else {
            return null;
        }
    }

    const sm = SignalManager.create(props.work.settingsObj?.signals, 'worker');
    sm.current.setValues(form.values, { contractor });

    const filterDocumentTypes = props.moduleManager
        .filterDependency('worker.documentTypes', {
            contractor
        });

    const documentTypes = props.workerDocumentTypes
        .filter(filterDocumentTypes);

    return <div className='we'>
        <Portal container={'#breadcrumb-right'}>
            {loading.render()}
        </Portal>

        {toast.render()}

        {props.worker &&
            <div className='c we'>
                <div className='md mr pd'>
                    <div className='c lg pd g-20 form-1 l200 he'>
                        <G label={t('Name')}>
                            {form.input('name', { autoFocus: true })}
                        </G>
                        <G label={t('Surname')}>
                            {form.input('surname')}
                        </G>
                        <G label={t('Worker document type')}>
                            {form.select('workerDocumentTypeId', { options: documentTypes })}
                        </G>
                        <G label={t('worker.code')}>
                            {form.input('code')}
                        </G>
                        {settings.show('workerTypeId') &&
                            <G label={t('Worker type')} name='workerTypeId' signals={sm.current}>
                                {form.select('workerTypeId', { options: props.workerTypes })}
                            </G>}
                        <div id={'embed-properties'}>
                            <DynamicProperties
                                className='e sm mr-left'
                                inline
                                readonly={!canSave}
                                object={props.worker}
                                onChange={v => form.setFieldValue('properties', v)}
                                isInternal={props.worker.contractorId == undefined}
                                objectType={PropertyGroupObjectType.JobHasWorker}
                                propertyGroupTypes={props.propertyGroupTypes} />
                        </div>

                        {renderAvatar()}

                        <div className='e' />

                        <div className='r'>
                            <span className='e' />
                            {canSave() &&
                                <button
                                    className='primary'
                                    disabled={form.isInvalid()}
                                    onClick={doSave}>
                                    {t('Save')}
                                </button>}
                        </div>
                    </div>
                </div>
            </div>}
    </div>
}
