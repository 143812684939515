import * as React from 'react';
import { Route } from 'react-router';

import { getMachineryName, IJob, IJobHasContractor, ISecurity, IWork } from '@models';

import JobHasResourceRequirements from '@containers/jobs/JobHasResourceRequirementsContainer';
import JobRequirements from '@containers/jobs/JobRequirementsContainer';
import { getContractorFromAppUser } from '@store/actions/contractors';
import { RequirementTargetType } from '@models/requirements';
import * as Actions from '@store/actions/jobs';
import { BreadcrumbItem, DataLoader, useTranslation, Loading } from '@components';
import { getIntParams, getIntQueryParameters, getQueryParameter } from '@utils';
import Routes from '@components/routes';

import JobLoader from '@containers/user/JobLoader';
import CreateContractorInvitation from '@containers/contractors/CreateContractorInvitationContainer';
import JobDataContainer from '@containers/jobs/JobDataContainer';
import JobAddContractorContainer from '@containers/jobs/JobAddContractorContainer';
import JobContractorsContainer from "@containers/jobs/JobContractorsContainer";
import JobRequestContractorContainer from "@containers/jobs/JobRequestContractorContainer";
import JobContractorRequestsContainer from "@containers/jobs/JobContractorRequestsContainer";
import JobContractorSubRequestsContainer from '@containers/jobs/JobContractorSubRequestsContainer';
import JobInformationLoaded from '@containers/jobs/JobInformationLoadedContainer';
import JobInfoCardContainer from '@containers/jobs/JobInfoCardContainer';
import JobInformationLoadedControl from '@containers/jobs/JobInformationLoadedControlContainer';
import JobNotifyInformationLoaded from '@containers/jobs/JobNotifyInformationLoadedContainer';
import ManageJobMachineries from '@containers/jobs/ManageJobMachineriesContainer';
import AddJobHasMachinery from '@containers/jobs/AddJobHasMachineryContainer';
import SelectJobHasMachinery from '@containers/jobs/SelectJobHasMachineryContainer';
import ManageJobWorkersContainer from '@containers/jobs/ManageJobWorkersContainer';
import JobAddWorkerContainer from '@containers/jobs/JobAddWorkerContainer';
import UploadRequirement from '@containers/jobs/UploadRequirementContainer';
import CreateRequirement from '@containers/jobs/CreateRequirementContainer';

import { getJobHasContractor } from '@store/actions/jobs';

export interface IProps {
    children?: any;
    job: IJob;
    work: IWork;

    security: ISecurity;

    selectJobHasContractor: Function;
    selectedJobHasContractor?: IJobHasContractor;
    getJobHasContractor: Function;
    getJobContractors: Function;
}

const WrappedJobHasContractorRequirements = (props: any) => {
    const { t } = useTranslation();
    const { jobid, jobhascontractorid, workid } = getIntParams(props.match.params);

    return <DataLoader provider={() => Actions.getJobHasContractor(workid, jobid, jobhascontractorid)}>
        {rel =>
            <div>
                <BreadcrumbItem
                    text={rel.contractor.name}
                    redirectTo={Routes.jobContractorsRoute(workid, jobid, true)} />
                {/* redirectTo={Routes.jobHasContractorRoute(workid, jobid, rel.id)} /> */}

                <BreadcrumbItem
                    text={t('Requirements')} />

                <div className='md pd c g-20'>
                    <JobHasResourceRequirements
                        job={props.job}
                        jobId={jobid}
                        resourceId={jobhascontractorid}
                        resourceTitle={rel.contractor?.name}
                        resourceType={RequirementTargetType.JobHasContractor}
                        workId={workid}
                        siblingId={rel.contractor.id}
                        siblingType={RequirementTargetType.Contractor}
                    />
                </div>
            </div>
        }
    </DataLoader>
}

const WrappedJobHasMachineryRequirements = (props: any) => {
    const { t } = useTranslation();
    const { jobid, jobhasmachineryid, workid } = getIntParams(props.match.params);

    return <DataLoader provider={() => Actions.getJobHasMachinery(workid, jobid, jobhasmachineryid)}>
        {rel =>
            <div>
                <BreadcrumbItem
                    text={getMachineryName(rel.machinery)}
                    redirectTo={Routes.jobMachineriesRoute(workid, jobid)} />
                { /* redirectTo={Routes.jobHasMachineryRoute(workid, jobid, rel.id)} /> **/}

                <BreadcrumbItem
                    text={t('Requirements')} />

                <div className='md pd c g-20'>
                    <JobHasResourceRequirements
                        job={props.job}
                        jobId={jobid}
                        resourceId={jobhasmachineryid}
                        resourceTitle={rel.machinery.prefixLicenseNumber + rel.machinery.licenseNumber! + rel.machinery.suffixLicenseNumber}
                        resourceType={RequirementTargetType.JobHasMachinery}
                        siblingId={rel.machinery.id}
                        siblingType={RequirementTargetType.Machinery}
                        siblingContractorId={rel.machinery.contractorId}
                        siblingJobHasContractorId={rel.jobHasContractorId}
                        workId={workid} />
                </div>
            </div>
        }
    </DataLoader>
}

const WrappedJobHasWorkerRequirements = (props: any) => {
    const { t } = useTranslation();
    const { jobid, jobhasworkerid, workid } = getIntParams(props.match.params);

    return <DataLoader provider={() => Actions.getJobHasWorker(workid, jobid, jobhasworkerid)}>
        {rel =>
            <div>
                <BreadcrumbItem
                    text={rel.worker.name + ' ' + rel.worker.surname}
                    redirectTo={Routes.jobWorkersRoute(workid, jobid)} />
                { /** redirectTo={Routes.jobHasWorkerRoute(workid, jobid, rel.id)} /> **/}

                <BreadcrumbItem
                    text={t('Requirements')} />

                <div className='md pd c g-20'>
                    <JobHasResourceRequirements
                        job={props.job}
                        jobId={jobid}
                        resourceId={jobhasworkerid}
                        resourceTitle={rel.worker?.name + ' ' + rel.worker.surname + ' - ' + rel.worker.code}
                        resourceType={RequirementTargetType.JobHasWorker}
                        siblingId={rel.worker.id}
                        siblingContractorId={rel.worker.contractorId}
                        siblingJobHasContractorId={rel.jobHasContractorId}
                        siblingType={RequirementTargetType.Worker}
                        workId={workid} />
                </div>
            </div>
        }
    </DataLoader>
}

const WrappedJobRequirements = (props: any) => {
    const { t } = useTranslation();
    const { jobid, workid } = getIntParams(props.match.params);

    return <div>
        <div className='md pd r g-20'>
            <DataLoader provider={() => Actions.getJob(workid, jobid)}>
                {job =>
                    <JobRequirements
                        job={job}
                        jobId={jobid}
                        work={props.work}
                        workId={workid} />
                }
            </DataLoader>
        </div>
    </div>
}

const WrappedJobCreateRequirement = (props: any) => {
    const {
        jobid, workid
    } = getIntParams(props.match.params);

    const { resourceType, resourceId, statusType } = getIntQueryParameters(window.location.search);

    return <div className={'c he'}>
        <DataLoader provider={() => Actions.getJob(workid, jobid)}>
            {job =>
                <UploadRequirement
                    job={job}
                    jobId={jobid}
                    workId={workid}
                    resourceType={resourceType}
                    resourceId={resourceId}
                    statusType={statusType} />
            }
        </DataLoader>
    </div>
}

const WrappedJobCreateResourceRequirement = (props: any) => {
    const {
        jobid, workid
    } = getIntParams(props.match.params);

    const { resourceType, resourceId, statusType } = getIntQueryParameters(window.location.search);

    return <div className={'c he'}>
        <DataLoader provider={() => Actions.getJob(workid, jobid)}>
            {job =>
                <CreateRequirement
                    job={job}
                    jobId={jobid}
                    workId={workid}
                    resourceType={resourceType}
                    resourceId={resourceId}
                    statusType={statusType} />
            }
        </DataLoader>
    </div>
}

const WrappedJobCard = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <div className={'c he'}>
        <DataLoader provider={() => Actions.getJob(workid, jobid)}>
            {resp =>
                <JobInfoCardContainer
                    job={resp.data.work.job}
                    workId={workid} />
            }
        </DataLoader>
    </div>
}

const WrappedJobInformationLoaded = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <div className={'c he'}>
        <DataLoader provider={() => Actions.getJob(workid, jobid)}>
            {job =>
                <JobInformationLoaded
                    job={job}
                    jobId={jobid}
                    workId={workid} />
            }
        </DataLoader>
    </div>
}

const WrappedJobInformationLoadedControl = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <div className={'c he'}>
        <DataLoader provider={() => Actions.getJob(workid, jobid)}>
            {job =>
                <JobInformationLoadedControl
                    job={job}
                    jobId={jobid}
                    workId={workid} />
            }
        </DataLoader>
    </div>
}

const WrappedJobNotifyInformationLoaded = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <div className={'c he'}>
        <DataLoader provider={() => Actions.getJob(workid, jobid)}>
            {job =>
                <JobNotifyInformationLoaded
                    job={job}
                    jobId={jobid}
                    workId={workid} />
            }
        </DataLoader>
    </div>
}

const WrappedEditJobData = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <JobDataContainer
        job={props.job}
        jobId={jobid}
        work={props.work}
        workId={workid} />;
}

const WrappedJobContractors = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);
    const parentId: number | undefined = getQueryParameter<number>(
        window.location.search,
        'parentId',
        { map: parseInt });
    const loadInvitations: boolean = getQueryParameter<boolean>(
        window.location.search,
        'loadInvitations',
        { map: v => v == 'true' }) ?? true;

    return <JobContractorsContainer
        job={props.job}
        work={props.work}
        parentId={parentId}
        loadInvitations={loadInvitations}
        refreshContractors={console.log}
        onRequestAddContractor={(d: IJobHasContractor | undefined) =>
            Routes.goToJobAddContractor(workid, jobid, d?.id)} />
}

const WrappedJobContractor = (props: any) => {
    const { jobid, workid, jobhascontractorid } = getIntParams(props.match.params);

    return <DataLoader provider={() => Actions.getJobHasContractor(workid, jobid, jobhascontractorid)}>
        {rel =>
            <JobContractorsContainer
                job={props.job}
                work={props.work}
                jobHasContractor={rel}
                refreshContractors={console.log}
                onRequestAddContractor={(d: IJobHasContractor | undefined) =>
                    Routes.goToJobAddContractor(workid, jobid, d?.id)} />}
    </DataLoader>;
}

const WrappedAddContractor = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);
    const parentId: number | undefined = getQueryParameter<number>(
        window.location.search,
        'parentId',
        { map: parseInt });

    if (parentId !== undefined) {
        return <DataLoader provider={() => getJobHasContractor(workid, jobid, parentId)}>
            {rel => <JobAddContractorContainer
                job={props.job}
                parentContractor={rel}
                onCancel={() => Routes.goToJob(workid, jobid)}
                onSuccess={() => Routes.goToJobContractors(workid, jobid)}
                work={props.work} />
            }
        </DataLoader>
    }
    else {
        return <JobAddContractorContainer
            job={props.job}
            onCancel={() => Routes.goToJob(workid, jobid)}
            onSuccess={() => Routes.goToJobContractors(workid, jobid)}
            work={props.work} />;
    }
}

const WrappedRequestNewContractor = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);
    return <JobRequestContractorContainer
        onCancel={() => Routes.goToJob(workid, jobid)}
        onSuccess={console.log}
        job={props.job}
        work={props.work} />
}

const WrappedContractorRequests = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <JobContractorRequestsContainer
        job={props.job}
        onProcessSuccess={() => Routes.goToJobContractors(workid, jobid)}
        work={props.work} />;
}

const WrappedSubContractorRequests = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <JobContractorSubRequestsContainer
        jobId={jobid}
        workId={workid} />;
}

const WrappedContractorInvite = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <CreateContractorInvitation
        job={props.job}
        onCancel={() => Routes.goToJob(props.work.id, props.job.id)}
        work={props.work} />;
}

const WrappedMachineries = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    const jobHasContractorId: number | undefined = getQueryParameter<number>(
        window.location.search,
        'jobHasContractorId',
        { map: parseInt });

    const relationId = getQueryParameter<number>(
        window.location.search,
        'relationId',
        { map: parseInt });

    return <ManageJobMachineries
        job={props.job}
        relationId={relationId}
        contractorId={jobHasContractorId}
        work={props.work} />;
}

const WrappedMachinery = (props: any) => {
    const { jobid, workid, machineryid } = getIntParams(props.match.params);

    return <DataLoader provider={() => Actions.getJobHasMachinery(workid, jobid, machineryid)}>
        {m => <ManageJobMachineries
            job={props.job}
            work={props.work}
            jobHasMachinery={m} />}
    </DataLoader>;
}

const WrappedMachineryCreate = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    return <AddJobHasMachinery
        job={props.job}
        workId={props.work.id}
        onChange={() => Routes.goToJobMachineries(workid, jobid)} />
}

const WrappedMachineryAdd = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    const contractorId: number | undefined = getQueryParameter<number>(
        window.location.search,
        'contractor',
        { map: safeParseInt });

    return <SelectJobHasMachinery
        job={props.job}
        contractorId={contractorId}
        workId={props.work.id}
        onChange={() => Routes.goToJobMachineries(workid, jobid)} />
}

const WrappedAddWorker = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    const jobHasContractorId: number | undefined = getQueryParameter<number>(
        window.location.search,
        'jobContractor',
        { map: safeParseInt });

    return <JobAddWorkerContainer
        job={props.job}
        work={props.work}
        jobHasContractorId={jobHasContractorId}
        onSuccess={() => Routes.goToJobWorkers(workid, jobid, { jobHasContractorId: jobHasContractorId })}
        onCancel={() => Routes.goToJobWorkers(workid, jobid)} />
}

const safeParseInt = (a: any) => {
    const r = parseInt(a);
    if (!isNaN(r)) {
        return r;
    }
    else {
        return undefined;
    }
}

const WrappedWorkers = (props: any) => {
    const { jobid, workid } = getIntParams(props.match.params);

    const jobHasContractorId: number | undefined = getQueryParameter<number>(
        window.location.search,
        'jobHasContractorId',
        { map: safeParseInt });

    const relationId = getQueryParameter<number>(
        window.location.search,
        'relationId',
        { map: safeParseInt });

    return <JobLoader workId={workid} jobId={jobid}>
        <ManageJobWorkersContainer
            job={props.job}
            relationId={relationId}
            work={props.work}
            jobHasContractorId={jobHasContractorId}
            refreshWorkers={() => Routes.goToJobWorkers(workid, jobid)}
        />
    </JobLoader>;
}

const WrappedWorker = (props: any) => {
    const { jobid, workid, jobhasworkerid } = getIntParams(props.match.params);

    return <DataLoader provider={() => Actions.getJobHasWorker(workid, jobid, jobhasworkerid)}>
        {w => <ManageJobWorkersContainer
            job={props.job}
            work={props.work}
            jobHasWorker={w}
            refreshWorkers={() => Routes.goToJobWorkers(workid, jobid)} />}
    </DataLoader>
}

function JobRoutesImpl(props: IProps) {
    const [parameters, setParameters] = React.useState<any>({});
    const [loading, setLoading] = React.useState<boolean>(true);

    const setJobHasContractor = async (id: number | undefined = undefined) => {
        if (id != undefined && id != null) {
            const rel = await props.getJobHasContractor(props.work.id, props.job.id, id);
            props.selectJobHasContractor(rel);
        }
        else {
            const contractors = await props.getJobContractors(props.work.id, props.job.id);
            const contractor = await getContractorFromAppUser(props.work.id);

            const jhc = contractors.find((r: IJobHasContractor) => r.contractorId == contractor.id);
            if (jhc != undefined) {
                props.selectJobHasContractor(jhc);
            }
        }
    }

    React.useEffect(() => {
        const noParameter = parameters.jobHasContractorId == undefined
            && parameters.jobContractor == undefined;

        if (loading == true
            && (parameters.jobHasContractorId == props.selectedJobHasContractor?.id
                || parameters.jobContractor == props.selectedJobHasContractor?.id
                || (noParameter && props.selectedJobHasContractor != undefined))) {
            setLoading(false);
        }
    }, [props.selectedJobHasContractor, parameters, loading]);

    React.useEffect(() => {
        setLoading(true);
        const params = getIntQueryParameters(window.location.search);
        setParameters(params);

        // TODO: pendientes de cambiar todas las rutas (reflejar el jobHasContractor como jobHasContractorId)
        if (params.jobHasContractorId != undefined || params.jobContractor != undefined) {
            setJobHasContractor(params.jobHasContractorId ?? params.jobContractor);
        }
        // si es un contratista y no establecido que jobHasContractor es nos aseguramos de establecer
        // el primero
        else if (props.security.isContractor()) {
            setJobHasContractor();
        }
        else {
            setLoading(false);
        }
    }, [window.location.search]);

    if (loading) {
        return <div className='e r c we he center v-center'>
            <Loading />
        </div>;
    }
    else {
        return <>
            <Route
                path='/work/:workid/jobs/:jobid/data'
                component={(p: any) => <WrappedEditJobData job={props.job} {...p} {...parameters} />} />
            <Route
                path='/work/:workid/jobs/:jobid/contractors'
                component={(p: any) => <WrappedJobContractors
                    job={props.job}
                    work={props.work}
                    {...p}
                    {...parameters} />} />
            <Route
                path='/work/:workid/jobs/:jobid/card'
                component={WrappedJobCard} />
            <Route
                path='/work/:workid/jobs/:jobid/information-loaded'
                component={WrappedJobInformationLoaded} />
            <Route
                path='/work/:workid/jobs/:jobid/information-loaded-control'
                component={WrappedJobInformationLoadedControl} />
            <Route
                path='/work/:workid/jobs/:jobid/notify-information-loaded'
                component={WrappedJobNotifyInformationLoaded} />
            <Route
                path='/work/:workid/jobs/:jobid/upload-requirement'
                component={WrappedJobCreateRequirement} />
            <Route
                path='/work/:workid/jobs/:jobid/create-requirement'
                component={WrappedJobCreateResourceRequirement} />
            <Route
                path='/work/:workid/jobs/:jobid/requirements'
                component={WrappedJobRequirements} />
            <Route
                exact={true}
                path='/work/:workid/jobs/:jobid/contractor/:jobhascontractorid'
                component={(p: any) => <WrappedJobContractor job={props.job} work={props.work} {...p} />} />
            <Route
                exact={true}
                path='/work/:workid/jobs/:jobid/jobContractor/:jobhascontractorid/requirements'
                component={(p: any) =>
                    <WrappedJobHasContractorRequirements
                        job={props.job}
                        work={props.work}
                        {...parameters}
                        {...p} />} />
            <Route
                path='/work/:workid/jobs/:jobid/worker/:jobhasworkerid/requirements'
                component={(p: any) =>
                    <WrappedJobHasWorkerRequirements
                        job={props.job}
                        work={props.work}
                        {...parameters}
                        {...p} />} />
            <Route
                exact={true}
                path='/work/:workid/jobs/:jobid/worker/:jobhasworkerid'
                component={(p: any) =>
                    <WrappedWorker
                        job={props.job}
                        work={props.work}
                        {...p} />} />
            <Route
                path='/work/:workid/jobs/:jobid/add/contractor'
                component={(p: any) =>
                    <WrappedAddContractor job={props.job} work={props.work} {...p} />} />
            <Route
                path='/work/:workid/jobs/:jobid/request/contractor'
                component={(p: any) => <WrappedRequestNewContractor job={props.job} work={props.work} {...p} />} />
            <Route
                path='/work/:workid/jobs/:jobid/contractor-requests'
                component={(p: any) => <WrappedContractorRequests job={props.job} work={props.work} {...p} />} />
            <Route
                path='/work/:workid/jobs/:jobid/sub-contractor-requests'
                component={(p: any) => <WrappedSubContractorRequests job={props.job} work={props.work} {...p} />} />
            <Route
                path='/work/:workid/jobs/:jobid/contractor-invite'
                component={(p: any) => <WrappedContractorInvite job={props.job} work={props.work} {...p} />} />
            <Route
                exact
                path='/work/:workid/jobs/:jobid/machineries/add'
                component={(p: any) => <WrappedMachineryAdd job={props.job} work={props.work} {...p} />} />
            <Route
                exact
                path='/work/:workid/jobs/:jobid/machineries/create'
                component={(p: any) => <WrappedMachineryCreate job={props.job} work={props.work} {...p} />} />
            <Route
                exact
                path='/work/:workid/jobs/:jobid/machineries'
                component={(p: any) => <WrappedMachineries job={props.job} work={props.work} {...p} />} />
            <Route
                exact={true}
                path='/work/:workid/jobs/:jobid/machinery/:jobhasmachineryid/requirements'
                component={(p: any) =>
                    <WrappedJobHasMachineryRequirements
                        job={props.job}
                        work={props.work}
                        {...p} />} />
            <Route
                exact
                path='/work/:workid/jobs/:jobid/machinery/:machineryid'
                component={(p: any) => <WrappedMachinery job={props.job} work={props.work} {...p} />} />
            <Route
                exact
                path='/work/:workid/jobs/:jobid/workers/add'
                component={(p: any) => <WrappedAddWorker job={props.job} work={props.work} {...p} />} />
            <Route
                exact
                path='/work/:workid/jobs/:jobid/workers'
                component={(p: any) => <WrappedWorkers job={props.job} work={props.work} {...p} />} />
        </>;
    }
}

export const JobRoutes = React.memo(
    JobRoutesImpl,
    (a, b) => a.job?.id == b.job?.id);
