import { pushTemporalResource } from './documents';
import { getClient, gql, noCache, q, m } from './graphql';
import { IHelpDeskItem, IJob, IJobHasDepartment, IHelpDeskItemFile, ICommunication, ICommunicationFile } from '../../models';
import { formatProperty } from './utils';

export async function initialize() {
    const client = getClient('initialize');
    const query = gql`
query Initialize {
    works {
        id, name, description, countryId, defaultEmail, helpDeskEmail, footerResource, contextData,
        users {
            id, userName, email, roles, policies
        },
        settings,
        workspaces {
            id, name, description, isActive, countryId,
            regionId, countyId, contact, address,
            location, postalCode, phone, fax, email,
            properties {
                id, propertyGroupTypeId, workspaceId, value
            }
        },
        workShifts {
            id, name, startMinutes, endMinutes
        },
        departments {
            id, name, description, isOblique
        }
    }
}`;

// no sabemos por que es necesario cargar todos los trabajadores de cada departamento
// workers {
//    id, name, surname
// }

    const resp = await client.query({ query, fetchPolicy: noCache });

    return resp;
}

export async function getWorks() {
    const client = getClient('getWorks');
    const query = gql`
query GetWorks {
    works {
        id, name, description, countryId,
        defaultEmail, helpDeskEmail, footerResource, contextData,
        users {
            id, userName, email, roles, policies
        },
        settings,
        workspaces {
            id, name, description, isActive, countryId, regionId, countyId
        },
        legalForms {
            id, name, hideContractorProperties, workerTypes {
                id, workerType {
                    id, name
                }
            }
        },
        workShifts {
            id, name, startMinutes, endMinutes
        },
        departments {
            id, name, description, isOblique
        },
        contractorSettings {
            id, workId, showGeneralIndicators, legalFormMandatory,
            jhcStartDateMandatory, jhcDescriptionMandatory, cifCantBeRepeated,
            canAddSubWorkers,
            canAddSubMachineries,
        },
        machinerySettings {
            id, workId, showGeneralIndicators, subTypeMandatory, onlyLicenseNumber,
        },
        workerSettings {
            id, workId, showGeneralIndicators, showWorkerImage, workerTypeIsMandatory
        },
    }
}`;

    const resp = await client.query({ query, fetchPolicy: noCache });

    return resp;
}

export async function getWork(workId: number) {
    const client = getClient('getWork');
    const query = gql`
query GetSelectedWork($workId: Int!) {
    communications(workId: $workId) {
        id, title, description, fromDate, toDate,
        documents {
            id, name, description, title
        }
    },
    work(id: $workId) {
        id, name, description, countryId, users, {
            id, userName, email, roles, policies, departments
        },
        jobSettings {
            id, workId, skipInformationLoadedState, colorizeContractorIndicators, showResourceIncidenceIndicator
        },
        activeModules {
            id, name, hasFrontend, frontendResource
        },
        defaultEmail,
        helpDeskEmail,
        footerResource,
        contextData,
        settings,
        workerDocumentTypes{
            id, name, workId
        },
        workerTypes{
            id, name, workId
        }
        workspaces {
            id, name, description, isActive, countryId, regionId, countyId
        },
        workShifts {
            id, name, startMinutes, endMinutes
        },
        departments {
            id, name, description, isOblique, workers {
                id, name, surname, workspaces {
                    id
                }
            }
        },
        workI18NResources {
            language, name, value
        },
        permissions {
            name, action, policies, roles, expression
        },
        activities {
            id, name, workId
        },
        legalForms {
            id, name, workId, hideContractorProperties, workerTypes {
                id, workerType {
                    id, name
                }
            }
        },
        machineryTypes {
            id, name, subTypes {
                id, name, parentId
            }
        },
        propertyGroupTypes {
            id, name, title, styleClassName, description, containerType, style, objectType, isInternal, mandatory, hidable,
            properties {
                id, name, title, typeId, placeholder, help, parentId, propertyOrder, selectOptions
            }
        },
        contractTypes {
            id, name
        },
        preventiveResources {
            id, name
        },
        requirementGroups {
            id, name, title, description, workId, orderValue, color, icon, style, isActive, legend,
            requirementTypes {
                id, name, title, description, workId, requirementGroupId,
                targetType, isActive, isShared, kindId,
                criteria, template,
                expirationTypeId, expirationValue, expirationTypeId,
                expirationRequired,
                expirationThreshold,
                color, orderValue,
                requireJobInformationAccess,
                validationTypeId,
                isResourcePicture,
                dependencies {
                    id, requirementTypeId, dependsOnRequirementId, propagate, dependencyType
                },
                documentRestrictions {
                    id, name, title, mimeTypes, regexp, excludeMimeTypes,
                },
                suggestions {
                    id, name
                },
                validationHolders {
                    id, appUserId, departmentId, workFunctionId, isBlocking,
                    validationOrder, jobResponsible, optional,
                    jobImpliedRso,
                }
            }
        },
        requirementStatusTypes {
            id, name, description
        },
        requirementValidationTypes {
            id, name
        },
        resourceStatusTypes {
            id, name
        },
        validations {
            id, name, value, field, resourceType, errorMessage
        },
        contractorSettings {
            id, workId, showGeneralIndicators, legalFormMandatory,
            jhcStartDateMandatory, jhcDescriptionMandatory, cifCantBeRepeated,
            canAddSubWorkers,
            canAddSubMachineries,
        },
        machinerySettings {
            id, workId, showGeneralIndicators, subTypeMandatory, onlyLicenseNumber,
        },
        workerSettings {
            id, workId, showGeneralIndicators, showWorkerImage, workerTypeIsMandatory
        },
    }
}`;

    const resp = await client.query({
        query,
        fetchPolicy: noCache,
        variables: {
            workId
        }
    });
    return resp;
}

export async function getContractors(workId: number) {
    const resp = q(`query GetContractors($workId: Int!) {
        work(id: $workId) {
            contractors {
                id, name, code
            }
        }
    }`, { workId }, {
        returnPath: 'work.contractors',
    });

    return resp;
}

// Jobs
export async function getJobs(workId: number) {
    const resp = q(`
    query GetJobs($workId: Int!) {
        work(id: $workId) {
            jobs {
                id, name, code, workspaceId, activityTypeId, workId
                workShiftId, contractTypeId, description,
                startDate, endDate, remarks, preventiveResourceId,
                countDepartmentsTotal, countDepartmentsAuthorized,
                countMachineriesTotal, countMachineriesAuthorized,
                countWorkersTotal, countWorkersAuthorized,
                countContractorsTotal, countContractorsAuthorized, isActive,
                contractors {
                    id, contractorId, contractor {id, name}, startDate, currentStatusType,
                    forcedStatusUntilDate,
                    hierarchyLevel,
                },
                machineries {
                    id, machineryId, startDate, machinery {id, name, code, contractorId,
                    contractor {id, name}}, currentStatusType, forcedStatusUntilDate
                }
                departments {
                    id, departmentId, responsibleId, currentStatusType, forcedStatusUntilDate
                }
            }
        }
    }`, { workId });

    return resp;
}

export async function getJobReferences(workId: number) {
    const resp = q(`
    query GetJobReferences($workId: Int!) {
        work(id: $workId) {
            jobs {
                id, name, code
            }
        }
    }`, { workId });

    return resp;
}

export async function saveJob(inputData: IJob, departments: IJobHasDepartment[] = []) {
    const data = {
        ...inputData,
        properties: (inputData.properties ?? []).map(formatProperty(inputData.workId)),
    };

    const client = getClient('saveJob');
    const mutation = gql`
mutation SaveJob($workId: Int!, $data: JobInputType!, $departments: [JobHasDepartmentInputType]) {
    saveJob(workId: $workId, job: $data, departments: $departments) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            workId: inputData.workId,
            data,
            departments,
        }
    });

    return resp;
}

export async function removeContractorInvitation(id: number, workId: number) {
    const client = getClient('removeContractorInvitation');
    const mutation = gql`
    mutation RemoveContractorInvitation($id: Int!, $workId: Int!) {
        removeContractorInvitation(id: $id, workId: $workId) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId
        }
    });

    return resp;
}

export async function removeJob(id: number, workId: number) {
    const client = getClient('removeJob');
    const mutation = gql`
    mutation RemoveJob($id: Int!, $workId: Int!) {
        removeJob(id: $id, workId: $workId) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId
        }
    });

    return resp;
}

export async function createHelpDeskItem(
    input: IHelpDeskItem,
    files: File[] = []) {
    const client = getClient('createHelpDeskItem');
    const inputFileResources: IHelpDeskItemFile[] = [];

    if (files) {
        for (const f of files) {
            const res = await pushTemporalResource(f);
            if (res.hasValue) {
                inputFileResources.push({
                    fileName: f.name,
                    temporalResourceId: res.value,
                });
            }
        }
    }

    input.files = inputFileResources;

    const mutation = gql`
mutation CreateHelpDeskItem($input: HelpDeskItemInputType!) {
    createHelpDeskItem(item: $input) {
        isError, error, value, hasValue
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            input
        }
    });

    return resp.data.createHelpDeskItem;
}

export async function saveCommunication(
    input: ICommunication, deletion: boolean = false) {
    const client = getClient('saveCommunication');

    const mutation = gql`
mutation saveCommunication($input: CommunicationGraphInputType!, $deletion: Boolean!) {
    saveCommunication(data: $input, deletion: $deletion) {
        isError, error, value, hasValue
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            input,
            deletion
        }
    });

    return resp.data.saveCommunication;
}

export async function getCommunicationReceivers(
    communication: ICommunication) {
    const resp = q(`
    query GetCommunicationReceivers($communication: CommunicationGraphInputType!) {
        communicationReceivers(communication: $communication) {
            id, userName, email
        }
    }`,
        { communication },
        { returnPath: 'communicationReceivers' });

    return resp;
}

export async function getCommunication(
    workId: number,
    id: number) {
    const resp = q(`
    query GetCommunicationById($workId: Int!, $id: Int!) {
        communication(workId: $workId, id: $id) {
            id, creationDate, createdBy, workId,
            fromDate,
            toDate,
            title, description,
            targetType,
            isActive, isRecursive,
            mainContractors,
            documents {
                id, name, description, title
            }
        }
    }`,
        { workId, id },
        { returnPath: 'communication' });

    return resp;
}

export function validateCommunication(id: number) {
    return m(`
    mutation ValidateCommunication($id: Int!) {
        validateCommunication(id: $id) {
            isError, error, value, hasValue
        }
    }`, { id }, { returnPath: 'validateCommunication' });
}


export function SaveUserPolicies(userId: number, policies: string[]) {
    return m(`
    mutation SaveUserPolicies($userId: Int!, $policies: [String]! ) {
        saveUserPolicies(userId: $userId, policies: $policies) {
            isError, error, value, hasValue
        }
    }`, { userId, policies }, { returnPath: 'saveUserPolicies' });
}