import * as React from 'react';

import {
    BreadcrumbItem,
    G,
    InputSwitch,
    RequireRole,
    SplitButton,
    Tag,
    useConfirm,
    useDataTable,
    useDialogs,
    useForm,
    useLoading,
    useMessage,
    useNumberState,
    useObjectState,
    usePaginator,
    useInputSearch,
    useResolveName,
    useSearch,
    useSearchFilters,
    useSearchPaginate,
    useTranslation,
    useWorkFormSettings,
    ValidationBuilder,
    usePermissions,
    useToast
} from '@components';
import { DynamicProperties } from '@components/user/DynamicProperties';
import { IMachinery, ISecurity, IUserIdentity, IWork } from '@models';
import { IContractor, IMachinerySubType, IMachineryType, IResourceStatusType, ResourceType } from '@models/resources';
import { IFieldValidation, IPropertyGroupType, PropertyGroupObjectType } from '@models/works';

import { RestrictMachinery } from './RestrictMachinery';
import { RestrictedMachineries } from './RestrictedMachineries';

import './ViewMachineries.scss';
import { redirectTo } from '@utils';
import { ResourceStatus } from '@components/common/ResourceStatus';
import MachineryJobsDialog from '@containers/machineries/MachineryJobsDialogContainer';
import { goToMachineryRequirements } from '@components/routes';
import { ModuleManager } from '@modules';

export interface IProps {
    getMachineries: Function;
    machinery: IMachinery;
    machineryTypes: IMachineryType[];
    propertyGroupTypes: IPropertyGroupType[];
    resourceStatusTypes: IResourceStatusType[];
    saveMachinery: Function;
    reactivateMachinery: Function;
    removeMachinery: Function;
    security: ISecurity;
    work: IWork;
    appUsers: IUserIdentity[];
    contractors: IContractor[];
    modules: ModuleManager;
    restrictMachinery: Function;
    removeRestrictedMachinery: Function;
}

export interface IMachinerySubTypeWithDisabling extends IMachinerySubType {
    disabled?: boolean;
}

function AddMachinery({
    machineryTypes, onChange, propertyGroupTypes, saveMachinery, workId, security, fieldValidations, contractors, work
}: {
    machineryTypes: IMachineryType[],
    contractors: IContractor[],
    onChange: Function,
    propertyGroupTypes: IPropertyGroupType[],
    saveMachinery: Function,
    workId: number,
    security: ISecurity;
    fieldValidations: IFieldValidation[];
    work: IWork;
}) {
    const machinerySettings = work.machinerySettings;
    const { t } = useTranslation();
    const messages = useMessage({
        successMessage: t('Machinery added successfully'),
        autoClear: true,
    });

    const loading = useLoading();
    const [subTypes, setSubTypes] = React.useState<IMachinerySubTypeWithDisabling[]>([]);

    const settings = useWorkFormSettings({ work: work, scope: 'machinery' });

    const selectedMachineryType = useNumberState();
    const selectedSubType = useNumberState();
    const selectedContractor = useNumberState();

    const [createJobRelations, setCreateJobRelations] = React.useState<boolean>(false);
    const [selectedMachineryTypeId, setSelectedMachineryTypeId] = React.useState<number>(0);

    const GRUA_AUTOPROPULSADA = 104;
    const CAMION_PLUMA = 105;
    const TURISMO_FURGONETA_Y_CAMIONES = 106;
    const MAQUINARIA_SIN_MATRICULA = 110;
    const MAQUINARIA_CON_MATRICULA = 109;
    const PLATAFORMA_ELEVADORA = 103;
    const EQUIPOS_DE_TRABAJO = 111;


    const formInit = () => {
        if (workId == 21) {
            return useForm<IMachinery>({
                initialValues: {
                    id: 0,
                    workId: workId,
                    name: '',
                    code: '',
                    machineryTypeId: selectedMachineryType.value,
                    machinerySubTypeId: selectedSubType.value,
                    contractorId: selectedContractor.value,
                    prefixLicenseNumber: '',
                    licenseNumber: '',
                    suffixLicenseNumber: '',
                },
                validateOnMount: true,
                validate: ValidationBuilder
                    .new()
                    .notEmpty('machineryTypeId')
                    .notEmptyIf(machinerySettings?.subTypeMandatory, 'machinerySubTypeId')
                    .withFieldValidations(ResourceType.Machinery, fieldValidations)
                    .notEmpty('licenseNumber', t('LicenseNumber/N.Serierequired'))
                    .lift()
            });
        } else if (workId == 23) {
            return useForm<IMachinery>({
                initialValues: {
                    id: 0,
                    workId: workId,
                    name: '',
                    code: '',
                    machineryTypeId: selectedMachineryType.value,
                    machinerySubTypeId: selectedSubType.value,
                    contractorId: selectedContractor.value,
                    prefixLicenseNumber: '',
                    licenseNumber: '',
                    suffixLicenseNumber: '',
                },
                validateOnMount: true,
                validate: (data: IMachinery) => ValidationBuilder
                    .create(data)
                    .notEmpty('machineryTypeId')
                    .notEmptyIf(machinerySettings?.subTypeMandatory && subTypes.length > 0, 'machinerySubTypeId')
                    .notEmptyIf([GRUA_AUTOPROPULSADA, CAMION_PLUMA, TURISMO_FURGONETA_Y_CAMIONES, MAQUINARIA_CON_MATRICULA].includes(data.machineryTypeId ?? 0), 'licenseNumber', t('code is required'))
                    .notEmptyIf([MAQUINARIA_SIN_MATRICULA, PLATAFORMA_ELEVADORA, EQUIPOS_DE_TRABAJO].includes(data.machineryTypeId ?? 0), 'code', t('code is required'))
                    .withFieldValidations(ResourceType.Machinery, fieldValidations)
                    .build()
            })
        } else {
            return useForm<IMachinery>({
                initialValues: {
                    id: 0,
                    workId: workId,
                    name: '',
                    code: '',
                    machineryTypeId: selectedMachineryType.value,
                    machinerySubTypeId: selectedSubType.value,
                    contractorId: selectedContractor.value,
                    prefixLicenseNumber: '',
                    licenseNumber: '',
                    suffixLicenseNumber: '',
                },
                validateOnMount: true,
                validate: ValidationBuilder
                    .new()
                    .notEmpty('machineryTypeId')
                    .notEmptyIf(machinerySettings?.subTypeMandatory && subTypes.length > 0, 'machinerySubTypeId')
                    .withFieldValidations(ResourceType.Machinery, fieldValidations)
                    .lift()
            });
        }
    }

    const form = formInit();

    React.useEffect(() => {
        setSelectedMachineryTypeId(form.values.machineryTypeId);
    }, [form.values.machineryTypeId]);

    React.useEffect(() => {
        selectedSubType.set(undefined);
        form.setFieldValue('machinerySubTypeId', undefined);

        if (form.values.machineryTypeId) {
            const mt = machineryTypes.find(mt => mt.id == form.values.machineryTypeId);
            setSubTypes(mt?.subTypes ?? []);
        }
        else {
            setSubTypes([]);
        }
    }, [form.values.machineryTypeId]);

    const addRecord = useObjectState({ properties: [] });

    const doAdd = loading.wrap(async (..._: any) => {
        const newRecord: IMachinery = {
            ...addRecord.value,
            ...form.values,
            isActive: true,
        };

        const resp = await saveMachinery(workId, newRecord, createJobRelations);

        let respMessage = resp;
        if (respMessage.error == 'restricted.machinery.message') {
            respMessage.error = `${t('restricted.machinery.message')} ${form.values.code && form.values.code.length > 0 ? form.values.code : form.values.licenseNumber}`;
        }

        messages.set(respMessage);
        if (resp.hasValue) {
            onChange();
        }
    });

    React.useEffect(() => {
        if (workId == 21 && subTypes.some(st => st.name == "Autocarro")) {
            subTypes.find(st => st.name == "Autocarro")!.disabled = true;
        }
    }, [subTypes]);

    return <div className=''>
        <div className='c lg pd g-20 form-1 l200 he'>
            <G label={t('Machinery type')}>
                {form.select('machineryTypeId', { options: machineryTypes.sort((a, b) => a.name.localeCompare(b.name)) })}
            </G>
            {subTypes.length > 0 &&
                <G label={t('Machinery subtype')}>
                    {form.select('machinerySubTypeId', { options: subTypes.sort((a, b) => a.name.localeCompare(b.name)) })}
                </G>
            }
            {settings.show('name') &&
                <G label={t('Name')}>
                    {form.input('name')}
                </G>}
            {(settings.show('code') && workId !== 23 || (workId === 23 && [MAQUINARIA_SIN_MATRICULA, PLATAFORMA_ELEVADORA, EQUIPOS_DE_TRABAJO].includes(selectedMachineryTypeId))) &&
                <G label={t('machinery.Code')}>
                    {form.input('code')}
                </G>}
            {(workId !== 23 || (workId === 23 && [GRUA_AUTOPROPULSADA, CAMION_PLUMA, TURISMO_FURGONETA_Y_CAMIONES, MAQUINARIA_CON_MATRICULA].includes(selectedMachineryTypeId))) &&
                <G label={workId !== 21 ? t('License plate') : t('License plate21')}>
                    {work.machinerySettings?.onlyLicenseNumber == false &&
                        form.inputs(
                            ['prefixLicenseNumber', 'licenseNumber', 'suffixLicenseNumber'],
                            {
                                'prefixLicenseNumber': { placeholder: t('Letter'), className: 'flat', containerStyle: { width: '40%' } },
                                'licenseNumber': { placeholder: t('Number'), containerClassName: '', className: 'flat' },
                                'suffixLicenseNumber': { placeholder: t('Letter'), className: 'flat-left', containerStyle: { width: '40%' } },
                            }
                        )
                    }
                    {work.machinerySettings?.onlyLicenseNumber == true &&
                        form.input('licenseNumber')
                    }
                </G>}
            <RequireRole oneOf={['worker', 'gestor']}>
                <G label={t('Contractor')}>
                    {form.select('contractorId', { options: contractors, filter: true })}
                </G>
            </RequireRole>

            <div id={'embed-properties'} />

            <DynamicProperties
                object={addRecord.value}
                onChange={addRecord.set('properties')}
                objectType={PropertyGroupObjectType.JobHasMachinery}
                propertyGroupTypes={propertyGroupTypes} />

            <div className='e' />

            {messages.render()}

            <div className='r'>
                {work.settingsObj?.createJobRelations == true &&
                    <div className='relationJobs'>
                        <InputSwitch checked={createJobRelations}
                            onChange={e => setCreateJobRelations(e.target.value)} />
                        <span className='e'>{t('createJobRelations.machineries')}</span>
                    </div>}
                <div className='e' />
                {loading.render()}
                <button
                    className='primary'
                    disabled={form.isInvalid()}
                    onClick={doAdd}>
                    {t('Add')}
                </button>
            </div>
        </div>
    </div>
}

enum FormType {
    List = 0,
    AddMachinery = 1,
    RestrictedMachineries
}

type IMachineryRow = IMachinery & {
    contractorName: string;
    validatedCount?: number;
    totalCount?: number;
}

export function ViewMachineriesImpl(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const toast = useToast();

    const filterKey = `filters.machineries[workId=${props.work.id}]`;

    const filter = useInputSearch({ filterKey });
    const settings = useWorkFormSettings({ work: props.work, scope: 'machinery' });

    const [machinerySubtypes, setMachinerySubtypes] = React.useState<any>([]);

    const [formType, setFormType] = React.useState<FormType>(FormType.List);

    const userFilters = useSearchFilters({
        security: props.security,
        workId: props.work.id,
        name: 'machineries/list',
        references: {
            contractor: props.contractors,
            machinery_type: props.machineryTypes,
            machinery_sub_type: Array.from(new Set(machinerySubtypes)),
        },
        persist: true,
    });

    React.useEffect(() => {
        if (userFilters.filters.active == null) {
            userFilters.setFilter('active', true);
        }
    }, [userFilters.filters]);

    const viewMachineriesProps = {
        security: props.security,
        moduleManager: props.modules,
        work: props.work,
    };

    const permissionCtx = {
        security: props.security,
    };

    const modulePerms = usePermissions(
        viewMachineriesProps, {
        ctx: permissionCtx,
    }, {
        canAddMachineries: {
            name: 'resources.add',
            default: props.security.hasPermission('machineries.add') || props.security.isContractor()
        }
    });

    const canAdd = modulePerms.get('canAddMachineries');


    React.useEffect(() => {
        if (props.work.id === 23) {
            calculateSubTypes(userFilters.filters.machinery_type)
        }
    }, [userFilters.filters.machinery_type]);

    const calculateSubTypes = (machineryTypeId: number) => {
        const subTypes = props.machineryTypes.map(mt => mt.subTypes).reduce((a: any, b) => a.concat(b), []);
        const filteredSubTypes = subTypes.filter((st: any) => st.parentId === machineryTypeId);

        setMachinerySubtypes(filteredSubTypes);
    }

    const paginateData = useSearchPaginate<IMachineryRow>({
        search: 'machineries/list',
        workId: props.work.id,
        filters: userFilters.merge({ name: filter.value, workId: props.work.id }),
        dependencies: [filter.value, userFilters.filters],
        lazy: true,
        persist: true,
        limit: props.work.id == 21 ? 100 : 10,
    });

    const paginator = usePaginator<IMachineryRow>({
        data: paginateData.value,
        onChange: (page, limit) => paginateData.doSearch(
            userFilters.merge({ name: filter.value, workId: props.work.id }),
            page,
            limit),
        onExport: () => paginateData.exportToXls(
            userFilters.merge({ workId: props.work.id }),
            t('machineries.export.filename')),
    });

    const resolveMachineryType = useResolveName(props.machineryTypes, { translate: true });

    const resolveMachinerySubType = (m: IMachinery) => {
        const smt = props.machineryTypes.find(mt => mt.id == m.machineryTypeId)?.subTypes?.find(st => st.id == m.machinerySubTypeId)?.name;
        return smt;
    }

    const renderMachineryType = (m: IMachinery) => {
        const value = t(resolveMachineryType(m.machineryTypeId));
        return <div>
            <span title={value}>{value}</span>
        </div>
    }

    const renderMachinerySubType = (m: IMachinery) => {
        const value = t(resolveMachinerySubType(m) ?? '');
        return <div>
            <span title={value}>{value}</span>
        </div>
    }

    const contractors = useSearch<IContractor>({
        workId: props.work.id,
        search: 'machineries/contractors',
        filters: {
            workId: props.work.id,
        },
    });

    const reload = loading.wrap(async () => {
        await paginateData.doSearch(userFilters.merge({ name: (filter instanceof String || typeof filter === 'string') ? filter.value : undefined, workId: props.work.id }));
    });

    const initialize = loading.wrap(async (doReload: boolean = false) => {
        await paginateData.doSearch(userFilters.merge({ workId: props.work.id }));
        setFormType(FormType.List);
        // const subTypes = props.machineryTypes.map(mt => mt.subTypes).reduce((a: any, b) => a.concat(b), []);
        // setMachinerySubtypes(subTypes);
        if (doReload) {
            await reload();
        }
    });

    const selectMachinery = (machinery: IMachinery | undefined) => {
        if (machinery) {
            redirectTo(`/work/${props.work.id}/machineries/${machinery.id}`);
        }
    }

    const machineryRequirements = (id: number) => {
        redirectTo(`/work/${props.work.id}/machineries/${id}/requirements`);
    }

    const renderLicensePlate = (m: IMachinery) => {
        const value = m.prefixLicenseNumber + '' + m.licenseNumber + '' + m.suffixLicenseNumber;
        if (m.prefixLicenseNumber != null) {
            return <div>
                <span title={value}>{value}</span>
            </div>;
        }
        else {
            return null;
        }
    }

    const renderStatus = (machinery: IMachinery) =>
        <ResourceStatus
            currentStatusType={machinery.currentStatusType!}
            resourceId={machinery.id}
            resourceType={ResourceType.Machinery}
            workId={props.work.id} />;

    const doActivateMachinery = loading.wrap(async (i: IMachinery) => {
        await props.reactivateMachinery(props.work.id, i.id);
        await initialize();
    });

    const confirmActivate = useConfirm({
        message: t('resource.activate.machinery.confirm'),
        accept: doActivateMachinery
    });

    const doDeactivateMachinery = loading.wrap(async (i: IMachinery) => {
        await props.removeMachinery(i.id, props.work.id, false);
        await initialize();
    });

    const doRemoveMachinery = loading.wrap(async (i: IMachinery) => {
        await props.removeMachinery(i.id, props.work.id, true);
        await initialize();
    });

    const confirmDeactivate = useConfirm({
        message: t('resource.deactivate.machinery.confirm'),
        accept: doDeactivateMachinery
    })

    const confirmRemove = useConfirm({
        message: t('resource.remove.machinery.confirm'),
        accept: doRemoveMachinery
    })

    const handleToast = (message: string, success: boolean) => {
        if (success) {
            toast.show(message)
        }
        else {
            toast.error(message)
        }
    }

    const dataTable = useDataTable({
        columns: [
            { title: t('License plate'), render: renderLicensePlate, className: `${props.work.id != 21 ? 'td-min left' : 'td-md'}`, sortKey: 'licenseNumber', },
            settings.show('name')
                ? { title: t('Name'), render: d => <span title={d.name}>{d.name}</span>, field: 'name', className: 'td-lg left', sortKey: 'name' }
                : undefined,
            settings.show('code')
                ? { title: t('machinery.codes'), render: d => <span title={d.code}>{d.code}</span>, field: 'code', className: `${props.work.id != 21 ? 'td-min center left' : 'td-md center'}`, sortKey: 'code', }
                : undefined,
            { title: t('Machinery type'), render: renderMachineryType, className: `${props.work.id != 21 ? 'td-max center' : 'td-md'}`, sortKey: 'machineryType' },
            { title: t('Machinery subtype'), render: renderMachinerySubType, className: 'td-md', sortKey: 'machinerySubtype' },
            {
                title: t('Contractor'),
                render: d => <span title={d.contractorName}>{d.contractorName}</span>,
                className: `${props.work.id != 21 ? 'td-max center left' : 'td-md'}`,
                sortKey: 'contractorName',
            },
            props.work.id != 19
                ? {
                    title: 'Documentation', className: `${props.work.id != 21 ? 'w-7 center' : 'td-indicator td-sm center'}`,
                    render: d =>
                        <span onClick={() => goToMachineryRequirements(props.work.id, d.id)} className='pointer'>
                            <Tag value={`${d?.validatedCount ?? 0}/${d?.totalCount ?? 0}`} />
                        </span>
                }
                : undefined,
            props.work.id != 19 && props.work.id != 23
                ? { title: t('Status'), field: 'currentStatusType', render: renderStatus, className: 'td-md' }
                : undefined
        ],
        actions: [
            {
                headerClassName: 'td-sm', actions: [
                    { title: t('View data'), onClick: selectMachinery },
                    { title: t('Requirements'), onClick: d => machineryRequirements(d.id) },
                    { title: t('Jobs'), disabled: r => r.contractorId == undefined, onClick: r => dialogs.show('jobs-dialog', r) },
                    (props.security.hasPermission('machineries.force.status'))
                        ? { title: t('resource.deactivate'), disabled: r => r.isActive == false, onClick: confirmDeactivate }
                        : undefined,
                    (props.security.hasPermission('machineries.force.status'))
                        ? { title: t('resource.activate'), disabled: r => r.isActive == true, onClick: confirmActivate }
                        : undefined,
                    (props.security.isGestor())
                        ? { title: t('resource.remove'), onClick: confirmRemove }
                        : undefined,
                    (props.security.hasPermission('restrict.resources'))
                        ? { title: t('restrict.machinery'), onClick: r => dialogs.show('restrict-machinery', { workId: props.work.id, machineryId: r.id }) }
                        : undefined
                ]
            }
        ],
        data: paginateData.data,
        onSort: (key, order) => paginateData.sortBy(key, order),
        denyLocalSort: true,
    });

    const getActions = () => {
        const actions = [];
        const security = props.security;
        actions.push({
            label: t('machineries.list'),
            command: () => setFormType(FormType.List),
        });

        if (canAdd) {
            actions.push({
                label: t('machineries.add'),
                command: () => setFormType(FormType.AddMachinery),
            });
        }

        if (props.security.hasPermission('restrict.resources')) {
            actions.push({
                label: t('restrict.machinery.add'),
                command: () => setFormType(FormType.RestrictedMachineries),
            });
        }

        return actions;
    }

    const actions = getActions();

    React.useEffect(() => {
        initialize();
    }, []);

    React.useEffect(() => {
        initialize(true)
    }, [userFilters.filters]);

    return <div className='ViewMachineries'>
        {toast.render()}
        <BreadcrumbItem
            redirectTo={`/work/${props.work.id}/machineries`}
            text={t('Machineries')} />

        <div className='h100p c he'>
            <div className='c he r toolbar'>
                <div className='p-inputgroup'>
                    <SplitButton
                        className='flat'
                        label={t('Actions')}
                        model={actions} />

                    {filter.render()}

                    {userFilters.renderAsButton()}
                    {loading.renderBox()}
                    <span className='p-inputgroup-addon'>
                        <i className='pi pi-search' />
                    </span>
                </div>
                {formType === FormType.List && <>
                    {dialogs.render('jobs-dialog',
                        {
                            style: { width: '60vw' },
                            title: (r: IMachinery) => t('Jobs') + ': ' + r.name,
                            className: 'table-bg'
                        },
                        (r: IMachinery) =>
                            <div className={'table-bg'}>
                                <MachineryJobsDialog
                                    requestClose={dialogs.clear}
                                    machineryId={r.id ?? 0}
                                    machineryTitle={r.name}
                                    work={props.work.id} />
                            </div>
                    )}
                    {dialogs.render('restrict-machinery', {
                        title: t('restrict.machinery.add'),
                        className: 'g pd',
                        style: { width: '60vw' }
                    }, (r: any) =>
                        <RestrictMachinery
                            {...viewMachineriesProps}
                            selectedMachineryId={r.machineryId}
                            data={paginateData.data}
                            restrictMachinery={props.restrictMachinery}
                            handleToast={handleToast}
                            onClose={dialogs.clear}
                            machineryTypes={props.machineryTypes}
                        />
                    )}
                    <div>
                        {dataTable()}
                    </div>
                    <div className='e' />
                    {paginator()}
                </>}
                {formType === FormType.AddMachinery && <>
                    <div className='md mr pd box'>
                        <AddMachinery
                            security={props.security}
                            machineryTypes={props.machineryTypes}
                            onChange={initialize}
                            propertyGroupTypes={props.propertyGroupTypes}
                            saveMachinery={props.saveMachinery}
                            workId={props.work.id}
                            fieldValidations={props.work.validations!}
                            contractors={contractors.value}
                            work={props.work} />
                    </div>
                </>}
                {formType === FormType.RestrictedMachineries && <>
                    <RestrictedMachineries
                        workId={props.work.id}
                        removeRestrictedMachinery={props.removeRestrictedMachinery}
                        handleToast={handleToast}
                    />
                </>}
            </div>
        </div>
    </div>
}

export const ViewMachineries = React.memo(ViewMachineriesImpl, (prev, next) => {
    return prev.work.id === next.work.id;
});