import * as React from 'react';

import {
    G, useToast, Portal, useForm, useLoading, useTranslation,
    ValidationBuilder, useNumberState, useWorkFormSettings,
} from '@components';
import { DynamicProperties } from '@components/user/DynamicProperties';
import {
    IPropertyGroupType, PropertyGroupObjectType, ISecurity,
    IWork, ResourceType, IFieldValidation, ModuleManager,
} from '@models';

import './EditMachinery.scss';
import useContractorFromAppUser from '@components/custom/useContractorFromAppUser';
import { IMachinery, IMachinerySubType, IMachineryType } from '@models/resources';
import { delay, redirectTo } from '@utils';

type getMachineryF = (workId: number, id: number) => Promise<IMachinery>;

export interface IProps {
    propertyGroupTypes: IPropertyGroupType[];
    getMachinery: getMachineryF;
    work: IWork;
    machinery: IMachinery;
    machineryId: number;
    reactivateMachinery: Function;
    security: ISecurity;
    saveMachinery: Function;
    machineryTypes: IMachineryType[];
    machinerySubTypes: IMachinerySubType[];
    moduleManager: ModuleManager;
}

export interface IMachinerySubTypeWithDisabling extends IMachinerySubType {
    disabled?: boolean;
}

const validateMachineryF = (fieldValidations: IFieldValidation[] | undefined, custom: any[]) =>
    ValidationBuilder
        .new<IMachinery>()
        .withFieldValidations(ResourceType.Machinery, fieldValidations)
        .customValidations(custom)
        .lift();

export function EditMachineryForm(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const toast = useToast();
    const [subTypes, setSubTypes] = React.useState<IMachinerySubType[]>([]);
    const [selectedMachineryTypeId, setSelectedMachineryTypeId] = React.useState<number>(0);

    const selectedSubType = useNumberState();

    const GRUA_AUTOPROPULSADA = 104;
    const CAMION_PLUMA = 105;
    const TURISMO_FURGONETA_Y_CAMIONES = 106;
    const MAQUINARIA_SIN_MATRICULA = 110;
    const MAQUINARIA_CON_MATRICULA = 109;
    const PLATAFORMA_ELEVADORA = 103;
    const EQUIPOS_DE_TRABAJO = 111;

    const settings = useWorkFormSettings({ work: props.work, scope: 'machinery' });

    const selfContractor = useContractorFromAppUser(props.work.id, props.security);

    const saveMachinery = loading.wrap(async (req: IMachinery) => {
        const resp = await props.saveMachinery(props.work.id, req);

        if (resp.hasValue) {
            toast.show(t('Machinery saved successfully'));
            if (props.work.id != 21) {
                await delay(2000);
                redirectTo(`/work/${props.work.id}/machineries`);
            }
            return { ok: true };
        }
        else {
            return { ok: false, message: resp.error };
        }
    });

    const doSave = (..._: any) => {
        saveMachinery(form.values);
    }

    const canSave = props.security.hasPermission('machinery.edit')
        || props.machinery.contractorId === selfContractor;

    const customValidations = [
        {
            name: 'licenseNumber',
            msg: 'licenseNumber is required',
            validation: (m: IMachinery) => props.moduleManager.validateData('machinery.licenseNumber', m),
        }
    ];

    const validateMachinery = validateMachineryF(props.work.validations, customValidations);

    const form = useForm<IMachinery>({
        readonly: !canSave,
        initialValues: {
            id: props.machineryId,
            workId: props.work.id,
            name: props.machinery.name,
            code: props.machinery.code,
            description: props.machinery.description,
            machineryTypeId: props.machinery.machineryTypeId,
            machinerySubTypeId: props.machinery.machinerySubTypeId,
            isActive: props.machinery.isActive,
            contractorId: props.machinery.contractorId,
            prefixLicenseNumber: props.machinery.prefixLicenseNumber,
            licenseNumber: props.machinery.licenseNumber,
            suffixLicenseNumber: props.machinery.suffixLicenseNumber,
            properties: props.machinery.properties,
        },
        validate: validateMachinery,
        onSubmit: (data: IMachinery) => {
            return saveMachinery(data);
        }
    });

    React.useEffect(() => {
        selectedSubType.set(undefined);
        if (form.values.machineryTypeId) {
            const mt = props.machineryTypes.find(mt => mt.id == form.values.machineryTypeId);
            if (mt?.subTypes != undefined) {
                const st: IMachinerySubTypeWithDisabling[] = mt.subTypes;
                if (st.some(st => st.name == "Autocarro")) {
                    st.find(st => st.name == "Autocarro")!.disabled = true;
                }
            }

            if (form.values.machineryTypeId) {
                const mt = props.machineryTypes.find(mt => mt.id == form.values.machineryTypeId);
                setSubTypes(mt?.subTypes ?? []);
                setSelectedMachineryTypeId(form.values.machineryTypeId);
            }
            else {
                setSubTypes([]);
            }
        }
    }, [form.values.machineryTypeId]);

    return <div className='EditMachineryContainer'>

        <Portal container={'#breadcrumb-right'}>
            {loading.render()}
        </Portal>

        {toast.render()}

        {props.machinery &&
            <div className='c we '>
                <div className=' md mr pd'>
                    <div className='c lg pd g-20 form-1 l200 he'>
                        {settings.show('name') &&
                            <G label={t('Name')}>
                                {form.input('name', { autoFocus: true })}
                            </G>}
                        {(settings.show('code') && props.work.id !== 23 || (props.work.id === 23 && [MAQUINARIA_SIN_MATRICULA, PLATAFORMA_ELEVADORA, EQUIPOS_DE_TRABAJO].includes(selectedMachineryTypeId))) &&
                            <G label={props.work.id == 21 ? t('Code') : 'Número de serie/bastidor'}>
                                {form.input('code')}
                            </G>}
                        {(props.work.id == 21 && settings.show('description')) &&
                            <G label={t('Description')}>
                                {form.textarea('description')}
                            </G>}
                        <G label={t('Machinery type')}>
                            {form.select('machineryTypeId', props.machineryTypes)}
                        </G>
                        {subTypes.length > 0 &&
                            <G label={t('Machinery subtype')}>
                                {form.select('machinerySubTypeId', subTypes)}
                            </G>}
                        {(props.work.id !== 23 || (props.work.id === 23 && [GRUA_AUTOPROPULSADA, CAMION_PLUMA, TURISMO_FURGONETA_Y_CAMIONES, MAQUINARIA_CON_MATRICULA].includes(selectedMachineryTypeId))) &&
                            <G label={t('License plate')}>
                                {props.work.machinerySettings?.onlyLicenseNumber == false &&
                                    form.inputs(
                                        ['prefixLicenseNumber', 'licenseNumber', 'suffixLicenseNumber'],
                                        {
                                            'prefixLicenseNumber': { placeholder: t('Letter'), className: 'flat', containerStyle: { width: '40%' } },
                                            'licenseNumber': { placeholder: t('Number'), containerClassName: '', className: 'flat' },
                                            'suffixLicenseNumber': { placeholder: t('Letter'), className: 'flat-left', containerStyle: { width: '40%' } },
                                        }
                                    )}
                                {props.work.machinerySettings?.subTypeMandatory == true &&
                                    form.input('licenseNumber')
                                }
                            </G>}

                        <div id={'embed-properties'}>
                            <DynamicProperties
                                inline={true}
                                readonly={!canSave}
                                className='e sm mr-left'
                                object={props.machinery}
                                objectType={PropertyGroupObjectType.JobHasMachinery}
                                onChange={v => form.setFieldValue('properties', v)}
                                propertyGroupTypes={props.propertyGroupTypes} />
                        </div>

                        <div className='e' />

                        <div className='r'>
                            <span className='e' />
                            {canSave &&
                                <button
                                    className='primary'
                                    disabled={form.isInvalid()}
                                    onClick={doSave}>
                                    {t('Save')}
                                </button>}
                        </div>
                    </div>
                </div>
            </div>}
    </div>
}
