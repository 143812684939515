import * as React from 'react';

import { IEventTrigger, getEventTriggerTypes, } from '@models';
import {
    Message,
    useForm,
    useTranslation,
    ValidationBuilder,
} from '@components';

import './EventTriggerForm.scss';
import { getEventResourceTypes } from '@models/resources';

export interface IProps {
    data?: IEventTrigger;
    error?: string;
    workId: number;
    footer?: any;
    onChange?: Function;
    onSubmit: Function;
}

export const validate = (data: IEventTrigger) => {
    return ValidationBuilder
        .create(data)
        .notEmpty('name')
        .notEmpty('eventTriggerTypeId')
        .notEmpty('resourceTypeId')
        .build();
}

export const isValid = (data: IEventTrigger) => {
    return Object.keys(validate(data)).length == 0;
}

export function EventTriggerForm(props: IProps) {
    const { t } = useTranslation();

    const resourceTypes = getEventResourceTypes(t);
    const eventTriggerTypes = getEventTriggerTypes(t);

    const editor = React.useRef<any>();

    const form = useForm<IEventTrigger>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            workId: props.workId,
            code: props.data?.code ?? '',
            resourceTypeId: props.data?.resourceTypeId ?? 1,
            eventTriggerTypeId: props.data?.eventTriggerTypeId ?? 1,
        },
        validate: validate,
        onSubmit: (data: IEventTrigger) => {
            data.code = editor.current.getValue();
            console.log(data);
            props.onSubmit(data);
            return {ok: true};
        },
        onChange: props.onChange,
    });

    React.useEffect(() => {
        (window.require as any)(['vs/editor/editor.main'], function() {
            const monaco = (window as any).monaco;
            if (editor.current == undefined) {
                editor.current = monaco.editor.create(document.getElementById('code-container'), {
                    value: form.values.code,
                    language: 'csharp',
                    theme: "vs-dark",
                });

                window.onresize = function () {
                    editor.current.layout();
                };
            }
        });
    }, []);

    return <div>
        <form onSubmit={form.handleSubmit}
            className='p-fluid'>
            <div className='sm pd r we g-20'>
                {form.input('name', { containerClassName: 'e' })}
                {form.select('eventTriggerTypeId', eventTriggerTypes)}
                {form.select('resourceTypeId', resourceTypes)}
            </div>

            <div id="code-container">
            </div>

            <div className='errors-container'>
                {form.errorBox()}
                {props.error != undefined && <Message severity={'error'} text={props.error} />}
            </div>
            {props.footer != undefined && props.footer}
        </form>
    </div>
}