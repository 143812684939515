import * as React from 'react';

import {
    INotification,
    IJob,
    IWorkspace,
    IActivityType,
    IContractType,
    IWorkShift,
    IDepartment,
    IPropertyGroupType,
    PropertyGroupObjectType,
    IPropertyGroup,
    ISecurity,
    IWork,
    IWorker, departmentIsProductive, JobEncodingType,
    ModuleManager,
} from '@models';
import {
    G,
    NotificationsBox,
    Skeleton,
    useForm, useMemoized, useResolveName,
    useTranslation,
    useWorkFormSettings,
    ILoading,
    ValidationBuilder,
    Select,
    useSearch,
} from '@components';
import { DynamicProperties } from '@components/user/DynamicProperties';

import './WorkForm.css';
import { flatten } from "@utils";

import './JobForm.scss';
import dateUtils from '@utils/date-utils';

type FooterF = (valid: boolean) => React.ReactNode;

export interface IProps {
    data?: IJob;
    work: IWork;
    workId: number;
    activityTypes: IActivityType[];
    contractTypes: IContractType[];
    moduleManager: ModuleManager;
    workspaces: IWorkspace[];
    workShifts: IWorkShift[];
    departments: IDepartment[];
    footer?: FooterF;
    propertyGroupTypes: IPropertyGroupType[];
    notifications: INotification[];
    onChange?: Function;
    onSubmit: Function;
    loading?: ILoading;
    security: ISecurity;
}

interface IValidateJobOpts {
    validateWorkspaces?: boolean;
}

export const validateJob = (data: IJob, validateOptions: IValidateJobOpts = {}, validateCode: boolean = false) => {
    const builder = ValidationBuilder
        .create(data)
        .notEmpty('name', 'Name is required')
        .notEmpty('startDate', 'Date is required')
        .notEmpty('endDate', 'Date is required')
        .notEmpty('description', 'Description is required')
        .notEmptyIf(validateOptions.validateWorkspaces, 'workspaceId', 'Workspace is required');

    if (validateCode) {
        builder.notEmpty('code', 'Code is required');
    }

    return builder.build();
}

export const isValid = (data: IJob) => {
    return Object.keys(validateJob(data)).length == 0;
}

type JobHasDepartment = {
    departmentId: number,
    responsibleId: number,
    isMain: boolean,
}

export function JobForm(props: IProps) {
    const { t } = useTranslation();

    const [availableWorkers, setAvailableWorkers] = React.useState<{ id: number, name: string }[]>([]);
    const [selectedDepartment, setSelectedDepartment] = React.useState<number | undefined>();
    const [selectedResponsible, setSelectedResponsible] = React.useState<number | undefined>();
    const [departments, setDepartments] = React.useState<JobHasDepartment[]>([]);

    const resolveDepartment = useResolveName(props.departments);
    const resolveWorker = useMemoized((workerId: number) => {
        const workers = flatten(props.departments.map(d => d.workers ?? []));
        const w = workers.find(w => w.id == workerId);

        return `${w?.name} ${w?.surname}`;
    });

    const codeIsManual = props.work.settingsObj?.jobEncodingType == JobEncodingType.Manual;

    const formSettings = useWorkFormSettings({ work: props.work, scope: 'job' });

    const form = useForm<IJob>({
        validateOnMount: true,
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            workId: props.workId,
            activityTypeId: props.data?.activityTypeId,
            workspaceId: props.data?.workspaceId,
            startDate: props.data?.startDate!,
            departments: props.data?.departments ?? [],
            properties: props.data?.properties ?? [],
            contractors: [],
            machineries: [],
            isActive: true
        },
        validate: (data: IJob) => {
            return validateJob(data, { validateWorkspaces: props.workspaces.length > 0 }, codeIsManual);
        },
        onSubmit: (data: IJob) => {
            props.onSubmit({
                id: data.id,
                name: data.name,
                code: data.code,
                workspaceId: data.workspaceId,
                workId: data.workId,
                activityTypeId: data.activityTypeId,
                workShiftId: data.workShiftId,
                contractTypeId: data.contractTypeId,
                startDate: data.startDate,
                endDate: data.endDate,
                remarks: data.remarks,
                description: data.description,
                properties: data.properties,
                isActive: data.isActive,
            }, departments);
            return { ok: true };
        },
        onChange: props.onChange,
    });

    const worksSpacesSearch = useSearch<any>({
        workId: props.work.id,
        search: 'jobList/workspaces',
    });

    React.useEffect(() => {
        if (props.security.isWorker() && props.work.id == 23) {
            worksSpacesSearch.doSearch();
        }
    }, []);

    const [workSpaces, setWorkSpaces] = React.useState<{ id: number, name: string }[]>(props.workspaces);

    React.useEffect(() => {
        if (props.security.isWorker() && props.work.id == 23) {
            setWorkSpaces(worksSpacesSearch.value);
        }
    }, [worksSpacesSearch.value]);

    const filterWorkers = props.moduleManager.filterDependency<IWorker>('job.add.mainDepartment.responsibles', {
        security: props.security,
        departments: departments,
    });

    const availableResponsibles = useSearch<any>({
        workId: props.work.id,
        search: 'jobList/oblique.responsibles',
        filters: {
            workId: props.work.id,
        },
    });

    React.useEffect(() => {
        if (selectedDepartment) {
            const dep = props.departments.find(d => d.id == selectedDepartment);
            const workers = (dep?.workers ?? [])
                .filter(w => (w.workspaces?.find(r => r.id == form.values.workspaceId) ?? false)
                    && availableResponsibles.value.find(r => r.id == w.id) != undefined)
                .filter(filterWorkers)
                .map(w => ({ id: w.id, name: `${w.name} ${w.surname}` }));
            workers.sort((a, b) => (a.name).localeCompare(b.name));

            if (departments.length == 0) {
                const workerIds = props.security.user?.workerIds ?? [];
                workers.filter(w => workerIds.includes(w.id));
            }

            setAvailableWorkers(workers);
        }
    }, [selectedDepartment, form.values.workspaceId]);

    const filterDepartment = props.moduleManager.filterDependency('job.departments');

    const [availableDepartments, setAvailableDepartments] = React.useState<IDepartment[]>([]);

    const calculateAvailableDepartments = () => {
        let result: IDepartment[];

        if (departments.length > 0 || !props.security.isWorker()) {
            result = props
                .departments
                // excluimos los departamentos ya seleccionados y los que no sean productivos
                .filter(d =>
                    !departments.map(r => r.departmentId).includes(d.id)
                    && departmentIsProductive(d)
                    && filterDepartment(d));
        } else if (departments.length == 0 && props.security.isWorker() && !props.security.isGestor() && (props.security.user?.obliqueDepartments?.length ?? 0) == 0) {
            result = []
            props.security.user?.departments?.forEach(d => {
                const department = props.departments.find(de => de.id == d);
                if (departmentIsProductive(department!)) {
                    result.push(department!);
                }
            });
        }
        else {
            result = props.departments
                .filter(d =>
                    !departments.map(r => r.departmentId).includes(d.id)
                    && departmentIsProductive(d)
                    && filterDepartment(d));
        }
        result.sort((a, b) => a.name.localeCompare(b.name));
        setAvailableDepartments(result);
    }

    React.useEffect(() => {
        calculateAvailableDepartments();
    }, [props.departments, departments]);

    const removeDepartment = (i: number) => {
        const newDepartments = [...departments];
        newDepartments.splice(i, 1);
        setDepartments(newDepartments);
    }

    const addSelectedDepartment = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        
        const rel = {
            departmentId: selectedDepartment!,
            responsibleId: selectedResponsible!,
            isMain: departments.length == 0,
        };

        setSelectedDepartment(undefined);
        setSelectedResponsible(undefined);

        setDepartments([...departments, rel]);
    }

    const todayOffset = new Date();
    todayOffset.setDate(todayOffset.getDate() - 1);


    const formIsValid = () => {

        if ((!form.values.activityTypeId && form.values.workId == 23)) {
            return false;
        }

        return (dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), todayOffset) > 0
            &&
            (dateUtils.compareDates(dateUtils.parseDate(form.values.endDate), dateUtils.parseDate(form.values.startDate)) >= 0))
            &&
            form.values.name != ''
            &&
            departments.length != 0
            &&
            (props.workspaces.length > 0 ? form.values.workspaceId != undefined : true)
            &&
            form.values.description ? form.values.description.trim() != '' : false;
    }


    return <div className={'JobForm'}>
        <form onSubmit={form.handleSubmit}>
            <div className='c md pd g-30' style={{ marginTop: '20px' }}>
                <div className='r l200 g-30'>
                    <div className='c form-1'>
                        {/**
                         *
                         * Podemos tener claves de internacionalización por obra:
                         * Evitaremos siempre que sea posible incluir lógica en la vista
                         * dependendiente de una obra en concreto (evitar complejidad/errores/mantenimiento)
                         * En este caso, no podríamos traducir directamente
                         * label={t('job.code')}
                         * y tener una clave para la obra 23 : job.code = 'Código AZSA'
                         * en la configuración de la obra ?
                         * Alternativamente, cada modulo que interactúe con el frontend
                         * puede definir claves de internacionalización propias
                         * (Ver ejemplo del módulo de Azsa)
                         *
                         */}
                        <G label={t(`JobCode`)}>
                            {codeIsManual &&
                                form.input('code')}
                            {!codeIsManual && <>
                                {props.data?.code && form.span('code')}
                                {!props.data?.code && <Skeleton />}
                            </>}
                        </G>
                        <G label={t(`jobName`)}>
                            {/**
                             * De igual forma a las claves de la internacionalización,
                             * podemos tener propiedades de componentes por obra,
                             * para evitar lógica dependiente de la obra en la vista general
                             */}
                            {/* {props.workId == 23 ? form.textarea('name', { rows: 2 }) : form.input('name')} */}
                            {form.textarea('name', props.moduleManager.componentProperties('job.name'))}
                        </G>
                        {props.workspaces.length > 0 &&
                            <G label={t('Workspace')}>
                                {form.select('workspaceId', { options: workSpaces })}
                            </G>}
                        {formSettings.show('activityTypeId') &&
                            <G label={t('Activity type')}>
                                <div className='c'>
                                    {form.select('activityTypeId', { options: props.activityTypes })}
                                    {!form.values.activityTypeId && <small className='p-error'>{t('job.error.activityTypeId')}</small>}
                                </div>
                            </G>}
                        {props.work.id === 23 && formSettings.show('description') &&
                            <G label={t('Description')}>
                                {form.textarea('description', { rows: 5 })}
                            </G>}
                        {props.work.id === 23 && < G label={t('Remarks')}>
                            {form.textarea('remarks', { rows: 5 })}
                        </G>}
                        {formSettings.show('properties') &&
                            <div id={'job-embed-properties'}>
                                <DynamicProperties
                                    inline
                                    className={props.workId == 21 ? 'c g-20' : 'c g-20 azsa_dinamic_properties'}
                                    labelClass={'label-rr wx-134'}
                                    object={form.values}
                                    embedPortal={'#job-embed-properties'}
                                    objectType={PropertyGroupObjectType.Job}
                                    propertyGroupTypes={props.propertyGroupTypes.filter(pg => pg.objectType == PropertyGroupObjectType.Job)}
                                    onChange={(v: IPropertyGroup[]) => form.setFieldValue('properties', v)} />
                            </div>}
                    </div>

                    <div className='c form-1'>
                        <G label={t('StartDate')} className={'dateTime'}>
                            <div className='c'>
                                {form.input('startDate', { type: 'date' })}
                                {(dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), todayOffset) < 0) &&
                                    <small className='p-error right-align date-error'>{t('job.error.startDate')}</small>}
                            </div>
                        </G>
                        <G label={t('EndDate')} className={'dateTime'}>
                            <div className='c'>
                                {form.input('endDate', { type: 'date' })}
                                {(dateUtils.compareDates(dateUtils.parseDate(form.values.endDate), dateUtils.parseDate(form.values.startDate)) < 0) &&
                                    <small className='p-error right-align date-error'>{t('job.error.endDate')}</small>}
                            </div>
                        </G>
                        {formSettings.show('workShiftId') &&
                            <G label={t('Work shift')}>
                                {form.select('workShiftId', props.workShifts)}
                            </G>}
                        {formSettings.show('contractTypeId') &&
                            <G label={t('Contract type')}>
                                {form.select('contractTypeId', props.contractTypes)}
                            </G>}
                        {props.work.id !== 23 && formSettings.show('description') &&
                            <G label={t('Description')}>
                                {form.textarea('description')}
                            </G>}
                        {props.work.id !== 23 && < G label={t('Remarks')}>
                            {form.textarea('remarks')}
                        </G>}
                    </div>
                </div>
                <div className={'c box'}>
                    <strong className={'border-bottom'}>{t('job.departments')}</strong>
                    <div className={'c md pd'}>
                        {departments.map((d, i) => <div key={i} className={'r g-20'}>
                            <span>
                                {d.isMain && <i className={'fas fa-circle'} />}
                            </span>
                            <span className={'e'}>{resolveDepartment(d.departmentId)}</span>
                            <span className={'e'}>{resolveWorker(d.responsibleId)}</span>
                            <span>
                                <i className={'fas fa-trash pointer'}
                                    onClick={() => removeDepartment(i)} />
                            </span>
                        </div>)}
                    </div>
                    <div className={'r add-action'}>
                        <Select
                            filter
                            className='e flat-right'
                            placeholder={t('Department')}
                            options={availableDepartments}
                            optionLabel='name'
                            optionValue='id'
                            value={selectedDepartment}
                            onChange={e => { +e.target.value > 0 && setSelectedDepartment(parseInt(e.target.value)) }}>
                        </Select>
                        <Select
                            filter
                            className='e flat'
                            placeholder={t('Responsible')}
                            options={availableWorkers}
                            optionLabel='name'
                            optionValue='id'
                            value={selectedResponsible}
                            onChange={e => +e.target.value > 0 && setSelectedResponsible(parseInt(e.target.value))}>
                        </Select>
                        <button disabled={selectedDepartment == undefined || selectedResponsible == undefined}
                            onClick={addSelectedDepartment}
                            className={'flat-left add-department-btn'}>
                            <i className={'fas fa-plus sm mr-right'} />
                            {t('Add department')}
                        </button>
                    </div>
                    {departments.length === 0 &&
                        <small className='p-error right-align'>{t('One department is required')}</small>
                    }
                </div>
            </div>
            <div className='errors-container'>
                {form.errorBox()}
                <NotificationsBox notifications={props.notifications} />
            </div>
            <div className={'r md pd r-end'}>
                {props.loading?.render()}
            </div>
            {props.footer != undefined &&
                (props.loading == undefined ||
                    !props.loading.isLoading()) &&
                props.footer(form.isValid()) &&
                props.footer(formIsValid()) ||
                !isValid(form.values)}
        </form>
    </div>
}
