import { IContractor, IDepartment, IMachinery, IResourceHasStatus, IWorker } from './resources';
import { IUserIdentity } from './identity';
import { IPropertyGroup } from './works';
import { IRequirement } from './requirements';

import { get as i18n } from '../i18n';

export interface IJobStatus {
    id: number;
    jobId: number;
    jobStatusTypeId: number;
    userId: number;
    dateTime: Date;
    remarks: string;
    isCurrent: boolean;
}

export interface IJob {
    id: number;
    name: string;
    code?: string;
    workId: number;
    description?: string;
    workspaceId?: number;
    activityTypeId?: number;
    workShiftId?: number;
    contractTypeId?: number;
    startDate: Date;
    endDate?: Date;
    remarks?: string;
    preventiveResourceId?: number;
    isActive: boolean;
    informationLoaded?: boolean;

    currentStatus?: IJobStatus;

    departments: IJobHasDepartment[];
    contractors: IJobHasContractor[];
    machineries: IJobHasMachinery[];
    properties?: IPropertyGroup[];

    countDepartmentsTotal?: number;
    countDepartmentsAuthorized?: number;
    countMachineriesTotal?: number;
    countMachineriesAuthorized?: number;
    countWorkersTotal?: number;
    countWorkersAuthorized?: number;
    countContractorsTotal?: number;
    countContractorsAuthorized?: number;
    hierarchyLevel?: number;
}

export interface IJobHasContractor extends IResourceHasStatus {
    id: number;
    contractor: IContractor;
    contractorId: number;
    jobId: number;
    startDate?: Date;
    jobsDescription: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactSurname: string;
    shipmentNumber: string;
    parentId?: number;

    properties?: IPropertyGroup[];
    requirements?: IRequirement[];
    workId?: number;
    parentContractorId?: number;
}

export interface IJobHasDepartment extends IResourceHasStatus {
    id?: number;
    jobId: number;
    departmentId: number;
    responsibleId: number;
    dateTime?: Date;
    isMain?: boolean;

    responsible?: IWorker;
    department?: IDepartment;

    parentId?: number;
}

export interface IJobHasContractorAppUser {
    id?: number;
    jobContractorId: number;
    appUserId: number;
    startDate: Date;
    endDate?: Date;

    appUser?: IUserIdentity;
}

export interface IStatusType {
    name: string;
    isInitial?: boolean;
    isFinal?: boolean;
    styleClassName?: string;
}

export interface IJobHasMachineryStatus {
    statusTypeId: number;
    statusType: IStatusType;
    dateTime: Date;
    description?: string;
    isCurrent?: boolean;
    userId: number;
}

export interface IJobHasMachinery extends IResourceHasStatus {
    id: number;
    jobId: number;
    machineryId?: number;
    jobHasContractorId?: number;
    startDate: Date;
    endDate?: Date;

    properties?: IPropertyGroup[];

    machinery?: IMachinery;

    machineryName?: string;
    machineryCode?: string;
    machineryTypeId?: number;
    machinerySubTypeId?: number;
    machineryPrefixLicenseNumber?: string;
    machineryLicenseNumber?: string;
    machinerySuffixLicenseNumber?: string;

    hasDelayedRevokation?: boolean;

    requirements?: IRequirement[];
}

export interface IJobHasWorker extends IResourceHasStatus {
    id: number;
    jobId: number;
    workerId: number;
    jobHasContractorId?: number;

    fullName?: string;

    startDate?: Date|string;

    hasDelayedRevokation?: boolean;

    job?: IJob;
    worker?: IWorker;
    properties?: IPropertyGroup[];
    requirements?: IRequirement[];
}

function parseJobProperty(jobProperty: IPropertyGroup) {
    if (jobProperty.value && !jobProperty.valueObj) {
        return { ...jobProperty, valueObj: JSON.parse(jobProperty.value) };
    }
    else {
        return jobProperty;
    }
}

export function parseJob(job: IJob) {
    const aJob = { ...job };

    if (aJob.properties) {
        aJob.properties = aJob.properties.map(parseJobProperty);
    }

    return aJob;
}

export enum JobStatusType {
    PENDING = 1,
    INFORMATION_LOADED = 2,
    AUTHORIZED = 3,
    FINALIZED = 4,
    NOT_AUTHORIZED = 5,
}

export const jobStatusTypes = [
    { id: JobStatusType.PENDING, name: i18n('job.status.pending') },
    { id: JobStatusType.INFORMATION_LOADED, name: i18n('job.status.informationLoaded') },
    { id: JobStatusType.AUTHORIZED, name: i18n('job.status.authorized') },
    { id: JobStatusType.FINALIZED, name: i18n('job.status.finalized') },
    { id: JobStatusType.NOT_AUTHORIZED, name: i18n('job.status.notauthorized') },
];

export enum JobLogType {
    ACCESS_JOB_INFORMATION = 1,
}

export interface IJobLog {
    id: number;
    jobId: number;
    userId: number;
    dateTime: Date;
    jobLogType: JobLogType;
}
