import * as React from 'react';

import {
    F, Select, TabPanel, TabView, TogglePanel, useForm, InputSwitch, useLoading, useObjectState, useRemoteData, useTranslation
} from '@components';
import {
    isContractorWorkerPropertyGroupType,
    isRRHHPropertyGroupType,
    isJobHasContractorPropertyGroupType, isMachineryPropertyGroupType, isJobPropertyGroupType,
    IWork, IWorkSettings, JobEncodingType, PropertyGroupObjectType
} from '@models';
import PropertyGroupTypesContainer from '@containers/admin/PropertyGroupTypesContainer';
import { WorkToolbar } from './WorkToolbar';
import { INotificationTemplate, isWorkspacePropertyGroupType } from '@models/works';

type GetNotificationTemplatesF = (workId: number) => Promise<INotificationTemplate[]>;

export interface IProps {
    getNotificationTemplates: GetNotificationTemplatesF;
    getJobPropertyGroups: Function;
    saveJobPropertyGroup: Function;
    saveJobPropertyType: Function;
    saveWork: Function;
    work: IWork;
}

const defaultSettings: IWorkSettings = {}

function ContractorsConfiguration({ saveWork, work }: { saveWork: Function, work: IWork }) {
    const { t } = useTranslation();
    const loading = useLoading();

    const workSettings = work.settingsObj ?? defaultSettings;

    const form = useForm({
        initialValues: {
            id: work.id,
            name: work.name,
            countryId: work.countryId,
            settings: workSettings,
            contextData: work.contextData,
            __acceptSubContractorRequests: workSettings?.acceptSubContractorRequests,
            __mainContractorCanFulfillSubs: workSettings?.mainContractorCanFulfillSubs,
            __rememberSubContractorAcceptances: workSettings?.rememberSubContractorAcceptances,
        }
    });

    const doSave = loading.wrap(async (..._: any) => {
        const w = {
            ...form.values,
            settings: form.get('settings'),
        };
        w.settings['acceptSubContractorRequests'] = form.get('__acceptSubContractorRequests');
        w.settings['mainContractorCanFulfillSubs'] = form.get('__mainContractorCanFulfillSubs');
        w.settings['rememberSubContractorAcceptances'] = form.get('__rememberSubContractorAcceptances');

        w.settings = JSON.stringify(w.settings);
        delete w['__acceptSubContractorRequests'];
        delete w['__mainContractorCanFulfillSubs'];
        delete w['__rememberSubContractorAcceptances'];

        await saveWork(w);
    });

    return <div className='c h100p'>
        <div className={'center g20 w100'}
            style={{ marginTop: '40px', display: 'grid', gridTemplateColumns: '60% auto', gridGap: '20px' }}>
            <label className={'text-right'}>{t('Accept subcontractor requests explicitly')}</label>
            {form.checkBox('__acceptSubContractorRequests')}

            <label className={'text-right'}>{t('Main contractor can fulfill subcontractors')}</label>
            {form.checkBox('__mainContractorCanFulfillSubs')}

            <label className={'text-right'}>{t('Remember subcontractor acceptances')}</label>
            {form.checkBox('__rememberSubcontractorAcceptances')}
        </div>
        <div className='e' />
        <div className='r'>
            <div className='e' />
            {loading.render()}
            <button
                className='primary'
                disabled={loading.isLoading()}
                onClick={doSave}>
                {t('Save')}
            </button>
        </div>
    </div>
}

function JobsConfiguration({ getNotificationTemplates, saveWork, work }: {
    getNotificationTemplates: GetNotificationTemplatesF, saveWork: Function, work: IWork
}) {
    const { t } = useTranslation();
    const loading = useLoading();

    const notificationTemplates = useRemoteData<INotificationTemplate[]>(
        getNotificationTemplates,
        { parameters: [work.id] }
    );

    // const settings = useObjectState<Partial<IWorkSettings>>(work.settingsObj ?? defaultSettings);
    const workSettings = work.settingsObj ?? defaultSettings;
    const workContextData = work.contextDataObj ?? {};

    const encodingOptions = [
        { id: JobEncodingType.Sequential, name: t('Sequential') },
        { id: JobEncodingType.SequentialByWorkspace, name: t('SequentialByWorkspace') },
        { id: JobEncodingType.Manual, name: t('job.encodingType.manual') },
    ];

    const reactivateResourcesBehaviorOptions = [
        { id: 1, name: t('reactivate.resources.behavior.return') },
        { id: 2, name: t('reactivate.resources.behavior.calculate') },
    ]

    const form = useForm({
        initialValues: {
            id: work.id,
            contractorInvitationTemplateId: work.contractorInvitationTemplateId,
            helpDeskEmail: work.helpDeskEmail,
            footerResource: work.footerResource,
            resetPasswordTemplateId: work.resetPasswordTemplateId,
            __jobEncodingType: workSettings.jobEncodingType,
            __jobEncodingPrefix: workSettings.jobEncodingPrefix,
            __allowAlwaysNotifyInformationLoaded: workSettings.allowAlwaysNotifyInformationLoaded,
            settings: workSettings,
            contextData: workContextData,
            __clientImage: workSettings.clientImage,
            __screenImage: workSettings.screenImage,
            __createJobRelations: workSettings.createJobRelations,
            __jobHiddenFields:
                workSettings.jobHiddenFields?.join
                    ? workSettings.jobHiddenFields?.join(',')
                    : '',
            __contractorHiddenFields:
                workSettings.contractorHiddenFields?.join
                    ? workSettings.contractorHiddenFields?.join(',')
                    : '',
            __workerHiddenFields:
                workSettings.workerHiddenFields?.join
                    ? workSettings?.workerHiddenFields?.join(',')
                    : '',
            __machineryHiddenFields:
                workSettings.machineryHiddenFields?.join
                    ? workSettings?.machineryHiddenFields?.join(',')
                    : '',
            __simpleDelayedRevokation: workSettings.simpleDelayedRevokation,
            __jobDelayedRevokation: workSettings.jobDelayedRevokation,
            __hideByDefaultEnded: workSettings.hideByDefaultEnded,
            __jobChainFinalisation: workSettings.jobChainFinalisation,
            __reactivateResourcesBehavior: workSettings.reactivateResourcesBehavior,
            contextDataPhone: workContextData?.phone,
            contextDataEmail: workContextData?.email,
        }
    });

    const doSave = loading.wrap(async (..._: any) => {
        const w = {
            ...form.values,
            settings: form.get('settings'),
            contextData: form.get('contextData'),
        };
        w.settings['jobEncodingType'] = w.__jobEncodingType;
        w.settings['jobEncodingPrefix'] = w.__jobEncodingPrefix;
        w.settings['jobHiddenFields'] = form.get<string>('__jobHiddenFields')?.split(',')?.map(x => x.trim()) ?? [];
        w.settings['contractorHiddenFields'] = form.get<string>('__contractorHiddenFields')?.split(',')?.map(x => x.trim()) ?? [];
        w.settings['workerHiddenFields'] = form.get<string>('__workerHiddenFields')?.split(',')?.map(x => x.trim()) ?? [];
        w.settings['machineryHiddenFields'] = form.get<string>('__machineryHiddenFields')?.split(',')?.map(x => x.trim()) ?? [];
        w.settings['clientImage'] = form.get<string>('__clientImage');
        w.settings['screenImage'] = form.get<string>('__screenImage');
        w.settings['createJobRelations'] = form.get<boolean>('__createJobRelations');
        // w.settling
        w.settings['allowAlwaysNotifyInformationLoaded'] = form.get<boolean>('__allowAlwaysNotifyInformationLoaded');
        w.settings['simpleDelayedRevokation'] = form.get<boolean>('__simpleDelayedRevokation');
        w.settings['jobDelayedRevokation'] = form.get<boolean>('__jobDelayedRevokation');
        w.settings['hideByDefaultEnded'] = form.get<boolean>('__hideByDefaultEnded');
        w.settings['jobChainFinalisation'] = form.get<boolean>('__jobChainFinalisation');
        w.settings['reactivateResourcesBehavior'] = form.get<number>('__reactivateResourcesBehavior');
        w.contextData['phone'] = form.get('__contextDataPhone');
        w.contextData['email'] = form.get('__contextDataEmail');

        w.settings = JSON.stringify(w.settings);
        w.contextData = JSON.stringify(w.contextData);

        await saveWork({
            id: work.id,
            name: work.name,
            countryId: work.countryId,
            settings: w.settings,
            contextData: w.contextData,
            resetPasswordTemplateId: w.resetPasswordTemplateId,
            contractorInvitationTemplateId: w.contractorInvitationTemplateId,
            defaultEmail: form.get('defaultEmail'),
            helpDeskEmail: form.get('helpDeskEmail'),
            footerResource: form.get('footerResource'),
        });
    });

    return <div className='c he'>
        <div className='form-2 g20'>
            <F label={t('Email')} labelClassName='md mr-right'>
                {form.input('defaultEmail', { type: 'email' })}
            </F>
            <F label={t('Encoding type')} labelClassName='md mr-right'>
                <Select
                    placeholder={t('Select job encoding type')}
                    options={encodingOptions}
                    optionLabel='name'
                    optionValue='id'
                    value={form.values.__jobEncodingType}
                    onChange={v => form.setFieldValue('__jobEncodingType', v.target.value)}>
                </Select>
            </F>
            <F label={t('Encoding prefix')} labelClassName='md mr-right'>
                <input
                    type='text'
                    value={form.values.__jobEncodingPrefix ?? ''}
                    onChange={s => form.setFieldValue('__jobEncodingPrefix', s.target.value)} />
            </F>
            <F label={t('job.allowAlwaysNotifyInformationLoaded')} labelClassName={'md mr-right'}>
                <InputSwitch checked={form.values.__allowAlwaysNotifyInformationLoaded}
                    onChange={s => form.setFieldValue('__allowAlwaysNotifyInformationLoaded', s.target.value)} />
            </F>
            <F label={t('job.hidden-fields')} labelClassName={'md mr-right'}>
                <input
                    type='text'
                    value={form.values.__jobHiddenFields ?? ''}
                    onChange={s => form.setFieldValue('__jobHiddenFields', s.target.value)} />
            </F>
            <F label={t('contractor.hidden-fields')} labelClassName={'md mr-right'}>
                <input
                    type='text'
                    value={form.values.__contractorHiddenFields ?? ''}
                    onChange={s => form.setFieldValue('__contractorHiddenFields', s.target.value)} />
            </F>
            <F label={t('worker.hidden-fields')} labelClassName={'md mr-right'}>
                <input
                    type='text'
                    value={form.values.__workerHiddenFields ?? ''}
                    onChange={s => form.setFieldValue('__workerHiddenFields', s.target.value)} />
            </F>
            <F label={t('machinery.hidden-fields')} labelClassName={'md mr-right'}>
                <input
                    type='text'
                    value={form.values.__machineryHiddenFields ?? ''}
                    onChange={s => form.setFieldValue('__machineryHiddenFields', s.target.value)} />
            </F>
            <F label={t('createJobRelations')} labelClassName={'md mr-right'}>
                <InputSwitch checked={form.values.__createJobRelations}
                    onChange={s => form.setFieldValue('__createJobRelations', s.target.value)} />
            </F>
            <F label={t('work.client-image')} labelClassName={'md mr-right'}>
                <input
                    type='text'
                    value={form.values.__clientImage ?? ''}
                    onChange={s => form.setFieldValue('__clientImage', s.target.value)} />
            </F>
            <F label={t('work.screen-image')} labelClassName={'md mr-right'}>
                <input
                    type='text'
                    value={form.values.__screenImage ?? ''}
                    onChange={s => form.setFieldValue('__screenImage', s.target.value)} />
            </F>
            <F label={t('simpleDelayedRevokation')} labelClassName={'md mr-right'}>
                <InputSwitch checked={form.values.__simpleDelayedRevokation}
                    onChange={s => form.setFieldValue('__simpleDelayedRevokation', s.target.value)} />
            </F>
            <F label={t('jobDelayedRevokation')} labelClassName={'md mr-right'}>
                <InputSwitch checked={form.values.__jobDelayedRevokation}
                    onChange={s => form.setFieldValue('__jobDelayedRevokation', s.target.value)} />
            </F>
            <F label={t('HideByDefaultEnded')} labelClassName={'md mr-right'}>
                <InputSwitch checked={form.values.__hideByDefaultEnded}
                    onChange={s => form.setFieldValue('__hideByDefaultEnded', s.target.value)} />
            </F>
            <F label={t('jobChainFinalisation')} labelClassName={'md mr-right'}>
                <InputSwitch checked={form.values.__jobChainFinalisation}
                    onChange={s => form.setFieldValue('__jobChainFinalisation', s.target.value)} />
            </F>
            <F label={t('work.contractor-invitation.template')}>
                {form.select('contractorInvitationTemplateId', {
                    blank: true,
                    options: notificationTemplates.value ?? [],
                })}
            </F>
            <F label={t('work.reset-password.template')}>
                {form.select('resetPasswordTemplateId', {
                    blank: true,
                    options: notificationTemplates.value ?? [],
                })}
            </F>
            <F label={t('work.helpDeskEmail')}>
                {form.input('helpDeskEmail', { type: 'email' })}
            </F>

            <F label={t('Reactivate.resources.behaviour')} labelClassName='md mr-right'>
                <Select
                    // placeholder={t('Select job encoding type')}
                    options={reactivateResourcesBehaviorOptions}
                    optionLabel='name'
                    optionValue='id'
                    value={form.values.__reactivateResourcesBehavior}
                    onChange={v => form.setFieldValue('__reactivateResourcesBehavior', v.target.value)}>
                </Select>
            </F>

            <label></label>
            <TogglePanel title={t('work.footer')}>
                <div className='md pd rounded box blue'>
                    <F label={t('work.footer.path')} labelClassName={'md mr-right'}>
                        {form.input('footerResource', { type: 'text' })}
                    </F>
                    <F label={t('work.contextData.phone')} labelClassName={'md mr-right'}>
                        {form.input('__contextDataPhone', { type: 'text' })}
                    </F>
                    <F label={t('work.contextData.email')} labelClassName={'md mr-right'}>
                        {form.input('__contextDataEmail', { type: 'text' })}
                    </F>
                </div>
            </TogglePanel>
        </div>
        <div className='e' />
        <div className='r sm mr-top'>
            <div className='e' />
            {loading.render()}
            <button
                className='primary'
                disabled={loading.isLoading()}
                onClick={doSave}>
                {t('Save')}
            </button>
        </div>
    </div>
}

export function AdminWorkConfiguration(props: IProps) {
    const { t } = useTranslation();

    return <div className='md pd h100p'>
        <WorkToolbar work={props.work} />

        <TabView className=''>
            <TabPanel header={t('General')} contentClassName='h100p'>
                <JobsConfiguration
                    getNotificationTemplates={props.getNotificationTemplates}
                    saveWork={props.saveWork}
                    work={props.work} />
            </TabPanel>
            <TabPanel header={t('Contractors')} contentClassName='h100p'>
                <ContractorsConfiguration
                    saveWork={props.saveWork}
                    work={props.work} />
            </TabPanel>
            <TabPanel header={t('Job properties')} contentClassName='h100p'>
                <PropertyGroupTypesContainer
                    propertyGroupTypes={props.work.propertyGroupTypes?.filter(isJobPropertyGroupType)}
                    objectType={PropertyGroupObjectType.Job}
                    work={props.work} />
            </TabPanel>
            <TabPanel header={t('Contractor/Job properties')} contentClassName='h100p'>
                <PropertyGroupTypesContainer
                    propertyGroupTypes={props.work.propertyGroupTypes?.filter(isJobHasContractorPropertyGroupType)}
                    objectType={PropertyGroupObjectType.JobHasContractor}
                    work={props.work} />
            </TabPanel>
            <TabPanel header={t('Machinery/Job properties')} contentClassName='h100p'>
                <PropertyGroupTypesContainer
                    propertyGroupTypes={props.work.propertyGroupTypes?.filter(isMachineryPropertyGroupType)}
                    objectType={PropertyGroupObjectType.JobHasMachinery}
                    work={props.work} />
            </TabPanel>
            <TabPanel header={t('Workers properties')} contentClassName='h100p'>
                <PropertyGroupTypesContainer
                    propertyGroupTypes={props.work.propertyGroupTypes?.filter(isContractorWorkerPropertyGroupType)}
                    objectType={PropertyGroupObjectType.JobHasWorker}
                    work={props.work} />
            </TabPanel>
            <TabPanel header={t('RRHH properties')} contentClassName='h100p'>
                <PropertyGroupTypesContainer
                    propertyGroupTypes={props.work.propertyGroupTypes?.filter(isRRHHPropertyGroupType)}
                    objectType={PropertyGroupObjectType.JobHasWorker}
                    isInternal={true}
                    work={props.work} />
            </TabPanel>
            <TabPanel header={t('Workspace properties')} contentClassName='h100p'>
                <PropertyGroupTypesContainer
                    propertyGroupTypes={props.work.propertyGroupTypes?.filter(isWorkspacePropertyGroupType)}
                    objectType={PropertyGroupObjectType.Workspace}
                    work={props.work} />
            </TabPanel>
        </TabView>
    </div>
}
