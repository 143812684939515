import * as React from 'react';
import {
    BreadcrumbItem, ValidationBuilder, useDataTable,
    usePaginator, useRemoteData, useTranslation,
    useMessage, useLoading, useForm, G, SplitButton,
    useSearch,
    Tag,
} from '@components';
import {
    ISecurity,
    IWork,
    ResourceType
} from '@models';
import { IContractor, IWorkerDocumentType, IWorkerType } from '@models/resources';
import { goToJobMachineries, goToJobWorkers, goToSubContractors, subContractorsRoute } from '@components/routes';
import { ResourceStatus } from '@components/common/ResourceStatus';
import { redirectTo } from '@utils';

type GetContractorF = (workId: number) => Promise<IContractor>;

export interface IProps {
    work: IWork;
    getContractorFromAppUser: GetContractorF;
}

export function ManageSubcontractors(props: IProps) {
    const { t } = useTranslation();
    const [contractor, setContractor] = React.useState<IContractor|undefined>();

    const loading = useLoading();

    const getContractor = loading.wrap(async () => {
        const c = await props.getContractorFromAppUser(props.work.id);
        setContractor(c);
    });

    React.useEffect(() => {
        getContractor();
        console.log("!!!! CONTRACTORID: " + contractor?.id);
    }, [props.work]);

    React.useEffect(() => {
        search.doSearch();
    }, [contractor]);

    const search = useSearch({
        workId: props.work.id,
        search: 'subcontractors',
        filters: {
            workId: props.work.id,
            contractorId: contractor?.id,
            RequireContractorId: contractor?.id,
        }
    });

    const renderStatus = (r: any) => {
        return <ResourceStatus
            currentStatusType={r.currentStatusType!}
            resourceId={r.id}
            resourceType={ResourceType.JobHasContractor}
            workId={props.work.id} />;
    };

    const renderRequirements = (r: any) => {
        return <div onClick={() => redirectTo(`/work/${props.work.id}/jobs/${r.jobId}/contractor/${r.id}/requirements`)}>
            <Tag className='pointer'
                value={`${r.validatedCount ?? 0}/${r.totalCount ?? 0}`}/>
        </div>
    }

    const renderMachineries = (r: any) => {
        return <div onClick={() => goToJobMachineries(props.work.id, r.jobId, r.id)}>
            <Tag className='pointer' value={`${r.countMachineriesAuthorized ?? 0}/${r.countContractorMachineries ?? 0}`}/>
        </div>
    }

    const renderWorkers = (r: any) => {
        return <div onClick={() => goToJobWorkers(props.work.id, r.jobId, r.id)}>
            <Tag className='pointer' value={`${r.countWorkersAuthorized ?? 0}/${r.countContractorWorkers ?? 0}`}/>
        </div>
    }

    const dataTable = useDataTable({
        columns: [
            { title: 'Contractor', field: 'name', className: 'center'},
            { title: 'Job', field: 'jobName', className: 'center'},
            { title: 'Start date', field: 'startDate', className: 'center'},
            { title: 'Description', field: 'description', className: 'center'},
            { title: 'Status', render: renderStatus, className: 'center'},
            {
                title: 'Requirements',
                render: renderRequirements,
                className: 'center',
            },
            { title: 'Machineries', render: renderMachineries, className: 'center'},
            { title: 'Workers', render: renderWorkers, className: 'center'}
        ],
        data: search.value
    });

    return <div>
        <BreadcrumbItem
            redirectTo={subContractorsRoute(props.work.id)}
            onClick={() => goToSubContractors}
            text={t('Subcontractors')} />
            {contractor &&
                <div>
                    {console.log(contractor)}
                    {dataTable()}
                </div>
            }
    </div>
}