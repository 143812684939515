import * as React from 'react';

import { F, ValidationBuilder, useForm, useLoading, useMessage, useToast, useTranslation } from '@components';
import { IOption } from '@models';
import {
    IRequirement,
    IRequirementType,
    IRequirementTypeDocumentRestriction,
    RequirementTargetType
} from '@models/requirements';
import { formatFileSize } from '@utils/file-utils';
import { fileSizeLimit } from '../../Constants';

import './UploadRequirements.scss';

type MeetsDependenciesF = (workId: number, requirementId: number, jobId?: number) => Promise<IOption<boolean>>;

export interface IProps {
    fulfillRequirement: Function;
    jobId?: number;
    requestClose: (reload: boolean) => void;
    requirement: IRequirement;
    requirementMeetsDependencies: MeetsDependenciesF;
    requirementType: IRequirementType;
    targetTitle: string;
}

function UploadFreeDocument({ onChange }: { onChange: (f: File) => void }) {
    const { t } = useTranslation();
    const toast = useToast();

    const fileRef = React.useRef<any>();

    // lanzamos el dialogo de seleccionar fichero directamente ?
    // React.useEffect(() => {
    //     if (fileRef.current) {
    //         fileRef.current.click();
    //     }
    // }, []);

    const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((e.target.files?.length ?? 0) == 1) {
            const f = e.target.files![e.target.files!.length - 1];

            if (f.size > fileSizeLimit) {
                toast.error(t('upload.filesize.limit') + ' : ' + formatFileSize(fileSizeLimit));
                return;
            }
            else {
                onChange(f);
            }
        }
        else {
            toast.error(t('files.length.error'));
        }
    }

    return <div className='r g-20 md pd'>
        <i className='pi pi-question-circle' />
        {t('Select document')}
        {toast.render()}
        <input
            type='file'
            onChange={selectFile}
            ref={fileRef} />
    </div>
}

function UploadDocument({
    restriction,
    onChange
}: {
    restriction: IRequirementTypeDocumentRestriction,
    onChange: (f: File) => void,
}) {
    const toast = useToast();
    const { t } = useTranslation();

    const setFromEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((e.target.files?.length ?? 0) > 0) {
            const f = e.target.files![e.target.files!.length - 1];
            let canUpload = true;

            if (f.size > fileSizeLimit) {
                canUpload = false;
                toast.error(t('upload.filesize.limit') + ' : ' + formatFileSize(fileSizeLimit));
                return;
            }

            if (restriction.mimeTypes) {
                const mimeTypes = restriction.mimeTypes.split(',').map(s => s.trim().toLowerCase());
                const regex = new RegExp(`.+\.(${mimeTypes.join('|')})$`);
                canUpload = canUpload && regex.test(f.name.toLowerCase());
            }

            if (restriction.excludeMimeTypes) {
                const mimeTypes = restriction.excludeMimeTypes.split(',').map(s => s.trim().toLowerCase());
                const regex = new RegExp(`.+\.(${mimeTypes.join('|')})$`);
                canUpload = canUpload && !regex.test(f.name);
            }

            if (canUpload) {
                onChange(f);
            }
            else {
                toast.error(t('File type not allowed'));
            }
        }
    }

    return <div className='c g-20 lg mr pd-top'>
        {toast.render()}
        <i className='pi pi-question-circle' />
        <span>{t('Select document')} : <strong>{restriction.title}</strong></span>
        <input type='file'
            onChange={setFromEvent}
            accept={restriction.mimeTypes} />
    </div>
}

function UploadDocumentsWithRestrictions({ documentRestrictions, onChange }: {
    documentRestrictions: IRequirementTypeDocumentRestriction[],
    onChange: (name: string, requirementId: number, file: File, allRequirementsFulfilled: boolean) => void,
}) {
    const [restrictionWithDocuments, setRestrictionWithDocuments] = React.useState<number[]>([]);

    const onChangeDocument = (r: IRequirementTypeDocumentRestriction) => {
        return (e: File) => {
            setRestrictionWithDocuments(s => s.concat([r.id!]));
            const allRequirementsFulfilled =
                documentRestrictions
                    .map(d => d.id!)
                    .filter(id => restrictionWithDocuments.includes(id) || r.id == id)
                    .length == documentRestrictions?.length;

            onChange(e.name, r.id!, e, allRequirementsFulfilled);
        }
    }

    return <div className='c lg mr pd-top'>
        {documentRestrictions.map((r, i) =>
            <UploadDocument
                key={i}
                onChange={onChangeDocument(r)}
                restriction={r} />)}
    </div>
}

type RequirementFile = [string, number | undefined, File];
type IFulfillData = {
    expeditionDate?: Date;
    expirationDate?: Date;
    noFileRemarks?: string;
    remarks?: string;
}

export function UploadRequirement(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();
    const toast = useToast();

    const [canFulfill, setCanFulfill] = React.useState<boolean>(false);
    const documentRestrictions = props.requirementType.documentRestrictions ?? [];
    const hasDocumentRestrictions = documentRestrictions.length > 0;

    const [files, setFiles] = React.useState<RequirementFile[]>([]);
    const [fulfilledRequirements, setFulfilledRequirements] = React.useState<boolean>(false);

    React.useEffect(() => {
        checkDependencies();
    }, []);

    React.useEffect(() => {
        if (files.length > 1) {
            setFiles(f => f.slice(1, 2));
        }
    }, [files]);

    const checkDependencies = async () => {
        const meetsDependencies = await props.requirementMeetsDependencies(
            props.requirement.workId,
            props.requirement.id,
            props.jobId);
        if (!(meetsDependencies.hasValue && meetsDependencies.value)) {
            console.log(meetsDependencies.error);
            console.log(t(meetsDependencies.error ?? ''));
            message.setError(t(meetsDependencies.error ?? 'error'));
        }
        else {
            setCanFulfill(true);
        }
    }

    const expirationRequired = props.requirementType.expirationRequired == true;

    const form = useForm<IFulfillData>({
        initialValues: {},
        validateOnMount: true,
        validate: expirationRequired
            ? ValidationBuilder.new().notEmpty('expirationDate').lift()
            : undefined,
    });

    const hasExpiration = !expirationRequired
        || form.values.expirationDate != null;
    const canUpload = (hasDocumentRestrictions
        ? fulfilledRequirements
        : files.length > 0) && hasExpiration;

    const onFreeDocument = (file: File) => {
        setFiles([[file.name, undefined, file]]);
        setFulfilledRequirements(true);
    }

    const onDocumentWithRestrictions = (
        name: string,
        requirementId: number,
        file: File, allRequirementsFulfilled: boolean) => {
        setFiles(f =>
            f.find(r => r[0] === name)
                ? f.map(r => r[0] === name ? [name, r[1], file] : r)
                : f.concat([[name, requirementId, file]]));
        setFulfilledRequirements(allRequirementsFulfilled);
    }

    const uploadDocument = loading.wrap(async () => {
        const docs = files.map(f => ({
            name: f[2].name,
            resourceId: f[2],
            fileSize: f[2].size,
            requirementTypeDocumentRestrictionId: f[1],
        }));

        const requirement = {
            requirementId: props.requirement.id,
            documents: docs,
            workId: props.requirement.workId
        };

        const res = await props.fulfillRequirement(
            props.requirement.workId,
            props.requirement.id,
            { ...requirement, ...form.values }
        );

        message.set(res);

        if (res.hasValue) {
            props.requestClose(true);
        }
    });

    const requirementFileName = (r: RequirementFile) => {
        if (r.length > 0) {
            const [__, _, f] = r;
            return `${f.name} (${formatFileSize(f.size)})`;
        }
        else {
            return '';
        }
    }

    const setFile = (f: File) => {
        if (!hasDocumentRestrictions) {
            onFreeDocument(f);
        }
        else {
            const requirementId = documentRestrictions[0].id!;
            const mimeTypes = (documentRestrictions[0].mimeTypes?.split(',') ?? []).map(t => t.trim().toLowerCase()).filter(t => t != '');
            const excludeMimeTypes = (documentRestrictions[0].excludeMimeTypes?.split(',') ?? []).map(t => t.trim().toLowerCase()).filter(t => t != '');
            let canUpload = true;

            if (f.size > fileSizeLimit) {
                canUpload = false;
                toast.error(t('upload.filesize.limit') + ' : ' + formatFileSize(fileSizeLimit));
                return;
            }

            if (mimeTypes.length > 0) {
                canUpload = mimeTypes.find(t => f.name.toLowerCase().includes(t)) != undefined;
            }
            if (excludeMimeTypes.length > 0) {
                canUpload = excludeMimeTypes.find(t => f.name.toLowerCase().includes(t)) == undefined;
            }

            if (canUpload) {
                const allRequirements = true;
                onDocumentWithRestrictions(
                    f.name,
                    requirementId,
                    f,
                    allRequirements);
            }
            else {
                toast.error(t('File type not allowed'));
            }
        }
    }

    const onDropDocument = (e: any) => {
        if (e.dataTransfer.items && e.dataTransfer.items.length == 1) {
            if (e.dataTransfer.items.kind === 'file') {
                const file = e.dataTransfer.items.getAsFile();
                setFile(file);
            }
        }
        else {
            toast.error(t('files.length.error'));
        }

        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    const goToTemplate = (e: any) => {
        const url = props.requirementType.template;
        if (url?.startsWith('http') ?? false) {
            window.open(url, '_blank');
        }
    }

    const targetKind = props.requirementType.targetType === RequirementTargetType.Contractor
        ? t('requirements.target.contractor')
        : props.requirementType.targetType === RequirementTargetType.Machinery
            ? t('requirements.target.machinery')
            : props.requirementType.targetType === RequirementTargetType.Worker
                ? t('requirements.target.worker')
                : props.requirementType.targetType === RequirementTargetType.JobHasMachinery
                    ? t('requirements.target.machinery')
                    : props.requirementType.targetType === RequirementTargetType.JobHasWorker
                        ? t('requirements.target.worker')
                        : props.requirementType.targetType === RequirementTargetType.JobHasContractor
                            ? t('requirements.target.contractor')
                            : t('requirements.target');

    if (canFulfill) {
        return <div className={'c UploadRequirements'}>
            {toast.render()}
            <div className='r'>
                <div className={'c md pd req-help'}>
                    <div className={'section c'}>
                        <div className={'c'}>
                            <label>{targetKind}:</label>
                            <span className={'value'}>{props.targetTitle}</span>
                        </div>
                        <div className={'c'}>
                            <label>{t('Document')}</label>
                            <span className={'value'}>{props.requirementType.title}</span>
                        </div>
                        <div className={'c'}>
                            <label>{t('Document description')}</label>
                            <span className={'value'}>{props.requirementType.description}</span>
                        </div>
                    </div>
                    <div className={'section'}>
                        <span className={'title'}>
                            <i className='fas fa-exclamation-triangle' />
                            {t('Criteria')}
                        </span>

                        <div className={'c g-20'}>
                            <label>{t('requirements.criteria')}</label>
                            {props.requirementType.criteria !== null && props.requirementType.criteria !== '' &&
                                <span className={'value'}><div dangerouslySetInnerHTML={{ __html: props.requirementType.criteria! }}></div></span>}
                            {(props.requirementType.criteria === null || props.requirementType.criteria === '') &&
                                <span className={'text-right mutted'}>{t('requirements.criteria.empty')}</span>}
                        </div>
                    </div>
                    <div className={'section'}>
                        <span className={'title'}>
                            <i className='fas fa-cloud-upload-alt' />
                            {t('requirements.template')}
                        </span>

                        <div className={'c g-20'}>
                            {props.requirementType.template !== null && props.requirementType.template !== '' &&
                                <span className={'value'}>
                                    <span className={`${props.requirementType.template?.toLowerCase() != 'no' && 'pointer'}`} onClick={goToTemplate}>
                                        {props.requirementType.template}
                                    </span>
                                </span>}
                            {(props.requirementType.template === null || props.requirementType.template === '') &&
                                <span className={'text-right mutted'}>{t('requirements.template.empty')}</span>}
                        </div>
                    </div>
                    <div className={'section'}>
                        <span className={'title'}>
                            <i className='fas fa-clock' />
                            {t('Tolerance')}
                        </span>
                        <div className={'c g-20'}>
                            <label>{t('requirements.tolerance')}</label>
                            <span className={'value'}>
                                {props.requirementType.expirationThreshold} {t('Days')}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='c sm pd g-20 file-container e'>
                    <div className={'c g-20'}>
                        <div className={'c g-5 e'}>
                            <F label={t('Expiration date')}>
                                {form.calendar('expirationDate')}
                            </F>
                        </div>
                        <div className={'c g-5 e'}>
                        </div>
                    </div>

                    <div className={'c g-5'}>
                        <label>{t('requirements.file.title')}</label>
                        <div className='c file-area'
                            onDragOver={e => e.preventDefault()}
                            onDrop={onDropDocument}>
                            <div className={'clarifications c g-10'}>
                                <span className={'mutted'}>
                                    {t('requirements.file.upload-area-overwrite')}
                                </span>
                            </div>

                            {!hasDocumentRestrictions &&
                                <UploadFreeDocument
                                    onChange={onFreeDocument} />}
                            {hasDocumentRestrictions &&
                                <UploadDocumentsWithRestrictions
                                    documentRestrictions={documentRestrictions}
                                    onChange={onDocumentWithRestrictions} />}

                            {files.length > 0 &&
                                <div className={'selected-files'}>
                                    {files.map((f, i) =>
                                        <div key={i}>
                                            {requirementFileName(f)}
                                        </div>)}
                                </div>}
                        </div>
                    </div>
                    {loading.renderBox()}
                </div>
            </div>
            {form.errorBox()}
            <div className='r r-end footer'>
                <button onClick={() => props.requestClose(false)}>{t('Cancel')}</button>
                <button
                    className='primary'
                    disabled={!canUpload || loading.isLoading()}
                    onClick={() => uploadDocument()}>
                    <span className='pi pi-upload sm pd-right' />
                    {t('Upload documentation')}
                </button>
            </div>
        </div>;
    }
    else {
        return <div className={'md pd'}>
            {message.render()}
        </div>;
    }
}
