import {
    ApolloClient,
    InMemoryCache,
    gql,
} from "@apollo/client";

import DateUtils from '@utils/date-utils';

let _client: ApolloClient<any> | null = null;
let _lastUsedName: string | null = null;

const getClient = (name : string) => {

    // if (_client == null) {
    //     _client = new ApolloClient({
    //         uri: `/graphql?name=${name}`,
    //         cache: new InMemoryCache()
    //     });
    // }

    const uri = `/graphql?name=${name}`;

    if (_client == null || _lastUsedName !== name) {
        _client = new ApolloClient({
            uri: uri,
            cache: new InMemoryCache()
        });
        _lastUsedName = name;
    }

    return _client;
}

export interface ISimpleQueryOpts<T> {
    returnPath?: string;
    map?: (a: T) => any,
    mapEvery?: (a: T) => any,
}

async function q<T>(query: string, variables: any, opts: ISimpleQueryOpts<T> = {}) {
    const name = query.split(' ')[1].split('(')[0];

    if(name.length == 0){
        console.log('query', query);
        console.log('* name', name)
        console.log('+ 0', query.split(' ')[0])
        console.log('+ 1', query.split(' ')[1])
    }
    const client = getClient(name);

    const resp = await client.query({
        query: gql(query),
        variables,
        fetchPolicy: noCache,
    });

    if (opts.returnPath) {
        const parts = opts.returnPath.split('.');
        const res = parts.reduce((acc, p) => {
            return acc[p];
        }, resp.data);

        if (opts.map) {
            return opts.map(res);
        }
        else if (opts.mapEvery && res && res.length) {
            return res.map(opts.mapEvery);
        }
        else {
            return res;
        }
    }
    else {
        return resp;
    }
}

const parseVariables = (data: any) => {
    const res: any = {};

    if (data == null) {
        return data;
    }
    else if (typeof(data) != 'object') {
        return data;
    }

    for (const k of Object.keys(data)) {
        if (data[k] === null) {
            res[k] = null;
        }
        else if (typeof(data[k]) === 'object' && data[k].getTimezoneOffset) {
            res[k] = DateUtils.fromUtc(data[k]);
        }
        else if (typeof(data[k]) === 'object' && data[k].concat) {
            res[k] = data[k].map(parseVariables);
        }
        else if (typeof(data[k]) === 'object') {
            res[k] = parseVariables(data[k]);
        }
        else {
            res[k] = data[k];
        }
    }

    return res;
}

async function m<T>(query: string, variables: any, opts: ISimpleQueryOpts<T> = {}) {

    const name = query.split(' ')[1].split('(')[0];
    const client = getClient(name);
    const parsedVariables = parseVariables(variables);

    const resp = await client.mutate({
        mutation: gql(query),
        variables: parsedVariables,
    });

    if (opts.returnPath) {
        const parts = opts.returnPath.split('.');
        const res = parts.reduce((acc, p) => {
            return acc[p];
        }, resp.data);

        return res;
    }
    else {
        return resp;
    }
}

const noCache = 'no-cache';

export {
    gql,
    getClient,
    noCache,
    m,
    q,
}
