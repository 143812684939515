import * as React from 'react';

import {
    F, Tag, Tooltip,
    useTranslation, useLoading, useDataTable, useFormDialog, useSearch,
} from '@components';
import {
    IActivityType, IConstants, IJob, ILegalForm, IPropertyGroupType, ISecurity, IWork,
    PropertyGroupObjectType
} from '@models';
import * as DateUtils from '@utils/date-utils';
import { BreadcrumbItem } from '../custom/BreadcrumbItem';
import { DynamicProperties } from '../user/DynamicProperties';
import { ProcessContractorRequest } from './ProcessContractorRequest';
import { isWorker } from 'cluster';

export interface IProps {
    activityTypes: IActivityType[];
    constants: IConstants;
    loadJob: Function;
    getJobContractorRequests: Function;
    hasPermission: Function;
    legalForms: ILegalForm[];
    onProcessSuccess: Function;
    processContractorRequest: Function;
    propertyGroupTypes: IPropertyGroupType[];
    job: IJob;
    work: IWork;
    security: ISecurity;
}

type IContractorRequest = any;

const ContractorRequestData = ({ contractor, propertyGroupTypes }: {
    contractor: IContractorRequest,
    propertyGroupTypes: IPropertyGroupType[],
}) => {
    const { t } = useTranslation();

    return <div className='r'>
        <div className='form-2 sm pd e'>
            <F label={t('Contractor social id')}>
                <span className='value'>{contractor.contractorName}</span>
            </F>
            <F label={t('Code')}>
                <span className='value'>{contractor.contractorCode}</span>
            </F>
            <F label={t('Contact name')}>
                <span className='value'>{contractor.contactName}</span>
            </F>
            <F label={t('Contact surname')}>
                <span className='value'>{contractor.contactSurname}</span>
            </F>
            <F label={t('Phone')}>
                <span className='value'>{contractor.contactPhone}</span>
            </F>
            <F label={t('Email')}>
                <span className='value'>{contractor.contactEmail}</span>
            </F>
            <F label={t('Entry date')}>
                <span className='value'>{DateUtils.format(contractor.startDate)}</span>
            </F>
            <F label={t('Jobs into this work')}>
                <span className='value'>{contractor.description ?? contractor.jobsDescription}</span>
            </F>
            <F label={t('Address')}>
                <span className='value'>{contractor.address}</span>
            </F>
            <F label={t('Postal code')}>
                <span className='value'>{contractor.postalCode}</span>
            </F>
        </div>

        <DynamicProperties
            objectType={PropertyGroupObjectType.JobHasContractorRequest}
            object={contractor}
            propertyGroupTypes={propertyGroupTypes}
            readonly
            className='sm pd'
            style={{ width: '40%' }}
        />
    </div>
}

export function JobContractorRequests(props: IProps) {
    const loading = useLoading(true);
    const { t } = useTranslation();
    const [requests, setRequests] = React.useState<IContractorRequest[]>([]);
    const [requestToProcess, setRequestToProcess] = React.useState<IContractorRequest | undefined>();
    const [invitations, setInvitations] = React.useState<any[]>([]);
    const isContractor = props.security.isContractor();
    const dialog = useFormDialog({
        editTitle: t('Contractor data'),
        className: 'md'
    });

    const refresh = async () => {
        await initialize();
        await props.loadJob(props.work.id, props.job.id);
        props.onProcessSuccess();
    }

    const invitationSearch = useSearch({
        workId: props.work.id,
        search: isContractor ? 'jobs/subcontractor.invitations.users' : 'jobs/subcontractor.invitations.users.workers',
        filters: {
            jobId: props.job.id,
            workId: props.work.id,
        }
    });

    const initialize = loading.wrap(async () => {
        const data = await props.getJobContractorRequests(props.work.id, props.job.id);
        const invisSearch = await invitationSearch.doSearch()
        const invitations: any[] = [];
        invisSearch.forEach((item: any) => {
            invitations.push({
                startDate: item.startDate,
                source: item.source,
                contractorName: item.contractorName,
                jobsDescription: item.description,
                accepted: item.accepted,
                contactEmail: item.email,
                description: item.jobsDescription,
            })
        });
        data.forEach((element: any) => {
            element.source = element.contactName
        });
        setInvitations(invitations);
        setRequests(data);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    React.useEffect(() => {
        setRequests(requests.concat(invitations));
    }, [invitations]);

    const canProcessRequests = props.hasPermission('jobs.contractors.processrequests');

    const dataTable = useDataTable({
        columns: [
            { title: 'Date', field: 'startDate', delegate: 'date', className: 'center' },
            { title: t('job.contractor-request.source'), field: 'source', className: 'center' },
            { title: t('job.contractor-request.target'), field: 'contractorName' },
            {
                title: t('Job'),
                render: c => {
                    return <span>
                        <strong>{c.jobCode}</strong>
                        <span>{c.jobsDescription}</span>
                    </span>
                },
            },
            props.work.id == 21 ?
            {
                title: 'Status', className: 'center', render: (d: IContractorRequest) =>
                    d.accepted
                        ? <Tag value={t('Accepted')} />
                        : d.discarded
                            ? <div id={`state_${d.id}`}>
                                <Tag value={t('Rejected')} className='danger' />
                                <Tooltip target={`#state_${d.id}`}>
                                    <span>{d.resultMessage}</span>
                                </Tooltip>
                            </div>
                            : d.discarded != null && d.jobId == null ? <Tag className='gr' value={t('Pending')} /> :  <Tag className='gr' value={t('Pending') + ' (' + t('Invitation') + ')'} />
            } : undefined,
        ],
        actions: [
            { icon: 'info', onClick: dialog.showEdit },
            canProcessRequests ? { icon: 'play', onClick: setRequestToProcess, disabled: (d: IContractorRequest) => d.accepted || d.discarded } : undefined
        ],
        data: requests,
    });

    return <div>
        <BreadcrumbItem
            text={t('Contractor requests')} />

        {requestToProcess &&
            <ProcessContractorRequest
                activityTypes={props.activityTypes}
                constants={props.constants}
                legalForms={props.legalForms}
                createContractorRequest={props.processContractorRequest}
                data={requestToProcess}
                onCancel={() => setRequestToProcess(undefined)}
                onSuccess={() => refresh()}
                propertyGroupTypes={props.propertyGroupTypes}
                job={props.job}
                work={props.work} />}

        {!requestToProcess && <>
            {loading.renderBox()}
            {dataTable()}
            {dialog.render(
                (c: IContractorRequest) =>
                    <ContractorRequestData
                        contractor={c}
                        propertyGroupTypes={props.propertyGroupTypes} />)}
        </>}
    </div>
}