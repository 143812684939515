import * as React from 'react';

import {
    Portal,
    classNames, R, SplitButton, TabPanel, TabView, Tag, Tooltip, useDialogs, useRemoteData,
    useTranslation, Panel, useSignalR, useConfirm, useLoading, usePermissions,
} from '@components';
import UploadRequirement from '@containers/requirements/UploadRequirementContainer';
import ValidateRequirement from '@containers/requirements/ValidateRequirementContainer';
import AddRequirementIncidenceContainer from '@containers/requirements/AddRequirementIncidenceContainer';
import AddRequirementNoteContainer from '@containers/requirements/AddRequirementNoteContainer';
import { ViewRequirementHistory } from './ViewRequirementHistory';
import ValidateAllRequirements from '@containers/requirements/ValidateAllRequirementsContainer';
import RemoteHtml from '@components/custom/RemoteHtml';
import {
    IRequirement, IRequirementGroup, IRequirementHasDocument,
    IRequirementStatusType, IRequirementType,
    IRequirementIncidence,
    requirementCanBeValidated,
    requirementCanUpload,
    IRequirementNote,
    FreeRequirementType,
    RequirementTypeKind,
    RequirementStatusTypes,
    userCanValidateRequirement,
    IRequirementNotification, RequirementTargetType, RequirementTypeDependencyType,
    RequirementValidationType,
    IRequirementStatus,
} from '@models/requirements';
import { IContractor, ResourceType } from '@models/resources';
import * as C from '../../Constants';
import { flatten, formatFileSize } from '@utils';
import DateUtils from '@utils/date-utils';

import './EditRequirements.scss';
import ViewRequirementIncidencesContainer from '@containers/requirements/ViewRequirementIncidencesContainer';
import ViewRequirementNotesContainer from '@containers/requirements/ViewRequirementNotesContainer';
import { DocumentViewer, hasViewer } from '@components/viewers/DocumentViewer';
import { RequirementDocumentInfo } from './RequirementDocumentInfo';
import { IJob, IUserIdentity, ISecurity, IOption, IWork, ModuleManager } from '@models';
import ResourcePhotoContainer from '@containers/resources/ResourcePhotoContainer';
import { MenuItem } from 'primereact/menuitem';
import { ViewRequirementValidations } from './ViewRequirementValidations';
import dateUtils from '@utils/date-utils';

type PendingRequirementsCallback = (hasPendingRequirements: boolean) => void;

export interface IProps {
    getRequirements: (workId: number, targetType: number, targetId: number) => Promise<IRequirement[]>;
    getRequirement: (workId: number, targetType: number, targetId: number, requirementId: number) => Promise<IRequirement>;
    removeRequirementFile: (requirement: IRequirement) => Promise<IOption<boolean>>;
    removeFreeRequirement: (requirement: IRequirement) => Promise<IOption<boolean>>;
    hasAccessJobInformation?: boolean;
    jobId?: number;
    job?: IJob;
    onHasPendingRequirements?: PendingRequirementsCallback;
    readOnly?: boolean;
    requirementGroups: IRequirementGroup[];
    requirementStatusTypes: IRequirementStatusType[];
    requirementIncidence: IRequirementIncidence;
    requirementNote: IRequirementNote;
    security: ISecurity;
    siblingId?: number;
    siblingType?: number;
    siblingContractorId?: number;
    siblingJobHasContractorId?: number;
    targetId: number;
    targetTitle: string;
    targetType: number;
    work: IWork;
    workId: number;
    appUsers: IUserIdentity[];
    index: number;
    getContractorFromAppUser: Function;
    getJobHasWorker: Function;
    getJobHasMachinery: Function;
    getJobHasContractor: Function;
    getContractor: Function;
    getWorker: Function;
    getMachinery: Function;
    moduleManager: ModuleManager;
    skipMirrorDependencies?: boolean;
    ctx?: any;
}

enum RequirementMode {
    FreeRequirement,
    DirectRequirement,
    RequirementWithMirrorDependency,
    RequirementWithValidationDependency,
    IBODependency,
}

type RequirementModeData = {
    mode: RequirementMode,
    meetsRequirements?: boolean,
}

function ViewRequirementDocument({ document, onClose, requirement, restrictionTitle }: {
    document: IRequirementHasDocument,
    onClose: Function,
    requirement: IRequirement,
    restrictionTitle?: string,
}) {
    var sortedVersions = document.document.versions!.sort((a, b) => dateUtils.compareDates(dateUtils.fromUtc(b.dateTime), dateUtils.fromUtc(a.dateTime)));

    const lastVersion = sortedVersions[0];
    // const lastVersion = document.document;

    const { t } = useTranslation();
    const dialogs = useDialogs();
    const documentUrl = `/api/files/${requirement.workId}/document/${document.document.id}/version/${lastVersion.id}`;

    const openViewer = () => {
        window.open(`/work/${requirement.workId}/requirements/${requirement.id}/documents/${document.id}`);
    }

    return <div className='c ViewRequirementDocumentContainer'>
        <div className='r we'>
            <div className='c md pd we'>
                {restrictionTitle && <strong>{restrictionTitle}</strong>}
                <div className='form-1 l200'>
                    <R label={t('Document name')}>
                        <span className='value'>
                            {lastVersion.name}
                        </span>
                    </R>
                    <R label={t('Document type')}>
                        <span className='value'>
                            {lastVersion.mimeType}
                        </span>
                    </R>
                    <R label={t('Document size')}>
                        <span className='value'>
                            {formatFileSize(lastVersion.size)}
                        </span>
                    </R>
                    <R label={t('Upload date')}>
                        <span className='value'>
                            {DateUtils.formatFromUtc(lastVersion.dateTime)}
                        </span>
                    </R>
                    <R label={t('Upload by')}>
                        <span className='value'>
                            {document.createdBy}
                        </span>
                    </R>
                </div>
            </div>
            <div className='c we'>
                <RequirementDocumentInfo
                    document={document}
                    requirement={requirement}
                    lastDocumentVersion={lastVersion} />
            </div>
        </div>

        {dialogs.render('viewer', { maximized: true, title: t('View document') }, () =>
            <DocumentViewer
                mimeType={lastVersion.mimeType}
                url={documentUrl} />
        )}

        <div className='footer r r-end'>
            <button onClick={() => onClose()}>{t('Close')}</button>
            {hasViewer(document.document.mimeType) &&
                <button className='primary' onClick={openViewer}>
                    <i className='pi pi-image sm mr-right' />
                    {t('View')}
                </button>}
            <a className='button primary' href={documentUrl}>
                <i className='pi pi-download sm mr-right' />
                {t('Download')}
            </a>
        </div>
    </div>
}

function RequirementDocument({ document, index, requirement, requirementType }: {
    document: IRequirementHasDocument,
    index: number,
    requirement: IRequirement,
    requirementType: IRequirementType
}) {
    const dialogs = useDialogs();
    const { t } = useTranslation();
    const restriction = requirementType.documentRestrictions?.find(r => r.id == document.documentRestrictionId);
    const restrictionTitle = restriction?.title;

    const tooltip = restrictionTitle
        ? `${restrictionTitle}: ${document.document.name}`
        : document.document.name;

    return <span
        title={tooltip}
        className={classNames('cell', `cell${index}`)}
        onClick={dialogs.showFromEvent('show-document')}>
        <i className='pi pi-file' />

        {dialogs.render('show-document', { title: t('Show document') }, () =>
            <ViewRequirementDocument
                document={document}
                requirement={requirement}
                restrictionTitle={restrictionTitle}
                onClose={() => dialogs.clear()} />)}
    </span>
}

function RequirementDocumentsRow({ requirement, requirementType }: {
    requirement: IRequirement,
    requirementType: IRequirementType
}) {
    return <span className='documents-cell'>
        {requirement.documents?.sort((a, b) => b.id - a.id).slice(-1).map((r, i) =>
            <RequirementDocument
                key={i}
                document={r}
                index={i}
                requirement={requirement}
                requirementType={requirementType} />)}
    </span>;
}

function RequirementRow({
    ctx,
    hasAccessJobInformation,
    jobId,
    job,
    index,
    onRequestReload, readOnly, requirement, requirementType, requirementGroup, requirementStatusTypes,
    requirementPair,
    removeFreeRequirement,
    removeRequirementFile,
    targetType, security, targetId, targetTitle, appUsers, work, getContractorFromAppUser,
    getJobHasWorker, getJobHasMachinery, getJobHasContractor, getContractor, getWorker, getMachinery, moduleManager,
    meetsRequirements,
}: {
    ctx: any,
    index: number,
    hasAccessJobInformation?: boolean,
    jobId?: number,
    job?: IJob,
    onRequestReload: Function,
    readOnly?: boolean,
    removeFreeRequirement?: Function,
    removeRequirementFile: Function,
    requirement: IRequirement,
    requirementType: IRequirementType,
    requirementGroup: IRequirementGroup,
    requirementStatusTypes: IRequirementStatusType[],
    requirementIncidence: IRequirementIncidence,
    requirementPair?: RequirementPair[],
    security: ISecurity,
    targetTitle: string,
    targetType: number,
    targetId: number,
    appUsers: IUserIdentity[],
    work: IWork,
    getContractorFromAppUser: Function,
    getJobHasWorker: Function,
    getJobHasMachinery: Function,
    getJobHasContractor: Function,
    getContractor: Function,
    getWorker: Function,
    getMachinery: Function,
    moduleManager: ModuleManager,
    meetsRequirements: boolean,
}) {
    const { t } = useTranslation();

    const loading = useLoading();
    const removeFile = loading.wrap(async () => {
        await removeRequirementFile(requirement);
        onRequestReload();
    });
    const performRemoveFreeRequirement = loading.wrap(async () => {
        await removeFreeRequirement?.(requirement);
        onRequestReload();
    });

    const dialogs = useDialogs();
    const actionsBtn = React.useRef<any>({});
    const [selfContractor, setSelfContractor] = React.useState<IContractor | undefined>(undefined);
    const [resourceContractorId, setResourceContractorId] = React.useState<number | undefined>(undefined);
    const confirmRemove = useConfirm({
        message: t('Are you sure to delete the file ?'),
        accept: removeFile,
        target: () => actionsBtn.current.container,
    });
    const confirmRemoveFreeRequirement = useConfirm({
        message: t('requirements.remove-free-requirement.confirm'),
        accept: performRemoveFreeRequirement,
        target: () => actionsBtn.current.container,
    });

    if (work.id != 21) {
        const initialize = async () => {
            const contractor = await getContractorFromAppUser(work.id);
            setSelfContractor(contractor);
            let resource = undefined;
            if (requirementType.targetType == RequirementTargetType.JobHasMachinery || (jobId != undefined && requirementType.targetType == RequirementTargetType.Machinery)) {
                resource = await getJobHasMachinery(work.id, jobId, targetId);
                setResourceContractorId(resource.machinery.contractorId);
            }
            else if (requirementType.targetType == RequirementTargetType.JobHasWorker || (jobId != undefined && requirementType.targetType == RequirementTargetType.Worker)) {
                resource = await getJobHasWorker(work.id, jobId, targetId);
                setResourceContractorId(resource.worker.contractorId);
            }
            else if (requirementType.targetType == RequirementTargetType.JobHasContractor || (jobId != undefined && requirementType.targetType == RequirementTargetType.Contractor)) {
                resource = await getJobHasContractor(work.id, jobId, targetId);
                setResourceContractorId(resource.contractorId);
            }
            else if (requirementType.targetType == RequirementTargetType.Worker) {
                resource = await getWorker(work.id, targetId);
                setResourceContractorId(resource.contractorId);
            }
            else if (requirementType.targetType == RequirementTargetType.Machinery) {
                resource = await getMachinery(work.id, targetId);
                setResourceContractorId(resource.contractorId);
            }
            else if (requirementType.targetType == RequirementTargetType.Contractor) {
                resource = await getContractor(work.id, targetId);
                setResourceContractorId(resource.id);
            }
        };

        React.useEffect(() => {
            initialize();
        }, []);
    }

    const currentStatus = requirement.status.find(s => s.isCurrent);
    const currentStatusType = currentStatus?.statusTypeId;
    const statusType = requirementStatusTypes.find(s => s.id == currentStatus?.statusTypeId);

    // Si la validacion es ita y esta validado no dejamos cargar documentos
    // #249: Transportistas
    const preCanUpload = work.id == 19 ? (!work.settingsObj?.mainContractorCanFulfillSubs ? selfContractor ? resourceContractorId == selfContractor?.id : !readOnly && (security.isGestor() || (security.hasPermission('requirements.upload')) ||
        (!readOnly && requirementCanUpload(requirement, targetType, security, {
            hasAccessJobInformation,
            requirementType,
        })))
        && !(requirementType.validationTypeId == RequirementValidationType.ITA
            && targetType != RequirementTargetType.JobHasContractor
            && targetType != RequirementTargetType.Contractor
            && currentStatus?.statusTypeId == RequirementStatusTypes.VALIDATED) : true) : !readOnly && (security.isGestor() || (security.hasPermission('requirements.upload')) ||
                (!readOnly && requirementCanUpload(requirement, targetType, security, {
                    hasAccessJobInformation,
                    requirementType,
                })));

    const haveIncidences =
        (currentStatus?.statusTypeId == 4 && currentStatus?.remarks && currentStatus?.remarks != '')
        || requirement.hasIncidences;

    const haveNotes = () : boolean => {

        if(currentStatus && (!currentStatus.notes || currentStatus.notes?.length == 0)){
            return false;
        }

        if(currentStatus?.statusTypeId != 3){
            return requirement.hasNotes ?? false;
        }else{
            return (currentStatus.notes && currentStatus.notes?.length > 0) ?? false;
        }
    }

    const mainDepartment = job?.departments?.find(d => d.isMain);
    const canValidateContext = {
        job,
        mainDepartment,
    };

    const isJobRequirement = (requirement: IRequirement) =>
        targetType == RequirementTargetType.JobHasDepartment
        || targetType == RequirementTargetType.Job
        || targetType == RequirementTargetType.JobHasContractor
        || targetType == RequirementTargetType.JobHasWorker
        || targetType == RequirementTargetType.JobHasMachinery;

    const permissionCtx = {
        job: job,
        security: security,
        requirement: requirement,
        requirementType: requirementType,
        canUpload: preCanUpload,
        resourceContractorId: resourceContractorId,
        ...ctx,
    };

    // validaciones multiples
    const [_showValidations, setShowValidations] = React.useState<boolean>(false);
    const showValidations = () => {
        // solo mostramos los validadores si el tipo de validacion = REQUIRE_ALL
        const allHolders = requirementType
            .validationHolders?.filter(h => h.optional != true)?.length ?? 0;
        const optionalHolders = requirementType.validationHolders?.filter(h => h.optional == true).map(r => r.id);
        const currentOptionalValidations = requirement.validations.filter(r => optionalHolders?.includes(r.validationHolderId) && r.validated && r.isCurrent);
        if (requirementType.validationTypeId == RequirementValidationType.REQUIRE_ALL) {
            setShowValidations(allHolders > 1 || currentOptionalValidations.length >= 1);
        }
    }
    const hideValidations = () => setShowValidations(false);

    const editRequirementsProps = {
        security,
        moduleManager,
        work,
    };

    const canReportIncidencesDefaultPermission = (requirementCanBeValidated(requirement)
        && userCanValidateRequirement(requirementType, security, canValidateContext)
    ) || security.isGestor();

    const canRemoveFreeRequirementDefaultPermission = !readOnly && (security.isGestor()
        || security.hasPermission('requirements.remove-free-requirement')
        || (security.isWorker() && requirement.status.length == 1
            && requirement.status[0].statusTypeId == RequirementStatusTypes.PENDING)
    ) && !security.isContractor();

    const canUploadDefaultPermission = isJobRequirement(requirement)
        ? 'job.canUploadRequirements'
        : 'canUploadRequirements';

    const canRemoveDefaultPermission = canUploadDefaultPermission && !security.isContractor();

    const canAddObservationsDefaultPermission = isJobRequirement(requirement)
        ? 'job.requirements.can-add-observations'
        : 'requirements.can-add-observations';

    const canValidateDefaultPermission = (requirementCanBeValidated(requirement)
        && userCanValidateRequirement(requirementType, security, canValidateContext)) || security.isGestor();

    const perms = usePermissions(
        editRequirementsProps, {
        ctx: permissionCtx,
        debug: false,
        dependencies: resourceContractorId
    }, {
        canReportIncidences: {
            name: isJobRequirement(requirement)
                ? 'job.requirements.create-incidence'
                : 'requirements.create-incidence',
            default: canReportIncidencesDefaultPermission,
        },
        canRemove: {
            name: isJobRequirement(requirement)
                ? 'job.requirements.can-remove'
                : 'requirements.can-remove',
            default: canRemoveDefaultPermission,
        },
        canRemoveFreeRequirement: {
            name: isJobRequirement(requirement)
                ? 'job.requirements.can-remove-free-requirement'
                : 'requirements.can-remove-free-requirement',
            default: canRemoveFreeRequirementDefaultPermission,
        },
        canUpload: {
            name: isJobRequirement(requirement)
                ? 'job.canUploadRequirements'
                : 'canUploadRequirements',
            default: canUploadDefaultPermission,
        },
        canAddObservations: {
            name: 'requirements.can-add-observations',
            default: canAddObservationsDefaultPermission,
        },
        canDownload: {
            name: 'requirement.download',
            default: true,
        },
        canValidate: {
            name: 'requirements.validate',
            default: canValidateDefaultPermission,
        },
    });

    const canUpload = perms.get('canUpload');
    const canRemoveFreeRequirement = perms.get('canRemoveFreeRequirement');
    const canRemove = perms.get('canRemove');
    const canAddObservations = perms.get('canAddObservations');
    const canReportIncidences = perms.get('canReportIncidences');
    const canDownload = perms.get('canDownload');
    const canValidate = perms.get('canValidate');

    // posibles acciones a ejecutar sobre un requerimiento
    let actions: MenuItem[] = [
        {
            label: t('View history'),
            icon: 'pi pi-clock',
            command: dialogs.showFromEvent('show-history'),
        },
        security.isGestorOrWorker() && canReportIncidences
            ? {
                label: t('Report incidences'),
                icon: 'pi pi-exclamation-triangle',
                command: dialogs.showFromEvent('add-incidence'),
            }
            : undefined,
        (security.isGestor() || (security.isWorker() && canAddObservations && security.hasPermission('requirements.note')))
            ? {
                label: t('Add observations'),
                icon: 'pi pi-comments',
                command: dialogs.showFromEvent('add-note'),
            }
            : undefined,
        { separator: true },
        security.isGestorOrWorker() && canValidate
            ? {
                label: t('Validate'),
                icon: 'pi pi-check-square',
                command: dialogs.showFromEvent('show-validate'),
                disabled: !canValidate
            }
            : undefined,
        (requirement.documents && requirement.documents.length > 0 && canRemove)
            ? {
                label: t('Remove file'),
                icon: 'pi pi-trash',
                command: confirmRemove,
            }
            : undefined,
        (requirement.requirementTypeId == FreeRequirementType && canRemoveFreeRequirement)
            ? {
                label: t('Remove requirement'),
                icon: 'pi pi-trash',
                command: confirmRemoveFreeRequirement
            }
            : undefined,
    ].filter(a => a != undefined)
        .map(a => a!)
        .filter(s => s.disabled != true);

    const onRequestClose = (reload: boolean = false) => {
        if (reload) {
            onRequestReload();
        }

        dialogs.clear();
    }

    const calculateActions = () => {
        if (job != undefined) {
            const actionsContext = {
                identity: security,
                job: job,
                actions: actions,
                requirementType: requirementType,
            };

            const filterActions = moduleManager.filterDependency('requirement.implied.rso.actions', actionsContext);

            return actions.filter(filterActions);
        } else {
            return actions;
        }
    }

    const onValidate = (reload: boolean = false) => {
        dialogs.clear();
        if (reload) {
            onRequestReload();
        }
    }

    const isOnDate = (requirement.expirationDate != null
        && DateUtils.dateIsLessOrEqual(
            DateUtils.parseDate(requirement.expirationDate),
            new Date()));


    const isExpired = (requirement.status.find(s => s.isCurrent)?.statusTypeId == RequirementStatusTypes.NOT_VALIDATED
                        || requirement.status.find(s => s.isCurrent)?.statusTypeId == RequirementStatusTypes.NOT_LOADED)
        && requirement.isExpired;

    const onNoteAdded = () => {
        dialogs.clear();
        onRequestReload();
    }

    const isResourcePicture = requirementType?.isResourcePicture;

    const uploadRequirementsTitle = requirementType.targetType === RequirementTargetType.Contractor
        ? t('requirements.upload.contractor')
        : requirementType.targetType === RequirementTargetType.Worker
            ? t('requirements.upload.worker')
            : requirementType.targetType === RequirementTargetType.Machinery
                ? t('requirements.upload.machinery')
                : requirementType.targetType === RequirementTargetType.Job
                    ? t('requirements.upload.job')
                    : requirementType.targetType === RequirementTargetType.JobHasContractor
                        ? t('requirements.upload.contractor')
                        : requirementType.targetType === RequirementTargetType.JobHasWorker
                            ? t('requirements.upload.worker')
                            : requirementType.targetType === RequirementTargetType.JobHasMachinery
                                ? t('requirements.upload.machinery')
                                : t('Upload documents');

    if (!isResourcePicture) {
        return <div className={classNames('requirement-row r g-10 vc', index % 2 == 0 ? 'odd' : 'even')}>
            {(requirementType.kindId === RequirementTypeKind.Mandatory
                || requirement.mandatory)
                && <i className='pi pi-circle-on' title={t('Mandatory requirement')} />}

            {(requirementType.kindId === RequirementTypeKind.Optional
                && !requirement.mandatory)
                && <i className='pi pi-circle-off' title={t('Optional requirement')} />}

            {C.isDebug && <span className='small mutted'>{requirement.id}</span>}

            {requirementType.description &&
                <i className={`pi pi-info-circle requirement_tooltip-${requirement.id} `} />}

            <strong className='e' style={{ color: requirement.color ?? requirementType.color ?? requirementGroup.color }}>
                {requirementType.title}
            </strong>
            <Tooltip target={`.requirement_tooltip-${requirement.id}`} content={requirementType.description} />

            {loading.render()}

            {requirement.expirationDate &&
                (currentStatusType == RequirementStatusTypes.PENDING) &&
                <span className={'expiration-date'}
                    title={t('requirements.expiration-date')}>
                    <i className='fas fa-clock' />
                    {DateUtils.formatFromUtc(requirement.expirationDate)}
                </span>}

            {requirement.expirationDate &&
                (currentStatusType == RequirementStatusTypes.VALIDATED) &&
                <span className={classNames('expiration-date', { 'expired': isOnDate })}
                    title={t('requirements.expiration-date')}>
                    <i className='fas fa-clock' />
                    {DateUtils.formatFromUtc(requirement.expirationDate)}
                </span>}

            {requirement.documents
                && requirement.documents.length > 0
                && requirement.documents[0].document.hasActiveVersion
                && canDownload
                && <RequirementDocumentsRow
                    requirement={requirement}
                    requirementType={requirementType} />}

            {haveIncidences && <span
                title={t('View incidences')}
                onClick={dialogs.showFromEvent('view-incidences')}>
                <i className='pi pi-exclamation-triangle pointer' />
            </span>}

            {haveNotes() && <span
                title={t('View notes')}
                onClick={dialogs.showFromEvent('view-notes')}>
                <i className='pi pi-comments pointer' />
            </span>}

            {canUpload && meetsRequirements && <span
                title={t('Upload requirement')}
                onClick={dialogs.showFromEvent('upload-documents')}>
                <i className='pi pi-upload pointer' />
            </span>}

            {dialogs.render('view-incidences', { title: t('View incidences'), className: 'no-padding' }, () =>
                <ViewRequirementIncidencesContainer
                    requirement={requirement}
                    targetType={targetType}
                    targetId={targetId}
                    onClose={dialogs.clear} />
            )}

            {dialogs.render('view-notes', { title: t('View notes'), className: 'g pd' }, () =>
                <ViewRequirementNotesContainer
                    requirement={requirement}
                    targetType={targetType}
                    targetId={targetId} />
            )}

            {isExpired && <Tag className='danger'>
                <i className='pi pi-calendar-times' />
                <span className='sm pd' title={t('Expiration date')}>{requirement.expirationDate}</span>
            </Tag>}


            {dialogs.render('add-incidence', { title: t('Report incidence'), className: 'g', style: { minWidth: '50vw' } }, () =>
                <AddRequirementIncidenceContainer
                    onClose={dialogs.clear}
                    onSuccess={onNoteAdded}
                    requirement={requirement}
                />)}

            {dialogs.render('add-note', { title: t('Add note'), className: 'g', style: { minWidth: '50vw' } }, () =>
                <AddRequirementNoteContainer
                    onClose={dialogs.clear}
                    onSuccess={onNoteAdded}
                    requirement={requirement}
                    status={currentStatus}
                />)}

            <div className={'r vc'} style={{ position: 'relative' }}>
                {_showValidations &&
                    <ViewRequirementValidations
                        job={job}
                        workId={requirement.workId}
                        requirement={requirement} />}

                {requirement.inProgress &&
                    <span
                        onMouseEnter={showValidations}
                        onMouseLeave={hideValidations}>
                        <Tag className='warning'
                            value={t('requirement.validation.inprogress')} />
                    </span>}

                {!requirement.inProgress &&
                    <span onMouseEnter={showValidations} onMouseLeave={hideValidations}>
                        <Tag className={isExpired ? 'status-type-expired flat-right tag-width' : `status-type-${statusType?.id} flat-right tag-width`}
                            value={isExpired
                                ? t('requirement.status-type.expired')
                                : t('requirement.status-type.' + statusType?.id)} /></span>}

                <SplitButton
                    ref={actionsBtn}
                    menuClassName='sm gray'
                    className={isExpired
                        ? 'actions-btn flat-left status-actions-btn st-expired'
                        : !requirement.inProgress
                            ? ('actions-btn flat-left status-actions-btn st-' + statusType?.id)
                            : ('actions-btn flat-left status-actions-btn warning st-warning')}
                    model={calculateActions()} />
            </div>

            {dialogs.render('upload-documents', { title: uploadRequirementsTitle }, () =>
                <UploadRequirement
                    jobId={jobId}
                    requirement={requirement}
                    requirementType={requirementType}
                    targetTitle={targetTitle}
                    requestClose={onRequestClose} />)}

            {dialogs.render('show-history', { title: t('View history'), className: 'g historic' }, () =>
                <ViewRequirementHistory
                    canDownload={canDownload}
                    requirement={requirement}
                    requirementStatusTypes={requirementStatusTypes}
                    appUsers={appUsers}
                    requestClose={dialogs.clear}
                    work={work} />)}

            {dialogs.render('show-validate', { title: t('Validate requirement'), className: 'g pd' }, () =>
                <ValidateRequirement
                    requestClose={onValidate}
                    requirement={requirement}
                    showInfo={true}
                    requirementType={requirementType}
                />)}
        </div>
    }
    else {
        return null;
    }
}

function RequirementGroup({
    ctx,
    hasAccessJobInformation,
    jobId,
    job,
    group, onRequestReload, readOnly,
    removeRequirementFile,
    requirements, requirementStatusTypes,
    security, requirementIncidence, targetType, targetId, targetTitle, appUsers, work,
    getContractorFromAppUser, getJobHasMachinery, getJobHasWorker, getJobHasContractor, getContractor, getWorker, getMachinery,
    moduleManager,
    skipMirrorDependencies,
}: {
    ctx?: any,
    hasAccessJobInformation?: boolean,
    jobId?: number,
    job?: IJob,
    group: IRequirementGroup,
    onRequestReload: Function,
    readOnly?: boolean,
    removeRequirementFile: Function,
    requirements: RequirementPair[],
    requirementStatusTypes: IRequirementStatusType[],
    requirementIncidence: IRequirementIncidence,
    security: ISecurity,
    targetTitle: string,
    targetType: number,
    targetId: number,
    appUsers: IUserIdentity[],
    work: IWork,
    getContractorFromAppUser: Function,
    getJobHasMachinery: Function,
    getJobHasWorker: Function,
    getJobHasContractor: Function,
    getContractor: Function,
    getWorker: Function,
    getMachinery: Function,
    moduleManager: ModuleManager,
    skipMirrorDependencies?: boolean,
}) {
    return <div className='c g-5'>
        {requirements
            .filter(([r, rt, mode]) => !requirementHasDependency(r)
                || mode.mode === RequirementMode.RequirementWithValidationDependency
                || skipMirrorDependencies)
            .sort(([a, at], [b, bt]) => (at.orderValue ?? 1000) - (bt.orderValue ?? 1000))
            .map(([r, rt, mode], key) =>
                <RequirementRow
                    ctx={ctx}
                    key={key + '_' + r.id + '_' + job?.id}
                    index={key}
                    hasAccessJobInformation={hasAccessJobInformation}
                    jobId={jobId}
                    job={job}
                    readOnly={readOnly}
                    security={security}
                    meetsRequirements={
                        mode.mode === RequirementMode.RequirementWithValidationDependency
                            ? (mode.meetsRequirements ?? false)
                            : true
                    }
                    onRequestReload={onRequestReload}
                    removeRequirementFile={removeRequirementFile}
                    requirement={r}
                    requirementGroup={group}
                    requirementStatusTypes={requirementStatusTypes}
                    requirementIncidence={requirementIncidence}
                    requirementType={rt}
                    targetTitle={targetTitle}
                    targetType={targetType}
                    targetId={targetId}
                    appUsers={appUsers}
                    getContractorFromAppUser={getContractorFromAppUser}
                    getJobHasMachinery={getJobHasMachinery}
                    getJobHasWorker={getJobHasWorker}
                    getJobHasContractor={getJobHasContractor}
                    getContractor={getContractor}
                    getWorker={getWorker}
                    getMachinery={getMachinery}
                    work={work}
                    requirementPair={requirements}
                    moduleManager={moduleManager} />)}
    </div>;
}

type RequirementPair = [IRequirement, IRequirementType, RequirementModeData];

type RequirementGroups = {
    [requirementGroupId: number]: RequirementPair[];
}

const getRequirementDependency = (r: any) => {
    if (r.requirementType) {
        const deps: any[] = r.requirementType.dependencies ?? [];
        return deps
            .find(d => d.dependencyType === RequirementTypeDependencyType.RequirementMustHaveBeenValidated);
    }
    else {
        return false;
    }
}

const requirementHasDependency = (r: any) => {
    if (r.requirementType) {
        const deps: any[] = r.requirementType.dependencies ?? [];

        return deps
            .find(d => d.dependencyType === RequirementTypeDependencyType.RequirementMustHaveBeenValidated
                && d.propagate) != undefined;
    }
    else {
        return false;
    }
}

const isMirrorRequirement = (r: IRequirement) => {
    const dependencies = r.requirementType?.dependencies ?? [];
    return dependencies
        .filter(d => d.propagate ?? false).length > 0;
}

const generalResources = [
    ResourceType.Contractor,
    ResourceType.Machinery,
    ResourceType.Worker,
];

let _prevActiveIndex = 0;

function associateRequirementTypes(groups: IRequirementGroup[]) {
    const allRequirementTypes = flatten(groups.map(g => g.requirementTypes ?? []));
    return (requirements: IRequirement[]) => {
        return requirements.map(r => {
            return {
                ...r,
                requirementType: allRequirementTypes.find(t => t.id === r.requirementTypeId),
            };
        });
    };
}

function EditRequirementsImpl(props: IProps) {
    const { t } = useTranslation();
    const [groups, setGroups] = React.useState<IRequirementGroup[]>([]);
    const requirements = useRemoteData<IRequirement[]>(props.getRequirements, {
        parameters: [props.workId, props.targetType, props.targetId],
        disabled: props.targetId == undefined,
        map: associateRequirementTypes(props.requirementGroups),
    });

    const [activeIndex, _setActiveIndex] = React.useState<number>(0);

    const setActiveIndex = (i: number) => {
        _prevActiveIndex = i;
        _setActiveIndex(i);
    }

    const siblingRequirements = useRemoteData<IRequirement[]>(props.getRequirements, {
        parameters: [props.workId, props.siblingType, props.siblingId],
        disabled: props.siblingId == undefined,
        map: associateRequirementTypes(props.requirementGroups),
    });
    const parentSiblingRequirements = useRemoteData<IRequirement[]>(props.getRequirements, {
        parameters: [props.workId, RequirementTargetType.JobHasContractor, props.siblingJobHasContractorId],
        disabled: props.siblingJobHasContractorId == undefined,
        map: associateRequirementTypes(props.requirementGroups),
    });

    const [requirementsGroups, setRequirementsGroups] = React.useState<RequirementGroups>({});
    const [freeRequirements, setFreeRequirements] = React.useState<IRequirement[]>([]);
    const [allRequirementTypes, setAllRequirementTypes] = React.useState<IRequirementType[]>([]);
    const dialogs = useDialogs();

    const _ = useSignalR<IRequirementNotification>({
        method: 'RequirementNotification',
        onMessage: r => {
            const shouldReload = r.workId === props.workId
                && (r.resourceId === props.targetId);
            // || (generalResources.indexOf(r.resourceType) >= 0));
            if (shouldReload) {
                reload();
            }
        }
    });

    const reload = async () => {
        if (props.targetId) {
            await requirements.query();
        }
        if (props.siblingId) {
            await siblingRequirements.query();
        }
        if (props.siblingJobHasContractorId) {
            await parentSiblingRequirements.query();
        }
    };

    React.useEffect(() => {
        setGroups(props
            .requirementGroups
            .sort((a, b) => (a.orderValue ?? 0) - (b.orderValue ?? 0)));
        setAllRequirementTypes(flatten(props
            .requirementGroups
            .map(g => g.requirementTypes ?? [])));
    }, [props.requirementGroups]);

    const loadSiblingRequirements = (res: RequirementGroups) => {
        if (!requirements.value || !siblingRequirements.value || props.skipMirrorDependencies) {
            return;
        }
        const mirrorDependencies =
            requirements
                .value
                .filter(r => requirementHasDependency(r));

        const siblings = [...siblingRequirements.value ?? []];
        const parentSiblings = [...parentSiblingRequirements.value ?? []];

        for (const r of mirrorDependencies) {
            const requirementType = allRequirementTypes.find(t => t.id === r.requirementTypeId);
            if (requirementType) {
                const groupId = requirementType.requirementGroupId;
                const dep = getRequirementDependency(r);
                if (dep === undefined) {
                    continue;
                }

                const sourceReq = siblings
                    .find(s => s.requirementTypeId == dep.dependsOnRequirementId);

                if (!res[groupId]) {
                    res[groupId] = [];
                }

                if (sourceReq && res[groupId]) {
                    const sourceRequirementTypeOriginal = allRequirementTypes.find(t => t.id === sourceReq.requirementTypeId)!;
                    const sourceRequirementType = { ...sourceRequirementTypeOriginal };
                    sourceRequirementType.kindId = requirementType.kindId;
                    res[groupId].push([
                        sourceReq,
                        sourceRequirementType,
                        {
                            mode: RequirementMode.RequirementWithMirrorDependency,
                        }
                    ]);
                }
            }
        }

        const reqsWithDependencies = requirements
            .value
            .filter(r => (r?.requirementType?.dependencies?.length ?? 0) > 0 && isMirrorRequirement(r));

        for (const r of reqsWithDependencies) {
            const requirementType = allRequirementTypes
                .find(t => t.id === r.requirementTypeId);
            if (requirementType) {
                const groupId = requirementType.requirementGroupId;
                const dep = requirementType!.dependencies![0];
                if (dep === undefined) {
                    continue;
                }

                const sourceReq = parentSiblings
                    .find(s => s.requirementTypeId == dep.dependsOnRequirementId);
                if (!res[groupId]) {
                    res[groupId] = [];
                }

                if (dep.dependencyType == RequirementTypeDependencyType.IBO) {
                    res[groupId].push([
                        r,
                        requirementType,
                        {
                            mode: RequirementMode.IBODependency,
                        }]);
                }
                else if (sourceReq
                    && res[groupId]
                    && dep.dependencyType == RequirementTypeDependencyType.RequirementMustHaveBeenValidated) {
                    const currentStatus = sourceReq.status.find(rs => rs.isCurrent);
                    const isValidated = currentStatus?.statusTypeId == RequirementStatusTypes.VALIDATED;

                    res[groupId].push([
                        r,
                        requirementType,
                        {
                            mode: RequirementMode.RequirementWithValidationDependency,
                            meetsRequirements: isValidated,
                        }]);
                }
            }
        }
        setRequirementsGroups(res);
    }

    React.useEffect(() => {
        const res: RequirementGroups = {};
        let freeR: IRequirement[] = [];

        if (requirements.value) {
            freeR = requirements
                .value
                .filter(r => r.requirementTypeId == FreeRequirementType);
            setFreeRequirements(freeR);

            const directRequirements = requirements
                .value
                .filter(r => !isMirrorRequirement(r) || props.skipMirrorDependencies);

            for (const r of directRequirements) {
                const requirementType = allRequirementTypes.find(t => t.id === r.requirementTypeId);
                const isFree = r.requirementTypeId == FreeRequirementType;

                if (requirementType) {
                    const groupId = requirementType.requirementGroupId;
                    const mode = isFree ? RequirementMode.FreeRequirement : RequirementMode.DirectRequirement;
                    if (!res[groupId]) {
                        res[groupId] = [[r, requirementType, {
                            mode
                        }]];
                    }
                    else {
                        res[groupId].push([
                            r,
                            requirementType,
                            {
                                mode,
                            }]);
                    }
                }
            }
        }

        setRequirementsGroups(res);
        loadSiblingRequirements(res);

        // tenemos los requerimientos cargados
        // si tenemos un callback para notificar los requerimientos pendientes lo llamamos
        if (props.onHasPendingRequirements) {
            const allRequirements: IRequirement[] =
                flatten(Object.values(res).map(g => g[0] as IRequirement[])).concat(freeR);
            const hasPendingRequirement = allRequirements.find(requirementIsPending);
            props.onHasPendingRequirements(hasPendingRequirement != undefined);
        }
    }, [
        requirements.value, props.requirementGroups, allRequirementTypes,
        siblingRequirements.value, parentSiblingRequirements.value,
    ]);

    React.useEffect(() => {
        // tenemos los requerimientos cargados
        // si tenemos un callback para notificar los requerimientos pendientes lo llamamos
        if (props.onHasPendingRequirements) {
            const allRequirements: IRequirement[] = flatten(
                Object.values(requirementsGroups)
            ).map(g => g[0] as IRequirement).concat(freeRequirements);
            const hasPendingRequirement = anyPending(allRequirements ?? []);
            props.onHasPendingRequirements(hasPendingRequirement);
        }
    }, [freeRequirements]);

    const requirementIsPending = (r: IRequirement) => {
        const currentStatus = r.status?.find(s => s.isCurrent);

        return currentStatus?.statusTypeId == RequirementStatusTypes.PENDING;
    }

    const anyPending = (allRequirements: IRequirement[]) => {
        const statuses = flatten(allRequirements.map(r => r.status ?? []));

        if (statuses == undefined || statuses.length == 0) {
            return false;
        }

        const anyPending = statuses.some(s => s.isCurrent && s.statusTypeId == RequirementStatusTypes.PENDING);

        return anyPending;
    }

    const canValidateDocuments = () => {
        return props.security.isGestorOrWorker() &&
            (requirements.value ?? []).find(requirementIsPending) != undefined;
    }

    const canValidateAllDocuments = () => {
        return props.security.isGestor() &&
            (requirements.value ?? []).find(requirementIsPending) != undefined;
    }

    const freeRequirementGroup = {
        id: FreeRequirementType,
        isActive: true,
        name: '',
        title: '',
        workId: props.workId,
    };

    const freeRequirementType = {
        id: FreeRequirementType,
        name: '',
        title: '',
        requirementGroupId: FreeRequirementType,
        workId: props.workId,
        targetType: props.targetType,
        kindId: RequirementTypeKind.Optional,
        isActive: true,
    };

    const freeComponents = [];

    if (freeRequirements.length > 0) {
        freeComponents.push(<TabPanel header={t('requirements.free.group')}>
            {freeRequirements.map((r, index) =>
                <RequirementRow
                    key={r.id + '_' + props.job?.id}
                    ctx={props.ctx}
                    index={index}
                    meetsRequirements={true}
                    hasAccessJobInformation={props.hasAccessJobInformation}
                    security={props.security}
                    onRequestReload={reload}
                    readOnly={props.readOnly}
                    removeRequirementFile={props.removeRequirementFile}
                    removeFreeRequirement={props.removeFreeRequirement}
                    requirement={r}
                    requirementStatusTypes={props.requirementStatusTypes}
                    requirementIncidence={props.requirementIncidence}
                    requirementType={{ ...freeRequirementType, title: r.title }}
                    requirementGroup={freeRequirementGroup}
                    targetTitle={props.targetTitle}
                    targetType={props.targetType}
                    targetId={props.targetId}
                    appUsers={props.appUsers}
                    getContractorFromAppUser={props.getContractorFromAppUser}
                    getJobHasMachinery={props.getJobHasMachinery}
                    getJobHasWorker={props.getJobHasWorker}
                    jobId={props.jobId}
                    job={props.job}
                    getJobHasContractor={props.getJobHasContractor}
                    getContractor={props.getContractor}
                    getWorker={props.getWorker}
                    getMachinery={props.getMachinery}
                    work={props.work}
                    moduleManager={props.moduleManager} />)}
            <div className='center legend'>
                <RemoteHtml silent resource={'Requirements/' + props.workId + '/Legend.html'} useI18n />
            </div>
        </TabPanel>);
    }

    React.useEffect(() => {
        renderPhoto();
    }, [requirements.value != undefined ? requirements.value.find(r => r.requirementType?.isResourcePicture)?.status : null]);

    const isEmpty = groups
        .find(g => requirementsGroups[g.id!]?.length > 0) == undefined && freeRequirements.length <= 0;

    const renderPhoto = () => {
        if (requirements.value != undefined) {
            {
                return requirements.value.find(r => r.requirementType?.isResourcePicture) != undefined && <Portal container='.actions'>
                    <ResourcePhotoContainer
                        resourceId={props.targetId}
                        work={props.work}
                        targetTitle={props.targetTitle}
                        targetType={props.targetType}
                        resourcePictureRequirement={requirements.value.find(r => r.requirementType?.isResourcePicture)} />
                </Portal>
            }
        }
    }

    const legend = (currentGroupTab: IRequirementGroup) => {
        if (currentGroupTab == undefined) {
            return null;
        }

        if (currentGroupTab.legend != '' && currentGroupTab.legend != undefined) {
            return <div className='center legend'>
                <div style={{ height: "2.7em", marginTop: "5px" }}>
                    <span style={{ textAlign: "left" }}>{t('legend.header')}</span>
                    <div className='view' dangerouslySetInnerHTML={{ __html: currentGroupTab.legend }}></div>
                </div>
            </div>
        }
        let url = '';

        if (props.workId == 21) {
            url = 'Requirements/' + props.workId + '/Legend.html'
        }

        if (url != '') {
            return <div className='center legend'>
                <div style={{ height: "2.7em", marginTop: "5px" }}>
                    <RemoteHtml silent resource={url} useI18n={props.workId == 21} />
                </div>
            </div>
        }
    }

    if (isEmpty) {
        return null;
    }
    else {
        const currentGroups = groups.filter(g => requirementsGroups[g.id!]);
        return <div className='e he we EditRequirements' id='EditRequirements'>
            {requirements.renderLoading()}

            {dialogs.render('validate-all', { maximized: true, title: t('Validate') }, () =>
                <ValidateAllRequirements
                    requestCancel={dialogs.clear}
                    requirements={requirements.value} />
            )}

            {canValidateAllDocuments() &&
                <Portal container='.actions'>
                    <Panel header={t('Actions')}>
                        <button className='plain rounded validate-button' onClick={dialogs.showFromEvent('validate-all')}>
                            <i className='pi pi-check-square sm mr-right' />
                            {t('Validate all requirements')}
                        </button>
                    </Panel>
                </Portal>}

            {renderPhoto()}

            <TabView
                key={`tabs_${props.targetType}_${props.targetId}`}
                className='he we'
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}>
                {currentGroups.map((g) => {
                    const reqs = requirementsGroups[g.id!] ?? [];

                    if (reqs.length > 0) {
                        return <TabPanel header={t(g.title)} key={g.id}>
                            <RequirementGroup
                                ctx={props.ctx}
                                group={g}
                                skipMirrorDependencies={props.skipMirrorDependencies}
                                hasAccessJobInformation={props.hasAccessJobInformation}
                                jobId={props.jobId}
                                job={props.job}
                                onRequestReload={reload}
                                readOnly={props.readOnly}
                                removeRequirementFile={props.removeRequirementFile}
                                requirementStatusTypes={props.requirementStatusTypes}
                                requirementIncidence={props.requirementIncidence}
                                requirements={requirementsGroups[g.id!] ?? []}
                                security={props.security}
                                targetTitle={props.targetTitle}
                                targetType={props.targetType}
                                targetId={props.targetId}
                                appUsers={props.appUsers}
                                getContractorFromAppUser={props.getContractorFromAppUser}
                                getJobHasMachinery={props.getJobHasMachinery}
                                getJobHasWorker={props.getJobHasWorker}
                                getJobHasContractor={props.getJobHasContractor}
                                getContractor={props.getContractor}
                                getWorker={props.getWorker}
                                getMachinery={props.getMachinery}
                                work={props.work}
                                moduleManager={props.moduleManager} />
                            {legend(currentGroups[activeIndex])}
                        </TabPanel>;
                    }
                    else {
                        return null;
                    }
                }).filter(r => r != null)}

                {freeComponents}
            </TabView>
        </div>
    }
}

export const EditRequirements = React.memo(EditRequirementsImpl, (prev, next) => {
    return prev.work.id === next.work.id
        && prev.targetType == next.targetType
        && prev.targetId == next.targetId
        && prev.ctx == next.ctx;
});
