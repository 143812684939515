import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { JobNotifyInformationLoaded } from '@components/jobs/JobNotifyInformationLoaded';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
    }),
    JobsStore.actionCreators,
)(JobNotifyInformationLoaded as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
