import { Action, Reducer } from 'redux';
import { ApplicationState } from '.';
import {
    IUserIdentity, IRole, IWork, parseWork, IPropertyGroupType
} from '../models';
import * as App from './app';
import * as AdminActions from './actions/admin';
import * as UserStore from './user';
import { actionCreators as N } from './notifications';
import t from '../i18n';
import { IUserData } from '../models/identity';
import { IModuleInfo } from '@models/works';

export interface IAdminState {
    users: IUserIdentity[],
    roles: IRole[],
    works: IWork[],
    availableModules: IModuleInfo[],
}

const defaultState = {
    users: [], roles: [], works: [], availableModules: [],
}

// types
const initializeType    = '[ADMIN] INITIALIZE';
const onInitializeType  = '[ADMIN] ON INITIALIZE';
const onGetUsersType    = '[ADMIN] ON GET USERS';

// actions
export interface InitializeAction {
    type: '[ADMIN] INITIALIZE'
}

export interface OnInitializeAction {
    type: '[ADMIN] ON INITIALIZE',
    data: Partial<IAdminState>,
}

export interface OnGetUsersAction {
    type: '[ADMIN] ON GET USERS',
    data: Partial<IAdminState>,
}

export type KnownAction = InitializeAction | OnInitializeAction | OnGetUsersAction | {type: undefined};

const onInitialize = (data: Partial<IAdminState>) => {
    return {
        type: onInitializeType,
        data
    };
}

const onGetUsers = (data: Partial<IAdminState>) => {
    return {
        type: onGetUsersType,
        data
    };
}

const loadWorks = async (dispatch: Function) => {
    dispatch(N.startLoading({
        ctx: 'adminworks',
    }));
    const resp = await AdminActions.getWorks();

    const data = {
        ...resp.data,
        works: resp.data.works.map(parseWork),
    };

    dispatch(onInitialize(data));
    dispatch(N.stopLoading({
        ctx: 'adminworks',
    }));
}

const loadUsers = async (dispatch: Function) => {
    dispatch(N.startLoading({
        ctx: 'adminusers',
    }));
    const resp = await AdminActions.getUsers();

    dispatch(onGetUsers(resp.data));
    dispatch(N.stopLoading({
        ctx: 'adminusers',
    }));
}

const getGraphqlErrorFromOption = (res: any, op: string|undefined = undefined) => {
    if (res.errors && res.errors.length > 0) {
        return JSON.stringify(res.errors[0]);
    }
    else if (op) {
        return res.data[op].error;
    }
}

export const plainActions = {
    getEventTriggers: AdminActions.getEventTriggers,
    saveEventTrigger: AdminActions.saveEventTrigger,
    associateAppUserToWorker: AdminActions.associateAppUserToWorker,
    getNotificationTemplates: AdminActions.getNotificationTemplates,
    removeNotificationTemplate: AdminActions.removeNotificationTemplate,
    saveNotificationTemplate: AdminActions.saveNotificationTemplate,
    getNotificationMessages: AdminActions.getNotificationMessages,
    removeNotificationMessage: AdminActions.removeNotificationMessage,
    saveNotificationMessage: AdminActions.saveNotificationMessage,
    getWorkResources: AdminActions.getWorkResources,
    getWorkDepartments: AdminActions.getWorkDepartments,
    saveDepartment: AdminActions.saveDepartment,
    getJobPropertyGroups: AdminActions.getJobPropertyGroups,
    loadWorkPermissions: AdminActions.loadWorkPermissions,
    saveWorkPermission: AdminActions.saveWorkPermission,
    removeWorkPermission: AdminActions.removeWorkPermission,
    getPropertyGroupTypes: AdminActions.getPropertyGroupTypes,
    removePropertyType: AdminActions.removePropertyType,
    removePropertyGroupType: AdminActions.removePropertyGroupType,
    savePropertyType: AdminActions.savePropertyType,
    saveWorkRequirementType: AdminActions.saveWorkRequirementType,
    saveWorkRequirementGroup: AdminActions.saveWorkRequirementGroup,
    getRequirementType: AdminActions.getRequirementType,
    getWorkRequirementGroups: AdminActions.getWorkRequirementGroups,
    saveWorkRequirementTypeDependency: AdminActions.saveWorkRequirementTypeDependency,
    removeWorkRequirementTypeDependency: AdminActions.removeWorkRequirementTypeDependency,
    saveRequirementTypeDocumentRestriction: AdminActions.saveRequirementTypeDocumentRestriction,
    removeRequirementTypeDocumentRestriction: AdminActions.removeRequirementTypeDocumentRestriction,
    saveRequirementTypeSuggestion: AdminActions.saveRequirementTypeSuggestion,
    removeRequirementTypeSuggestion: AdminActions.removeRequirementTypeSuggestion,
    saveRequirementTypeValidationHolder: AdminActions.saveRequirementTypeValidationHolder,
    removeRequirementTypeValidationHolder: AdminActions.removeRequirementTypeValidationHolder,
    getFieldValidations: AdminActions.getFieldValidations,
    removeFieldValidation: AdminActions.removeFieldValidation,
    saveFieldValidation: AdminActions.saveFieldValidation,
    saveContractorSettings: AdminActions.saveContractorSettings,
    saveMachinerySettings: AdminActions.saveMachinerySettings,
    saveWorkerSettings: AdminActions.saveWorkerSettings,
    saveJobSettings: AdminActions.saveJobSettings,
    saveWorkHasModule: AdminActions.saveWorkHasModule,
}

export const actionCreators = {
    savePropertyGroupType: (workId: number, data: IPropertyGroupType) => async (dispatch: Function) => {
        await AdminActions.savePropertyGroupType(workId, data);
        loadWorks(dispatch);
    },
    selectWork: (id: number) => (dispatch: Function) => {
        dispatch(UserStore.actionCreators.selectWork(id, false));
    },
    saveUser: (data: IUserData) => async (dispatch: Function) => {
        const ctxKey = 'adminusers';
        dispatch(N.clearErrors({
            ctx: ctxKey,
        }));
        dispatch(N.startLoading({
            ctx: ctxKey,
        }));
        const res = await AdminActions.saveUser(data);
        if (res.errors || res.data.saveUser.isError) {
            dispatch(N.error({
                ctx: ctxKey,
                action: data.id ? 'edit' : 'add',
                message: getGraphqlErrorFromOption(res, 'saveUser'),
            }));
        }
        else {
            dispatch(N.success({
                ctx: ctxKey,
                action: data.id ? 'edit' : 'add',
            }));
        }
        dispatch(N.stopLoading({
            ctx: ctxKey
        }));
        await loadUsers(dispatch);
    },
    saveWork: (data: IWork, opts: any = {}) => async (dispatch: Function) => {
        const ctxKey = opts.ctx || 'adminworks';
        dispatch(N.clearErrors({
            ctx: ctxKey,
        }));
        dispatch(N.startLoading({
            ctx: ctxKey,
        }));
        const res = await AdminActions.saveWork(data);
        if (res.errors || res.data.saveWork.isError) {
            dispatch(N.error({
                ctx: ctxKey,
                action: data.id ? 'edit' : 'add',
                message: getGraphqlErrorFromOption(res, 'saveWork'),
            }));
        }
        else {
            dispatch(N.success({
                ctx: ctxKey,
                action: data.id ? 'edit' : 'add',
            }));
        }
        dispatch(N.stopLoading({
            ctx: ctxKey
        }));
        await loadWorks(dispatch);
    },
    loadUsers: () => (dispatch: Function) => {
        loadUsers(dispatch);
    },
    loadWorks: () => (dispatch: Function) => {
        loadWorks(dispatch);
    },
    initialize: () => async (dispatch: Function, getState: () => ApplicationState) => {
        dispatch(App.actionCreators.startLoading({
            message: t.getFixedT('es')('Initializing ...')
        }));
        const resp = await AdminActions.initialize();
        dispatch(onInitialize(resp.data));
        dispatch(App.actionCreators.stopLoading());
    }
};

export const reducer: Reducer<IAdminState> = (state: IAdminState | undefined, incomingAction: Action): IAdminState => {
    if (state === undefined) {
        return defaultState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case onInitializeType: {
            const works = action.data.works
                ? action.data.works.map(parseWork)
                : [];

            return {...state, ...action.data, works };
        }

        case onGetUsersType: {
            return {...state, ...action.data};
        }

        default:
            return state;
    }
};
