import * as React from 'react';

import {
    BreadcrumbItem,
    G,
    RequireRole,
    SignalManager,
    SplitButton,
    InputSwitch,
    Tag,
    useConfirm,
    useDataTable,
    useDialogs,
    useForm,
    useInputSearch,
    useLoading,
    useMessage,
    useNumberState,
    useObjectState,
    usePaginator,
    useResolveName,
    useSearch,
    useSearchPaginate,
    useSearchFilters,
    useTranslation,
    ValidationBuilder,
    Avatar,
    usePermissions,
    useToast
} from '@components'
import {
    IFieldValidation,
    IPropertyGroupType,
    IResourceStatusType,
    ISecurity,
    IUserIdentity,
    IWork,
    IWorker,
    ModuleManager,
    PropertyGroupObjectType,
    ResourceType
} from '@models';
import { DynamicProperties } from '@components/user/DynamicProperties';
import './ViewWorkers.scss';
import { IContractor, ILegalForm, IWorkerDocumentType, IWorkerType } from '@models/resources';
import { ResourceStatus } from '@components/common/ResourceStatus';
import WorkerJobsDialog from '@containers/workers/WorkerJobsDialogContainer';
import ProfessionalQualificationsDialog from '@containers/workers/ProfessionalQualificationsDialogContainer';
import { RestrictWorker } from './RestrictWorker';
import { goToWorker, goToWorkerRequirements } from '@components/routes';
import useContractorFromAppUser from '@components/custom/useContractorFromAppUser';
import { useWorkFormSettings } from '@components/custom/useWorkFormSettings';
import { RestrictedWorkers } from './RestrictedWorkers';

const ADD_PERMISSION = 'workers.add';

export interface IProps {
    getWorkers: Function;
    propertyGroupTypes: IPropertyGroupType[];
    resourceStatusTypes: IResourceStatusType[];
    saveWorker: Function;
    restrictWorker: Function;
    removeRestrictedWorker: Function;
    reactivateWorker: Function;
    removeWorker: Function;
    security: ISecurity;
    work: IWork;
    worker: IWorker;
    appUsers: IUserIdentity[];
    workerTypes: IWorkerType[];
    workerDocumentTypes: IWorkerDocumentType[];
    contractors: IContractorProperties[];
    getRequirements: Function;
    legalForms: ILegalForm[];
    moduleManager: ModuleManager;
}

export interface IContractorProperties extends IContractor {
    value: string;
    propertyGroupTypeId: number;
}

function AddWorker({
    onChange, propertyGroupTypes, saveWorker, workId, work, security, workerTypes,
    workerDocumentTypes, fieldValidations, contractors, legalForms, moduleManager
}: {
    onChange: Function,
    propertyGroupTypes: IPropertyGroupType[],
    saveWorker: Function,
    workId: number,
    work: IWork,
    security: ISecurity,
    workerTypes: IWorkerType[],
    workerDocumentTypes: IWorkerDocumentType[],
    contractors: IContractorProperties[],
    fieldValidations: IFieldValidation[],
    legalForms: ILegalForm[],
    moduleManager: ModuleManager,
}) {
    const settings = useWorkFormSettings({ work: work, scope: 'worker' })
    const { t } = useTranslation();
    const messages = useMessage({
        successMessage: t('Worker added successfully'),
        autoClear: true,
    });
    const selectedWorkerType = useNumberState();
    const selectedWorkerDocumentType = useNumberState();
    const selectedContractor = useNumberState();

    const [availableWorkerTypes, setAvailableWorkerTypes] = React.useState<IWorkerType[]>([]);

    const [selectedFile, setSelectedFile] = React.useState<File | undefined>();
    const [createJobRelations, setCreateJobRelations] = React.useState<boolean>(false);

    const [shouldValidateCode, setShouldValidateCode] = React.useState<boolean>(false);

    const loading = useLoading();

    const workerSettings = work.workerSettings;

    const form = useForm<IWorker>({
        initialValues: {
            id: 0,
            workId: workId,
            name: '',
            surname: '',
            code: '',
            workerTypeId: selectedWorkerType.value,
            workerDocumentTypeId: selectedWorkerDocumentType.value,
            contractorId: selectedContractor.value,
        },
        validateOnMount: true,
        validate: ValidationBuilder
            .new()
            .notEmpty('name')
            .notEmpty('surname', 'Apellido obligatorio')
            .notEmpty('code')
            .notEmpty('workerDocumentTypeId')
            .notEmptyIf(workerSettings?.workerTypeIsMandatory, 'workerTypeId')
            .withFieldValidations(ResourceType.Worker, shouldValidateCode ? fieldValidations : undefined)
            .lift(),
        validateOnChange: true,
    });

    React.useEffect(() => {
        const res = moduleManager.validateData('WorkerCode', { workerDocumentTypeId: form.values.workerDocumentTypeId });
        setShouldValidateCode(res ?? false);
        form.setFieldValue('code', '');
    }, [form.values.workerDocumentTypeId]);

    const addRecord = useObjectState({ properties: [] });

    const doAdd = loading.wrap(async (..._: any) => {
        const newRecord: IWorker = {
            ...addRecord.value,
            ...form.values,
            isActive: true,
            properties: addRecord.value.properties,
        };

        const resp = await saveWorker(workId, newRecord, { createJobRelations, imageFile: selectedFile });

        let respMessage = resp;
        if (respMessage.error == 'restricted.worker.message') {
            respMessage.error = `${t('restricted.worker.message')} ${form.values.code}`;
        }else if (respMessage.error == 'worker.code.exists.message') {
            respMessage.error = `${t('worker.code.exists.message')} ${form.values.code}`;
        }

        messages.set(respMessage);

        if (resp.hasValue) {
            onChange(true);
        }
    });

    const contractorUser = useContractorFromAppUser(workId, security);

    const contractorData = useSearch<IContractor>({
        workId: workId,
        search: 'workers/contractor.get',
        lazy: true
    });

    React.useEffect(() => {
        if (contractorUser || form.values.contractorId) {
            contractorData.doSearch({
                contractorId: contractorUser ?? form.values.contractorId
            });
        }
    }, [form.values.contractorId, contractorUser]);

    const onSelectPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![e.target.files?.length! - 1];
        setSelectedFile(file);
    }

    const contractor = contractorData.value && contractorData.value.length > 0
        ? contractorData.value[0]
        : undefined;

    const sm = SignalManager.create(work.settingsObj?.signals, 'worker');

    const cannotSave = () => {
        if (work.workerSettings?.showWorkerImage == true) {
            if ((form.isInvalid() || selectedFile == undefined || selectedFile.type != 'image/jpeg')) {
                return true;
            } else {
                return false;
            }
        } else {
            if (form.isInvalid()) {
                return true;
            } else {
                return false;
            }
        }
    }

    React.useEffect(() => {
        //TODO: refactor this
        if (workId != 21) {
            if (form.values.contractorId) {
                workerTypeCalculator(form.values.contractorId);
            }
            if (contractor) {
                workerTypeCalculator(contractor.id);
            }
        }
        else {
            setAvailableWorkerTypes(workerTypes);
        }
    }, [form.values.contractorId, contractor]);


    const workerTypeCalculator = (contractorId: number) => {
        const contractor = contractors.find(x => x.id == contractorId);
        const selectedLegalForm = contractor?.legalFormId;
        const selectedWorkerTypes = legalForms.find(x => x.id == selectedLegalForm)?.workerTypes?.map(x => x.workerType).map(x => x.id) ?? [];

        if (selectedWorkerTypes.length > 0) {
            setAvailableWorkerTypes(workerTypes.filter(x => selectedWorkerTypes?.includes(x.id)));
        }
        else {
            //TODO
            if (workId != 19) {
                const value = JSON.parse(contractor?.value ?? "[]");
                const c: any[] = [workerTypes.find(x => x.name == "TRABAJADOR AUTONOMO SOCIO")];
                if (Object.keys(value).length) {
                    if (value.Metal == true) {
                        c.push(workerTypes.find(x => x.name == "TRABAJADOR REGIMEN GENERAL CONVENIO METAL"));
                    }
                    if (value.Construcción == true) {
                        c.push(workerTypes.find(x => x.name == "TRABAJADOR REGIMEN GENERAL CONVENIO CONSTRUCCION"));
                    }
                    if (value["Madera y Mueble"] == true) {
                        c.push(workerTypes.find(x => x.name == "TRABAJADOR REGIMEN GENERAL CONVENIO MADERA"));
                    }
                    if (value["Vidrio y Rotulación"] == true) {
                        c.push(workerTypes.find(x => x.name == "TRABAJADOR REGIMEN GENERAL CONVENIO VIDRIO, ROTULACION Y CERAMICA"));
                    }
                    if (value.Otros != undefined) {
                        c.push(workerTypes.find(x => x.name == "TRABAJADOR REGIMEN GENERAL"));
                    }
                }
                setAvailableWorkerTypes(c);
            }
        }
    }

    sm.current.setValues(form.values, { contractor });

    const filterDocumentTypes = moduleManager
        .filterDependency('worker.documentTypes', {
            contractor
        });

    const documentTypes = workerDocumentTypes
        .filter(filterDocumentTypes);

    return <div className='c g-20'>
        <div className='r g-20 h100p'>
            <div className='form-1 l200'>
                <div className='c g-20 e'>
                    <G label={t('Name')}>
                        {form.input('name')}
                    </G>
                    <G label={t('Surname')}>
                        {form.input('surname')}
                    </G>
                    <G label={t('Worker document type')}>
                        {form.select('workerDocumentTypeId', { options: documentTypes })}
                    </G>
                    <G label={t('worker.code')}>
                        {form.input('code')}
                    </G>
                    <RequireRole oneOf={['worker', 'gestor']}>
                        <G label={t('Contractor')}>
                            {form.select('contractorId', { options: contractors, filter: true })}
                        </G>
                    </RequireRole>
                    {settings.show('workerTypeId') &&
                        <G label={t('Worker type')} name='workerTypeId'>
                            {form.select('workerTypeId', { options: availableWorkerTypes })}
                        </G>
                    }
                    {work.workerSettings?.showWorkerImage == true &&
                        <input type='file' onChange={onSelectPhoto} title='Upload Photo' />
                    }
                    {(work.workerSettings?.showWorkerImage == true && selectedFile == null) &&
                        <small className='p-error'>{t('You must upload a JPEG')}</small>
                    }
                    {(work.workerSettings?.showWorkerImage == true && selectedFile != null && selectedFile.type != 'image/jpeg') &&
                        <small className='p-error'>{t('You must upload a JPEG')}</small>}
                </div>
            </div>

            <div id={'embed-properties'} />

            <DynamicProperties
                object={addRecord.value}
                onChange={addRecord.set('properties')}
                isInternal={!security.isContractor()}
                objectType={PropertyGroupObjectType.JobHasWorker}
                propertyGroupTypes={propertyGroupTypes} />
        </div>
        <div className='e' />

        {messages.render()}
        <div className='r'>
            {work.settingsObj?.createJobRelations! == true &&
                <div className='relationJobs'>
                    <InputSwitch checked={createJobRelations}
                        onChange={e => setCreateJobRelations(e.target.value)} />
                    <span className='e'>{t('createJobRelations.workers')}</span>
                </div>}
            <span className='e' />
            {loading.render()}
            <button
                className='primary'
                disabled={cannotSave()}
                onClick={doAdd}>
                {t('Add')}
            </button>
        </div>
    </div>
}

enum FormType {
    List = 0,
    AddWorker = 1,
    RestrictedWorkers = 2,
}

type IWorkerResult = IWorker & {
    contractorName: string;
    validatedCount: number;
    totalCount: number;
    photoId?: number;
}

function ViewWorkersImpl(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const filterKey = `filters.workers[workId=${props.work.id}]`;
    const filter = useInputSearch({ filterKey });
    const searchTerm = filter;
    const settings = useWorkFormSettings({ work: props.work, scope: 'worker' });
    const toast = useToast();

    const viewWorkersProps = {
        security: props.security,
        moduleManager: props.moduleManager,
        work: props.work,
    };

    const permissionCtx = {
        security: props.security,
    };

    const modulePerms = usePermissions(
        viewWorkersProps, {
        ctx: permissionCtx,
    }, {
        canAddWorkers: {
            name: 'resources.add',
            default: props.security.hasPermission(ADD_PERMISSION) || props.security.isContractor() || props.security.isGestor()
        }
    });

    const canAdd = modulePerms.get('canAddWorkers');

    const [formType, setFormType] = React.useState<FormType>(FormType.List);

    const reload = loading.wrap(async () => {
        await paginateData.doSearch(userFilters.merge({ name: (filter instanceof String || typeof filter === 'string') ? searchTerm.value : undefined, workId: props.work.id }));
    });

    const initialize = loading.wrap(async (doReload: boolean = false) => {
        setFormType(FormType.List);

        if (doReload) {
            await reload();
        }
    });

    const selectWorker = (worker: IWorker | undefined) => {
        if (worker) {
            goToWorker(props.work.id, worker.id);
        }
    }
    const viewWorkerRequirements = (id: number) => {
        goToWorkerRequirements(props.work.id, id);
    }

    const renderStatus = (worker: IWorker) =>
        <ResourceStatus
            currentStatusType={worker.currentStatusType!}
            resourceId={worker.id}
            resourceType={ResourceType.Worker}
            workId={props.work.id} />;

    const resolveWorkerType = useResolveName(props.workerTypes, { translate: true });
    const resolveWorkerDocumentType = useResolveName(props.workerDocumentTypes, { translate: true });

    const renderWorkerType = (w: IWorker) => {
        return <div>
            <span title={resolveWorkerType(w.workerTypeId)}>{resolveWorkerType(w.workerTypeId)}</span>
        </div>;
    }

    const renderWorkerDocumentType = (w: IWorker) => {
        return <div>
            <span title={t(resolveWorkerDocumentType(w.workerDocumentTypeId))}>{t(resolveWorkerDocumentType(w.workerDocumentTypeId))}</span>
        </div>;
    }

    const doActivateWorker = loading.wrap(async (i: IWorker) => {
        await props.reactivateWorker(props.work.id, i.id);
        await reload();
    });

    const doDeactivateWorker = loading.wrap(async (i: IWorker) => {
        await props.removeWorker(i.id, props.work.id, false);
        await reload();
    });

    const doRemoveWorker = loading.wrap(async (i: IWorker) => {
        await props.removeWorker(i.id, props.work.id, true);
        await reload();
    });

    const confirmActivate = useConfirm({
        message: t('resource.activate.worker.confirm'),
        accept: doActivateWorker
    });
    const confirmDeactivate = useConfirm({
        message: t('resource.deactivate.worker.confirm'),
        accept: doDeactivateWorker
    });
    const confirmRemove = useConfirm({
        message: t('resource.remove.worker.confirm'),
        accept: doRemoveWorker
    });

    const userFilters = useSearchFilters({
        security: props.security,
        workId: props.work.id,
        name: 'workers/list',
        references: {
            contractor: props.contractors,
        },
        persist: true
    });

    React.useEffect(() => {
        if (userFilters.filters.active == null) {
            userFilters.setFilter('active', true);
        }
    }, [userFilters.filters]);

    const paginateData = useSearchPaginate<IWorkerResult>({
        search: 'workers/list',
        workId: props.work.id,
        filters: userFilters.merge({ name: searchTerm.value, workId: props.work.id }),
        dependencies: [searchTerm.value, userFilters.filters],
        lazy: true,
        persist: true,
        //TODO
        limit: props.work.id == 21 ? 100 : 10,
    });

    const paginator = usePaginator<IWorkerResult>({
        data: paginateData.value,
        onChange: (page, limit) => paginateData.doSearch(
            userFilters.merge({ name: searchTerm.value, workId: props.work.id }),
            page,
            limit),
        onExport: () => paginateData.exportToXls(
            userFilters.merge({ name: searchTerm.value, workId: props.work.id }),
            t('workers.export.filename')),
    });

    const renderPhoto = (w: IWorkerResult) => {
        if (w.photoId) {
            return <Avatar
                image={`/api/files/${props.work.id}/document/${w.photoId}`}
                shape='circle'
                size='xlarge' />
        }
        else {
            return <Avatar
                image='/img/default_worker_image.png'
                shape='circle'
                size='xlarge' />
        }
    }

    const perms = usePermissions(props, {}, {
        deactivate: 'resource.deactivate',
        remove: 'workers.complete.remove',
    });

    const getWorkerActions = () => {
        const openQualificationsDialog = (d: any) => dialogs.show('professional-qualifications', d);
        return [
            { title: t('View data'), onClick: selectWorker },
            { title: t('Requirements'), onClick: (d: IWorkerResult) => viewWorkerRequirements(d.id) },
            { title: t('Jobs'), onClick: (r: IWorkerResult) => dialogs.show('jobs-dialog', r) },
            (props.security.hasPermission('workers.qualifications')) ? props.moduleManager.injectAction('professional-qualifications', { openQualificationsDialog: openQualificationsDialog }) : undefined,
            perms.get('deactivate')
                ? { title: t('resource.deactivate'), disabled: (r: IWorkerResult) => r.isActive == false, onClick: confirmDeactivate }
                : undefined,
            props.security.hasPermission('workers.force.status')
                ? { title: t('resource.activate'), disabled: (r: IWorkerResult) => r.isActive == true, onClick: confirmActivate }
                : undefined,
            perms.get('remove')
                ? { title: t('resource.remove'), onClick: confirmRemove }
                : undefined,
            props.security.hasPermission('restrict.resources')
                ? { title: t('restrict.worker'), onClick: (d: IWorkerResult) => dialogs.show('restrict-worker', { worker: d }) }
                : undefined,
        ];
    };


    const dataTable = useDataTable({
        className: `workers-table workers-table${'-' + props.work.id}`,
        columns:
            [
                props.work.workerSettings?.showWorkerImage == true
                    ? { title: '', render: renderPhoto, className: 'td-avatar' }
                    : undefined,
                {
                    title: t('Name'),
                    render: d => <span title={`${d.name}`}>{d.name}</span>,
                    field: 'name',
                    className: 'td-md left',
                    sortKey: 'name',
                },
                {
                    title: t('Surname'),
                    render: d => <span title={`${d.surname}`}>{d.surname}</span>,
                    field: 'surname',
                    className: 'td-md left',
                    sortKey: 'surname',
                },
                {
                    title: t('Contractor'),
                    render: (d: IWorkerResult) => d.contractorName
                        ? <span title={d.contractorName}>{d.contractorName}</span>
                        : null,
                    className: 'w-25 left',
                    sortKey: 'contractorName',
                },
                settings.show('workerTypeId')
                    ? { title: t('Worker type'), render: renderWorkerType, className: 'td-lg', sortKey: 'workerType' }
                    : undefined,
                props.work.workerSettings?.showGeneralIndicators == true
                    ? {
                        title: 'Documentation', className: 'td-indicator td-md center',
                        render: (d: IWorkerResult) =>
                            <span onClick={() => goToWorkerRequirements(props.work.id, d.id)} className='pointer'>
                                <Tag value={`${d?.validatedCount ?? 0}/${d?.totalCount ?? 0}`} />
                            </span>
                    }
                    : undefined,
                settings.show('currentStatusType') && props.work.id != 23
                    ? { field: 'currentStatusType', render: renderStatus, className: 'td-md' }
                    : undefined,
                { title: t('Worker document type'), render: renderWorkerDocumentType, className: 'w-10 center', sortKey: 'workerDocumentType' },
                { title: t('worker.code'), render: d => <span title={d.code}>{d.code}</span>, field: 'code', className: 'w-10 center', sortKey: 'code' },
            ],
        actions: [
            {
                headerClassName: 'td-sm',
                actions: getWorkerActions()
            }
        ],
        data: paginateData.data,
        onSort: (key, order) => paginateData.sortBy(key, order),
        denyLocalSort: true,
    });

    const getActions = () => {
        const actions = [];
        const security = props.security;

        actions.push({
            label: t('workers.list'),
            command: () => setFormType(FormType.List),
        });
        if (canAdd) {
            actions.push({
                label: t(ADD_PERMISSION),
                command: () => setFormType(FormType.AddWorker)
            });
        }
        if (security.hasPermission('restrict.resources')) {
            actions.push({
                label: t('restrict.worker.add'),
                command: () => setFormType(FormType.RestrictedWorkers)
            });
        }

        return actions;
    }

    const contractors = useSearch<IContractorProperties>({
        workId: props.work.id,
        search: 'machineries/contractors',
        filters: {
            workId: props.work.id,
        },
    });

    const actions = getActions();

    React.useEffect(() => {
        initialize(true);
    }, []);

    React.useEffect(() => {
        if(userFilters.filters.active == null){
            userFilters.setFilter('active', true);
        }
    }, [userFilters.filters]);

    const handleToast = (message: string, success: boolean) => {
        if (success) {
            toast.show(message)
        }
        else {
            toast.error(message)
        }
    }

    return <div className='ViewWorkers'>
        {toast.render()}
        <BreadcrumbItem
            redirectTo={`/work/${props.work.id}/workers`}
            onClick={() => setFormType(FormType.List)}
            text={t('Workers')} />

        <div className='h100p he'>
            <div className='c he r'>
                <div className='p-inputgroup toolbar'>
                    <SplitButton
                        className='flat'
                        label={t('Actions')}
                        model={actions} />

                    {filter.render()}

                    <span className='key-shortcut'>CTRL+F</span>
                    {userFilters.renderAsButton()}
                    {loading.renderBox()}
                    <span className='p-inputgroup-addon'>
                        <i className='pi pi-search' />
                    </span>
                </div>
                <div>
                </div>
                {dialogs.render('professional-qualifications', { title: t('Professional qualifications'), className: 'g pd', style: { maxHeight: 'fit-content', minHeight: 'fit-content', maxWidth: '80vw', minWidth: '80vw' } }, (r: IWorker) =>
                    <div className='sm pd'>
                        <ProfessionalQualificationsDialog
                            moduleManager={props.moduleManager}
                            requestClose={dialogs.clear}
                            workerId={r.id}
                            worker={r}
                            work={props.work.id} />
                    </div>)}
                {formType === FormType.List && <>
                    {dialogs.render('jobs-dialog', {
                        title: (w: IWorker) => t('Jobs') + ': ' + w.name + ' ' + w.surname,
                        className: 'g pd',
                        style: { width: '60vw' }
                    }, (r: IWorker) =>
                        <div>
                            <WorkerJobsDialog
                                requestClose={dialogs.clear}
                                workerId={r.id ?? 0}
                                work={props.work.id} />
                        </div>
                    )}
                    {dialogs.render('restrict-worker', {
                        title: t('restrict.worker.add'),
                        className: 'g pd',
                        style: { width: '60vw' }
                    }, (r: any) =>
                        <RestrictWorker
                            {...viewWorkersProps}
                            worker={r.worker}
                            restrictWorker={props.restrictWorker}
                            handleToast={handleToast}
                            onClose={{ clearDialogs: dialogs.clear, reload: initialize}}
                        />
                    )}
                    <div>
                        {dataTable()}
                    </div>
                    <div className='e' />
                    {paginator()}
                </>}
                {formType === FormType.AddWorker && <>
                    <div className='lg mr pd box'>
                        <AddWorker
                            onChange={initialize}
                            security={props.security}
                            propertyGroupTypes={props.propertyGroupTypes}
                            saveWorker={props.saveWorker}
                            workId={props.work.id}
                            workerTypes={props.workerTypes}
                            workerDocumentTypes={props.workerDocumentTypes}
                            fieldValidations={props.work.validations!}
                            contractors={contractors.value}
                            work={props.work}
                            legalForms={props.legalForms}
                            moduleManager={props.moduleManager} />
                    </div>
                </>}
                {formType === FormType.RestrictedWorkers && <>
                    <RestrictedWorkers
                        workId={props.work.id}
                        removeRestrictedWorker={props.removeRestrictedWorker}
                        handleToast={handleToast}
                    />
                </>}
            </div>
        </div>
    </div>
}

export const ViewWorkers = React.memo(ViewWorkersImpl, (prev, next) => {
    return prev.work.id === next.work.id;
});
