import * as React from 'react';
import './NavMenu.scss';

import HelpDeskSupport from '@containers/HelpDeskSupportContainer';
import Sec from '@utils/security';
import { IMessage, INotification, MessageType } from '@models/notifications';
import { ICommunication, IOption, ISecurity, IUserIdentity, IWork, ModuleManager } from '@models';
import { MenuItem } from '../../../../GStore.Modules/FrontEnd';
import { Menubar, classNames, useToast, useTranslation, Badge } from '@components';
import { NotificationsMessages } from './notifications/NotificationMessages';
import { getReports } from '@store/actions/reports';
import { isDebug } from '../Constants';
import { redirectToExternal, redirectTo } from '@utils';
import { IReportDefinition } from "@models/reports";

type ClearMessageF = (id: string) => Promise<IOption<boolean>>;

interface IProps {
    clearMessage: ClearMessageF;
    user?: IUserIdentity;
    menuItems: MenuItem[];
    notifications: INotification[];
    messages: IMessage[];
    removeNotification: Function;
    security: ISecurity;
    work: IWork;
    workId: number;
    communications: ICommunication[];
    appUsers: IUserIdentity[];
    moduleManager: ModuleManager;
}

type FilterItemsT = (items: any[], props: IProps) => any[];

const filterMenuItems: FilterItemsT = (items: any[], props: IProps) => {
    return items.filter(i => {
        if (i.showIf) {
            return i.showIf(props);
        }
        else {
            return i;
        }
    }).map(i => {
        if (i.items) {
            return { ...i, items: filterMenuItems(i.items, props) }
        }
        else {
            return i;
        }
    });
};

function NavMenuImpl(props: IProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const [showMessages, setShowMessages] = React.useState<boolean>(false);
    const [showHelpDesk, setShowHelpDesk] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.messages.length <= 0) {
            setShowMessages(false);
        }
    }, [props.messages]);

    const hasWork = props.workId > 0;
    const isContractor = props.security.isContractor();
    const isWorker = props.security.isWorker();
    const isGestor = props.security.isGestor();
    const isGestorOrWorker = props.security.isGestorOrWorker();
    const isAdmin = props.security.isAdmin();
    const isContractorOrWorker = !isAdmin && (isContractor || isWorker || isGestor);

    const [reportItems, setReportItems] = React.useState<any[]>([]);

    const fillReportItems = (items: IReportDefinition[]) => {
        setReportItems(items
            .filter(r => !r.hidden)
            .map(r => {
                return {
                    label: t(r.title),
                    icon: r.icon,
                    command: () => redirectTo(`/work/${props.workId}/reports/${r.name}`),
                };
            }));
    }

    React.useEffect(() => {
        if (props.workId) {
            getReports(props.workId).then(fillReportItems);
        }
    }, [props.workId]);


    React.useEffect(() => {
        if (props.notifications && props.notifications.length > 0) {
            for (const n of props.notifications) {
                props.removeNotification(n.id);
                toast.show(n.message);
            }
        }
    }, [props.notifications]);

    const setLanguage = (lang: string) => {
        document.cookie = `lang=${lang};path=/`;
        window.location.reload();
    }

    const userLabel = isDebug ?
        (isAdmin ? "(admin)" : isWorker ? "(trabajador)" : isGestor ? "(gestor)" : isContractor ? "(contratista)" : "(??)")
        : '';
    const menuItems = filterMenuItems([
        {
            label: t('Start'), icon: 'pi pi-fw pi-home', command: () => redirectTo('/'), showIf: () => props.communications.length == 0
        },
        {
            id: 'functions',
            label: t('Functions'),
            icon: 'fas fa-globe',
            showIf: () => (!props.security.isWorker) && hasWork && isGestorOrWorker && !isAdmin && props.communications.length == 0,
            items: [
                {
                    label: t('Jobs'),
                    command: () => redirectTo(`/work/${props.workId}/jobs`)
                },
                {
                    label: t('Contractors'),
                    command: () => redirectTo(`/work/${props.workId}/contractors`)
                },
                {
                    label: t('Workers'),
                    command: () => redirectTo(`/work/${props.workId}/workers`)
                },
                {
                    label: t('Machineries'),
                    command: () => redirectTo(`/work/${props.workId}/machineries`)
                },
                {
                    label: t('Subcontractors request'),
                    command: () => redirectTo(`/work/${props.workId}/subcontractorrequests`)
                }
            ]
        },
        {
            id: 'functions',
            label: t('Functions'),
            icon: 'fas fa-globe',
            showIf: () => isContractor && props.communications.length == 0 && !isAdmin,
            items: [
                {
                    label: t('Documents'),
                    command: () => redirectTo(`/work/${props.workId}/contractor/requirements`)
                },
                {
                    label: t('Workers'),
                    command: () => redirectTo(`/work/${props.workId}/workers`)
                },
                {
                    label: t('Machineries'),
                    command: () => redirectTo(`/work/${props.workId}/machineries`)
                },
                {
                    label: t('Jobs'),
                    command: () => redirectTo(`/work/${props.workId}/jobs`)
                },
                {
                    label: t('Subcontractors'),
                    command: () => redirectTo(`/work/${props.workId}/subcontractors`)
                },
                {
                    label: t('Subcontractors request'),
                    command: () => redirectTo(`/work/${props.workId}/subcontractorrequests`)
                },
            ]
        },
        {
            id: 'utilities',
            label: t('Utilities'),
            icon: 'fas fa-book',
            showIf: () => hasWork && !isAdmin && props.communications.length == 0,
            items: [
                {
                    label: t('Reports'), icon: 'fas fa-file',
                    showIf: () => hasWork && isContractorOrWorker,
                    items: reportItems,
                    // command: () => redirectTo(`/work/${props.workId}/reports`),
                },
                {
                    label: t('Download area'), icon: 'fas fa-download',
                    showIf: () => hasWork && isContractorOrWorker,
                    command: () => redirectTo(`/work/${props.workId}/documents`)
                },
                {
                    label: t('Access control'), icon: 'fas fa-ban',
                    showIf: () => hasWork && isContractorOrWorker,
                    items: [
                        {
                            label: t('Machineries'),
                            command: () => redirectTo(`/work/${props.workId}/access-control/machineries`),
                        },
                        {
                            label: t('Workers'),
                            command: () => redirectTo(`/work/${props.workId}/access-control/workers`),
                        }
                    ],
                },
                {
                    label: t('Users'), icon: 'pi pi-user',
                    showIf: () => isGestor && !isAdmin,
                    command: () => redirectTo(`/work/${props.workId}/users`),
                },
                {
                    label: t('Communications'), icon: 'fas fa-comments',
                    showIf: () => isGestor || isContractor || props.security.hasPolicy('communication.list') || props.security.hasPolicy('communication.create'),
                    items: [
                        {
                            label: t('New Communication'),
                            icon: 'fas fa-plus',
                            showIf: () => isGestor || props.security.hasPolicy('communication.create'),
                            command: () => redirectTo(`/work/${props.workId}/communications/new`),
                        },
                        {
                            label: t('Communications'),
                            icon: 'fas fa-search',
                            showIf: () => isGestor || props.security.hasPolicy('communication.list') || isContractor,
                            command: () => redirectTo(`/work/${props.workId}/communications`),
                        }
                    ]
                }
            ]
        },
        {
            label: t('Users'), icon: 'pi pi-user',
            showIf: () => Sec.isAdmin(props.user),
            items: [
                {
                    label: t('Users'),
                    command: () => redirectTo('/admin/users'),
                },
            ]
        },
        {
            label: t('Works'), icon: 'pi pi-list',
            showIf: () => Sec.isAdmin(props.user),
            items: [
                {
                    label: t('Works'),
                    command: () => redirectTo('/admin/works'),
                }
            ]
        },
        {
            label: t('Devel'), icon: 'pi pi-code',
            showIf: () => Sec.isAdmin(props.user),
            items: [
                {
                    label: t('Graphql ui'),
                    command: () => redirectTo('/admin/devel/graphqlui'),
                },
                {
                    label: t('Schema'),
                    command: () => redirectTo('/admin/devel/schema'),
                },
            ]
        },
        { separator: true, className: 'e' },
        {
            className: classNames('notifications-menu-item', { ['notifications-menu-item-open']: showMessages }),
            label: <i className='pi pi-bell'>
                <Badge value={props.messages.length} className='small top-right' />
            </i>,
            command: () => setShowMessages(s => !s),
            showIf: () => (props.messages ?? [])
                .filter(m => m.notificationType != MessageType.RELOAD_NOTIFICATIONS).length > 0
                && props.communications.length == 0
        },
        {
            label: t('helpDesk.support'), icon: 'pi pi-comment',
            className: `help-desk-menu-button help-desk-${showHelpDesk}`,
            showIf: () => props.workId > 0 && props.communications.length == 0,
            command: () => {
                setShowHelpDesk(b => !b);
            },
        },
        {
            className: classNames({
                'contractor': props.security.isContractor(),
                'worker': props.security.isWorker(),
                'admin': props.security.isAdmin(),
                'gestor': props.security.isGestor()
            }),
            label: userLabel + ' ' + props.user?.userName,
            showIf: () => props.user != undefined,
        },
        {
            icon: 'pi pi-cog',
            showIf: () => !Sec.isAdmin(props.user) && props.user && props.communications.length == 0,
            items: [
                {
                    label: t('user.my-data'),
                    command: () => redirectTo(`/user/${props.workId}/profile`),
                    showIf: () => hasWork && (props.security.isWorker() || props.security.isContractor()),
                },
                {
                    label: t('Password'),
                    //TODO: Revisar este showIf
                    showIf: () => (props.security.user?.id !== 442),
                    command: () => redirectTo('/user/password')
                }
            ]
        },
        {
            label: t('Logout'),
            icon: 'pi pi-sign-out',
            showIf: () => props.user !== undefined,
            command: () => redirectToExternal('/api/users/logout'),
        },
        //  TODO: Implementar cambio de idioma
        // {
        //     label: t('Language'),
        //     icon: 'pi pi-globe',
        //     showIf: () => props.user === undefined,
        //     items: [
        //         {
        //             label: t('Spanish'),
        //             icon: 'pi pi-flag',
        //             command: () => setLanguage('es')
        //         },
        //         {
        //             label: t('Italian'),
        //             icon: 'pi pi-flag',
        //             command: () => setLanguage('it')
        //         },
        //         {
        //             label: t('English'),
        //             icon: 'pi pi-flag',
        //             command: () => setLanguage('en')
        //         }
        //     ]
        // },
        {
            label: t('Login'),
            icon: 'pi pi-sign-in',
            showIf: () => props.user === undefined,
            command: () => redirectToExternal('/')
        }
    ], props);

    if (props.menuItems) {
        for (const mi of props.menuItems) {
            mi.label = t(mi.label);
            if (mi.parent == undefined) {
                const pos = props.messages.length > 0 ? 5 : 4;
                menuItems.splice(menuItems.length - pos, 0, mi);
            }
            else {
                const parent = menuItems.find(m => m.id == mi.parent);
                if (parent && parent.items) {
                    parent.items.push(mi);
                }
            }
        }
    }

    const closeHelpDesk = (ok: boolean) => {
        if (ok) {
            toast.show(t('helpDesk.send.ok'));
        }
        setShowHelpDesk(false);
    }

    return <div id='main-menu'>
        <div className='logos'>
            <img src='/img/logo_gstore.jpg' className='gstore' alt='logo' />
            <span className='e'></span>
            <img src='/img/ilustracion.jpg' className='ils' alt='logo' />
        </div>
        <div className='header-menu'>
            <Menubar model={menuItems} />
            {showHelpDesk && props.communications.length == 0 && <HelpDeskSupport onClose={closeHelpDesk} />}
            {showMessages &&
                <NotificationsMessages
                    clearMessage={props.clearMessage}
                    messages={props.messages}
                    requestClose={() => setShowMessages(false)}
                    security={props.security}
                    work={props.work}
                    workId={props.workId} />}
        </div>
        {toast.render()}
    </div>
}


export default function NavMenu(props: IProps) {

    return props
        .moduleManager
        .renderComponent<IProps>(
            'NavMenu',
            { ...props, getReports, Menubar, },
            NavMenuImpl,
            true);
}
