import * as React from 'react';

import { IWork } from '@models';
import { IMessage, MessageType } from '@models/notifications';
import { IRequirement } from '@models/requirements';

import ValidateAllRequirements from '@containers/requirements/ValidateAllRequirementsContainer';
import { useHideBodyScrollbar, useLoading } from '@components';
import { unique } from '@utils';

import './ValidateRequirementsFromNotificationMessages.scss';

type GetRequirementF = (workId: number, targetType: number, targetId: number, id: number) => Promise<IRequirement>;
type LoadWorkF = (workId: number) => void;

export interface IProps {
    getRequirementsFromResources: Function;
    getRequirement: GetRequirementF;
    loadWork: LoadWorkF;
    message: IMessage;
    messages: IMessage[];
    requestCancel: Function;
    workId: number;
    work: IWork;
}

export function ValidateRequirementsFromNotificationMessages(props: IProps) {
    const loading = useLoading({container: '#validate-requirements-left'});
    const [requirement, setRequirement] = React.useState<IRequirement|undefined>();
    const [message, setMessage] = React.useState<IMessage|undefined>();

    const [requirementJobs, setRequirementJobs] = React.useState<any>({});
    const [requirements, setRequirements] = React.useState<IRequirement[]>([]);
    const [selectedRequirementId, setSelectedRequirementId] = React.useState<number|undefined>();

    useHideBodyScrollbar();

    React.useEffect(() => {
        if (props.messages) {
            const res: any = {};

            for (const m of props.messages) {
                if (m.notificationType === MessageType.PENDING_REQUIREMENT && m.data?.JobId) {
                    res[m.data.RequirementId] = m.data.JobId;
                }
            }

            setRequirementJobs(res);
        }
    }, [props.messages]);

    const loadRequirements = loading.wrap(async () => {
        const msgs = props.messages.filter(m => m.notificationType === MessageType.PENDING_REQUIREMENT);
        const requirementIds = unique(
            msgs
                .map(m => ({
                    requirementId: m.data?.RequirementId,
                    resourceType: m.data?.ResourceType,
                    resourceId: m.data?.ResourceId
                }))
                .filter(r => r.requirementId != undefined),
            'requirementId');

        const res = await props.getRequirementsFromResources(
            props.workId,
            requirementIds);

            console.log('loadrequirements', props.message);

        for (const r of res) {
            const msg = msgs.find(m => m.data?.RequirementId == r.id);
            if (r.id === props.message?.data?.RequirementId) {
                setSelectedRequirementId(r.id);
            }
            if (msg) {
                r.__resourceName = msg.data?.ResourceName;
            }
        }

        setRequirements(res);
    });

    React.useEffect(() => {
        loadRequirements();
    }, [props.messages]);

    React.useEffect(() => {
        if (props.work == undefined || props.work.id != props.workId) {
            props.loadWork(props.workId);
        }
    }, [props.work]);

    React.useEffect(() => {
        setMessage(props.message);
    }, [props.message]);

    return <div className='validate-requirements-from-messages'>
        {loading.renderBox({style: {fontSize: '0.8em'}})}
        {requirements && props.work && props.work?.id == props.workId &&
            <ValidateAllRequirements
                requirementJobs={requirementJobs}
                requirements={requirements}
                selectedRequirementId={selectedRequirementId}
                requestCancel={props.requestCancel} />}
    </div>;
}